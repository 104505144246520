import { getEnvironmentVariables } from "../constants/environment";

const ENV_VARS = getEnvironmentVariables();

export const AUTH_CONFIG = {
  aws_project_region: ENV_VARS.REGION,
  aws_cognito_region: ENV_VARS.REGION,
  aws_cognito_identity_pool_id: ENV_VARS.COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: ENV_VARS.COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: ENV_VARS.COGNITO_WEB_CLIENT_ID,
  oauth: {
    domain: ENV_VARS.COGNITO_DOMAIN,
    scope: ["openid"],
    redirectSignIn: ENV_VARS.COGNITO_REDIRECT_SIGNIN,
    redirectSignOut: ENV_VARS.COGNITO_REDIRECT_SIGNOUT,
    responseType: "code",
  },
  cookieStorage: {
    domain: `${window.location.hostname}`,
    path: "/",
    expires: 365,
    sameSite: "strict",
    secure: true, // secure: true, will not work for localhost (except on FireFox)
  },
  // To get the AWS Credentials, we need to configure the Auth module with the
  // Cognito Federated Identity Pool
  Auth: {
    region: ENV_VARS.REGION,
    userPoolId: ENV_VARS.COGNITO_USER_POOL_ID,
    userPoolWebClientId: ENV_VARS.COGNITO_WEB_CLIENT_ID,
    identityPoolId: ENV_VARS.COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: false
  },
};
