import {
  NonCancelableCustomEvent,
  PropertyFilter,
  PropertyFilterProps,
} from "@cloudscape-design/components";
import { Query } from "@cloudscape-design/components/property-filter/interfaces";
import { IPaginated } from "../../data/interfaces/ICampaign";

import React from "react";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";

export interface IPropertyFilterProps {
  handleSearchChange: (query: PropertyFilterProps.Query) => void;
  searchQuery: Query;
  items: IPaginated;
  filteringProperties?: PropertyFilterProps.FilteringProperty[];
  onLoadItems?: (
    event: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>
  ) => void;
  filteringStatusType?: DropdownStatusProps.StatusType;
  filteringOptions?: PropertyFilterProps.FilteringOption[];
}

const CloudscapeTablePropertyFilter = ({
  handleSearchChange,
  searchQuery,
  items,
  filteringProperties,
  onLoadItems,
  filteringStatusType,
  filteringOptions,
}: IPropertyFilterProps) => {

  return (
    <PropertyFilter
      onChange={({ detail }) => handleSearchChange(detail)}
      query={searchQuery}
      i18nStrings={{
        filteringAriaLabel: "your choice",
        dismissAriaLabel: "Dismiss",
        filteringPlaceholder: "Filter by text, property or value",
        groupValuesText: "Values",
        groupPropertiesText: "Properties",
        operatorsText: "Operators",
        operationAndText: "and",
        operationOrText: "or",
        operatorLessText: "Less than",
        operatorLessOrEqualText: "Less than or equal",
        operatorGreaterText: "Greater than",
        operatorGreaterOrEqualText: "Greater than or equal",
        operatorContainsText: "Contains",
        operatorDoesNotContainText: "Does not contain",
        operatorEqualsText: "Equals",
        operatorDoesNotEqualText: "Does not equal",
        editTokenHeader: "Edit filter",
        propertyText: "Property",
        operatorText: "Operator",
        valueText: "Value",
        cancelActionText: "Cancel",
        applyActionText: "Apply",
        allPropertiesLabel: "All properties",
        tokenLimitShowMore: "Show more",
        tokenLimitShowFewer: "Show fewer",
        clearFiltersText: "Clear Filters",
        removeTokenButtonAriaLabel: (token) =>
          `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text) => `Use: "${text}"`,
      }}
      countText={items?.page?.totalElements?.toString() + " matches"}
      expandToViewport
      filteringProperties={filteringProperties ?? []}
      tokenLimit={5}
      data-testid="property-filter-field"
      onLoadItems={onLoadItems}
      filteringStatusType={filteringStatusType}
      filteringOptions={filteringOptions}
      filteringLoadingText="Loading suggestions"
      customGroupsText={[
        {
          properties: "Custom Attributes",
          values: "Custom Attribute values",
          group: "attributes"
        }
      ]}
    />
  );
};

export default CloudscapeTablePropertyFilter;
