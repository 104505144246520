import React, { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "@cloudscape-design/components";
import {
  RAW_COLUMNS,
  PREFERENCES_VISIBLE_COLUMNS,
  PREFERENCES_VISIBLE_CONTENT,
  FILTERING_PROPERTIES,
} from "./config";

import CloudscapeTable from "../../components/table/CloudscapeTable";
import {
  API_CALL_NAME,
  ENTITY_TYPE,
  RESOURCE_FLIGHT_PLAN,
  TABLE_SELECTION_TYPE_MULTI,
} from "../../data/constants/common";
import {
  getSavedPreferencesFromLocalStorage,
  getSavedSearchFiltersLocalStorage,
} from "../../components/table/TableUtils";

const FlightPlansDashboard: FC<object> = (): ReactElement | null => {
  const navigate = useNavigate();

  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    navigate("/flight-plans/create");
  };

  const handleOnEdit = (flightPlan: any): void => {
    navigate("/flight-plans/edit", {
      state: {
        ...flightPlan,
      },
    });
  };

  return (
    <div data-testid="flight-plan-dashboard">
      <CloudscapeTable
        columnDefinitions={RAW_COLUMNS}
        resizableColumns={false}
        resourceName={RESOURCE_FLIGHT_PLAN}
        apiNameCall={API_CALL_NAME.FLIGHT_PLANS}
        selectionType={TABLE_SELECTION_TYPE_MULTI}
        visibleContentDefault={PREFERENCES_VISIBLE_COLUMNS}
        visibleContentOptions={PREFERENCES_VISIBLE_CONTENT}
        filteringProperties={FILTERING_PROPERTIES}
        handleOnCreate={handleOnCreate}
        actionHandlers={{
          handleOnEdit: handleOnEdit,
          hasArchiveButton: true,
        }}
        title={RESOURCE_FLIGHT_PLAN}
        savedPreferences={getSavedPreferencesFromLocalStorage(
          RESOURCE_FLIGHT_PLAN,
          PREFERENCES_VISIBLE_COLUMNS
        )}
        savedSearchFilters={getSavedSearchFiltersLocalStorage(
          ENTITY_TYPE.FLIGHT_PLANS
        )}
        entityType={ENTITY_TYPE.FLIGHT_PLANS}
      />
    </div>
  );
};

export default FlightPlansDashboard;
