import React, { Component, ErrorInfo, ReactNode } from "react";
import FallbackComponent from "./FallbackComponent";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null as unknown as Error,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true,  error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const { error } = this.state;
      const props = {
        message: error?.message,
        name: error.name,
        stack: error?.stack,
      };
      return <FallbackComponent { ...props}/>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
