import {
  Autosuggest,
  Button,
  Container,
  ContentLayout,
  FlashbarProps,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useState, useContext } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { postNewBusinessData, useFetchBusinessData } from "../../../client/client";
import { BUSINESS_PROPERTY, CreateNewBusinessUnit, MODEMetadata } from "../Interface";
import { MessageContext, MessagesContextType } from "../../../context/MessagingContext";
import ScreenLoader from "../../../components/common/ScreenLoader";
import { LINKS_CONFIG } from "../../../data/constants/links";
import { BUSINESS_ROUTE_PATHS, REQUEST_METHOD } from "../../../data/constants/common";
import { businessGroupNameSlugMap } from "../config";

type InvalidInput = {
  businessUnitSlug: boolean;
  businessGroupSlug: boolean;
  businessUnitName: boolean;
  businessUnitPOC: boolean;
  budgetClass: boolean;
}
type Props = {
  getIdToken: () => string;
  email: string;
  addMessage: (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string | undefined
  ) => void;
  navigate: NavigateFunction;
};

export const CreateBusinessUnit: React.FC<Props> = ({
  getIdToken,
  email,
  addMessage,
  navigate,
}): JSX.Element => {
  const { state } = useLocation();
  const isUpdate = state ? true : false;

  const [pageSize, setPageSize] = useState<number>(25);

  const { isLoading, data, serverError }
  = useFetchBusinessData({
    urlPath: `/businessgroup/?pageSize=${pageSize}`,
    method: REQUEST_METHOD.GET,
    getTokenFn: getIdToken,
  });

  const [posixGroups, setPosixGroups] = useState(
    state 
      ? { 
        accessGroupsRW: state.accessGroupsRW,
        accessGroupsRO: state.accessGroupsRO,
      }
      : {
        accessGroupsRW: "",
        accessGroupsRO: "",
      }
  );
  const [modeMetadata] = useState<MODEMetadata>({
    createdBy: email,
    creationDate: Date.now()
  });
  const [newBusinessUnit, setNewBusinessUnit] = useState<CreateNewBusinessUnit>(
    state 
      ? { ...state }
      : {
        businessUnitSlug: "",
        businessUnitName: "",
        budgetClass: "",
        businessGroupSlug: "",
        businessUnitPOC: "",
        accessGroupsRW: [],
        accessGroupsRO: [],
        metadata: modeMetadata,
      }
  );
  const [invalidInput, setInvalidInput] = useState<InvalidInput>({
    businessUnitSlug: false,
    businessGroupSlug: false,
    businessUnitName: false,
    businessUnitPOC: false,
    budgetClass: false,
  });

  const handleInputChange = (
    name: string,
    value: string
  ) => {
    setInvalidInput({ ...invalidInput, [name]: false});
    setNewBusinessUnit({
      ...newBusinessUnit, [name]: value,
    });
  };
  const [posting, setPosting] = useState<boolean>(false);


  const handleSubmitBusinessData = async () => {
    setPosting(true);
    await postNewBusinessData({
      newBusinessData: {businessUnit: { ...newBusinessUnit }},
      method: isUpdate ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
      getIdToken,
      addMessage,
      navigate,
      urlParam: `businessunit/${isUpdate ? newBusinessUnit.businessUnitSlug: ""}`,
      navigateTo: BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT + BUSINESS_ROUTE_PATHS.BUSINESS_UNITS + "/",
      successMessage: `${BUSINESS_PROPERTY.BUSINESS_UNIT} ${isUpdate ? "updated" : "created"} successfully!`,
      errorMessage: `${BUSINESS_PROPERTY.BUSINESS_UNIT} ${isUpdate ? "update" : "creation"} failed, please try again!`,
      setPosting,
    });
  };

  return (
    <ContentLayout
      data-testid="create-bu-container"
      header={
        <Header>
          {`${isUpdate ? "Update" : "Create new"} ${BUSINESS_PROPERTY.BUSINESS_UNIT}`}
        </Header>
      }
    >
      <form onSubmit={event => event.preventDefault()} data-testid="create-new-bu-form">
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="cancel-btn"
                variant="link"
                onClick={() => navigate(BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT + BUSINESS_ROUTE_PATHS.BUSINESS_UNITS + "/")}
              >
                Cancel
              </Button>
              <Button
                data-testid="submit-btn"
                variant="primary"
                onClick={handleSubmitBusinessData}
              >
                {`${isUpdate ? "Update": "Create"}`}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="m">
            <Container
              header={
                <Header variant="h3">
                  Basic details
                </Header>
              }
            >
              <>
                {posting && (
                  <ScreenLoader />
                )}
              </>
              <Grid
                gridDefinition={[{colspan: 6}, {colspan: 6}]}
              >
                <SpaceBetween size="l">
                  <FormField
                    label={BUSINESS_PROPERTY.BUSINESS_GROUP}
                    description={`Enter an existing ${BUSINESS_PROPERTY.BUSINESS_GROUP} to create a new ${BUSINESS_PROPERTY.BUSINESS_UNIT}`}
                    errorText={invalidInput.businessGroupSlug ? `Please enter or select an already existing ${BUSINESS_PROPERTY.BUSINESS_GROUP} slug`: ""}
                  >
                    <Autosuggest
                      data-testid="create-bu-business-group-slug-input"
                      placeholder={`Start typing ${BUSINESS_PROPERTY.BUSINESS_GROUP} name or slug`}
                      loadingText={`Loading ${BUSINESS_PROPERTY.BUSINESS_GROUP}s`}
                      finishedText={`finished loading all available ${BUSINESS_PROPERTY.BUSINESS_GROUP}s`}
                      virtualScroll
                      disableBrowserAutocorrect
                      empty="No matches found"
                      onLoadItems={() => {
                        setPageSize(data.page?.totalElements);
                      }}
                      disabled={isUpdate}
                      value={newBusinessUnit.businessGroupSlug}
                      onChange={({ detail: { value } } ) => handleInputChange("businessGroupSlug", value)}
                      options={businessGroupNameSlugMap(data.businessGroups)}
                      enteredTextLabel={() => `${newBusinessUnit.businessGroupSlug}`}
                      onBlur={() => {
                        const isInvalid = businessGroupNameSlugMap(data.businessGroups)
                          ?.map(bg => bg.value).includes(newBusinessUnit.businessGroupSlug);
                        setInvalidInput({...invalidInput, businessGroupSlug: !isInvalid});
                      }}
                      errorText={`Error fetching ${BUSINESS_PROPERTY.BUSINESS_GROUP}s`}
                      statusType={isLoading ? "loading" : serverError ? "error" : "finished"}
                    />
                  </FormField>
                  <FormField
                    label={`${BUSINESS_PROPERTY.BUSINESS_UNIT} slug`}
                    description="Must be all lowercase [a-z][0-9]"
                    errorText={invalidInput.businessUnitSlug ? "This is a required input which must be at least 2 characters and ^[a-z0-9]+$": ""}
                  >
                    <Input
                      data-testid="business-unit-slug-input"
                      placeholder={`${BUSINESS_PROPERTY.BUSINESS_UNIT} slug`}
                      name={newBusinessUnit.businessUnitSlug}
                      value={newBusinessUnit.businessUnitSlug}
                      invalid={invalidInput.businessUnitSlug}
                      disabled={posting || isUpdate}
                      onChange={({ detail: { value } } ) => handleInputChange("businessUnitSlug", value)}
                      onBlur={() => {
                        const isInvalid = newBusinessUnit["businessUnitSlug"].length < 2 || !(/^[a-z0-9]+$/.test(newBusinessUnit.businessUnitSlug));
                        setInvalidInput({...invalidInput, businessUnitSlug: isInvalid});
                      }}
                    />
                  </FormField>
                  <FormField
                    label="Budget Class"
                    description="Budget Class must be 4 characters"
                    errorText={invalidInput.budgetClass ? "This is a required input and must be exactly 4 alpha characters": ""}
                  >
                    <Input
                      data-testid="business-unit-budget-class-input"
                      placeholder="Budget Class"
                      inputMode="text"
                      name={newBusinessUnit.budgetClass}
                      value={newBusinessUnit.budgetClass}
                      invalid={invalidInput.budgetClass}
                      disabled={posting}
                      onChange={({ detail: { value } } ) => {
                        value = value.toUpperCase();
                        handleInputChange("budgetClass", value);
                      }}
                      onBlur={() => {
                        const isInvalid = !(/^[A-Z]{4}$/.test(newBusinessUnit["budgetClass"]));
                        setInvalidInput({ ...invalidInput, budgetClass: isInvalid});
                      }}
                    />
                  </FormField>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <FormField
                    label={`${BUSINESS_PROPERTY.BUSINESS_UNIT} name`}
                    description="Enter a short name for the new Business Unit which will appear in the UI and downstream in reporting data"
                    errorText={invalidInput.businessUnitName ? "This is a required field with at least 3 characters.": ""}
                  >
                    <Input
                      data-testid="business-unit-name-input"
                      placeholder={`${BUSINESS_PROPERTY.BUSINESS_UNIT} name`}
                      name={newBusinessUnit.businessUnitName}
                      value={newBusinessUnit.businessUnitName}
                      invalid={invalidInput.businessUnitName}
                      disabled={posting}
                      onChange={({ detail: { value } } ) => handleInputChange("businessUnitName", value)}
                      onBlur={() => {
                        const isInvalid = newBusinessUnit["businessUnitName"].length < 3;
                        setInvalidInput({...invalidInput, businessUnitName: isInvalid});
                      }}
                    />
                  </FormField>
                  <FormField
                    label="Business Unit Admin"
                    description={`Alias of the POC for the ${BUSINESS_PROPERTY.BUSINESS_UNIT}`}
                    errorText={invalidInput.businessUnitPOC ? "This is a required input with at least 3 characters": ""}
                  >
                    <Input
                      data-testid="business-unit-owner-name-input"
                      placeholder={`${BUSINESS_PROPERTY.BUSINESS_UNIT} Admin's alias`}
                      name={newBusinessUnit.businessUnitPOC}
                      value={newBusinessUnit.businessUnitPOC}
                      invalid={invalidInput.businessUnitPOC}
                      disabled={posting}
                      onChange={({ detail: { value } } ) => handleInputChange("businessUnitPOC", value)}
                      onBlur={() => {
                        const isInvalid = newBusinessUnit["businessUnitPOC"].length < 3;
                        setInvalidInput({...invalidInput, businessUnitPOC: isInvalid});
                      }}
                    />
                  </FormField>
                </SpaceBetween>
              </Grid>
            </Container>
            <Container
              header={
                <Header variant="h3">
                  POSIX groups
                </Header>
              }
            >
              <SpaceBetween size="l">
                <FormField
                  label="Read/Write POSIX group"
                  description={
                    <>
                      Add Read/Write POSIX group(s); separating each by a comma. These must end with <b><i>rw</i></b>.
                      <div>You can visit{" "}
                        <a href={LINKS_CONFIG.AMAZON_TEAM_URL} target="_blank" rel="noreferrer">{LINKS_CONFIG.AMAZON_TEAM_URL}</a> 
                        {" "}to create new group(s).
                      </div>
                    </>
                  }
                >
                  <Input
                    data-testid="business-unit-rw-posix-input"
                    placeholder="Add read/write group(s)"
                    value={posixGroups.accessGroupsRW}
                    name={posixGroups.accessGroupsRW}
                    disabled={posting}
                    onChange={({ detail: { value } } ) => {
                      setPosixGroups({
                        ...posixGroups,
                        accessGroupsRW: value,
                      });
                    }}
                    onBlur={() => {
                      setNewBusinessUnit({
                        ...newBusinessUnit,
                        accessGroupsRW: posixGroups.accessGroupsRW.split(","),
                      });
                    }}
                  />
                </FormField>
                <FormField
                  label="Read-only POSIX group"
                  description={
                    <>
                      Add Read-only POSIX group(s) separating each by a comma. These must end with <b><i>ro</i></b>.
                      <div>You can visit{" "}
                        <a href={LINKS_CONFIG.AMAZON_TEAM_URL} target="_blank" rel="noreferrer">{LINKS_CONFIG.AMAZON_TEAM_URL}</a> 
                        {" "}to create new group(s).
                      </div>
                    </>
                  }
                >
                  <Input
                    data-testid="business-unit-ro-posix-input"
                    placeholder="Add read-only group(s)"
                    value={posixGroups.accessGroupsRO}
                    name={posixGroups.accessGroupsRO}
                    disabled={posting}
                    onChange={({ detail: { value } } ) => {
                      setPosixGroups({
                        ...posixGroups,
                        accessGroupsRO: value,
                      });
                    }}
                    onBlur={() => {
                      setNewBusinessUnit({
                        ...newBusinessUnit,
                        accessGroupsRO: posixGroups.accessGroupsRO.split(","),
                      });
                    }}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};


export const CreateBusinessUnitContainer: React.FC = (): JSX.Element => {

  const { getIdToken, userAttributes } = useContext(AuthContext);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const navigate = useNavigate();

  return (
    <div data-testid="create-new-business-unit-container">
      <CreateBusinessUnit 
        getIdToken={getIdToken}
        email={userAttributes?.email as string}
        addMessage={addMessage}
        navigate={navigate}
      />
    </div>
  );
};
