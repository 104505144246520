import {
  FormField,
  Input,
  Select,
  SpaceBetween,
  Tiles,
} from "@cloudscape-design/components";
import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  API_CALL_NAME,
  REQUEST_ID_SELECTOR,
} from "../../../data/constants/common";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { AuthContext } from "../../../context/AuthContext";
import { getEnvironmentVariables } from "../../../data/constants/environment";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import { getBusinessUnitsOptions } from "../../../data/helpers/functions";

export interface ITaCSIDSelectorProps {
  isEdit: boolean;
  selector: string;
  setSelector: (selector: string) => void;
  tacsID: string;
  setTacsID: (tacsID: string) => void;
  campaignName: string;
  setCampaignName: (campaignName: string) => void;
  businessUnit: string;
  setBusinessUnit: (businessUnit: string) => void;
  invalidField: any;
  setInvalidField: (invalidFields: any) => void;
}

const TaCSIDSelector: FC<ITaCSIDSelectorProps> = (
  props: ITaCSIDSelectorProps
): ReactElement | null => {
  const {
    isEdit,
    selector,
    setSelector,
    tacsID,
    setTacsID,
    campaignName,
    setCampaignName,
    businessUnit,
    setBusinessUnit,
    invalidField,
    setInvalidField,
  } = props;
  const { selectedBG, getIdToken } = useContext(AuthContext);

  const [campaignList, setCampaignList] = useState<any[]>([]);
  const [status, setStatus] =
    useState<DropdownStatusProps.StatusType>("loading");
  const [businessUnitOption, setBusinessUnitOption] =
    useState<OptionDefinition | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tacsIDErrorMessage, setTacsIDErrorMessage] = useState("");

  const businessUnitList = getBusinessUnitsOptions(selectedBG);

  const handleFetchCampaignUsingTaCSIDError = () => {
    setTacsIDErrorMessage(
      "This TaCSID does not exist or you do not have sufficient permissions for this campaign"
    );
    setInvalidField((prevState: any) => {
      return { ...prevState, tacsId: true };
    });
    setCampaignName("");
  };

  const fetchCampaignUsingTaCSID = async (tacsID: string) => {
    const url = `${getEnvironmentVariables().API_ENDPOINT}/${
      API_CALL_NAME.CAMPAIGNS
    }/?tacsId=${tacsID}&${encodeURI(
      businessUnitList.map((bu) => `bu=${bu.value}`).join("&")
    )}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
      });
      const data = await response.json();
      if (response.ok) {
        setBusinessUnit(data.campaigns[0].businessunitSlug);
        setCampaignName(data.campaigns[0].campaignname);
        setInvalidField((prevState: any) => {
          return { ...prevState, tacsId: false };
        });
      } else {
        handleFetchCampaignUsingTaCSIDError();
      }
    } catch (error: any) {
      handleFetchCampaignUsingTaCSIDError();
    }
  };

  const fetchCampaigns = async (isNewList = true) => {
    const page = isNewList ? 1 : currentPage;
    const url = `${getEnvironmentVariables().API_ENDPOINT}/${
      API_CALL_NAME.CAMPAIGNS
    }/?bu=${businessUnit}&currPage=${page}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
      });
      const data = await response.json();
      if (response.ok) {
        const newList = data.campaigns
          .filter((campaign: any) => campaign.accessLevel === "rw")
          .map((campaign: any) => {
            return { value: campaign.tacsid, label: `${campaign.campaignname} - ${campaign.tacsid}` };
          });
        setCurrentPage(page + 1);
        isNewList
          ? setCampaignList(newList)
          : setCampaignList([...campaignList, ...newList]);
        page > data.page.totalPages
          ? setStatus("finished")
          : setStatus("pending");
      } else {
        setStatus("error");
      }
    } catch (error: any) {
      setStatus("error");
    }
  };

  const handleOnLoadCampaigns = (detail: any) => {
    if (!detail.firstPage) {
      setStatus("loading");
      const isNewList = false;
      fetchCampaigns(isNewList);
    }
  };

  const handleOnChangeCampaign = (detail: any) => {
    setCampaignName(detail.label);
    setTacsID(detail.value);
  };

  useEffect(() => {
    if (isEdit) {
      fetchCampaignUsingTaCSID(tacsID);
    }
  }, []);

  useEffect(() => {
    if (businessUnitOption) {
      fetchCampaigns();
    }
  }, [businessUnitOption]);

  const handleOnChangeTacsID = (tacsID: string) => {
    setTacsID(tacsID);
    setCampaignName("");
    const regex = /[A-Z]{4}[\d]{2}[A-Z]{2}[A-Z0-9]{3}$/g;
    if (regex.test(tacsID)) {
      fetchCampaignUsingTaCSID(tacsID);
    } else {
      setTacsIDErrorMessage("The ID entered does not match the TaCS ID Format");
      setInvalidField((prevState: any) => {
        return { ...prevState, tacsId: true };
      });
    }
  };

  return (
    <SpaceBetween size="l">
      {!isEdit && (
        <FormField
          stretch={true}
          label="TaCS Campaign"
          description="Choose the TaCS Campaign where the Flight Plan will be created"
        >
          <Tiles
            data-testid="request-selector-tile"
            items={[
              {
                value: REQUEST_ID_SELECTOR.WITH_TACS_ID,
                label: "I have a TaCS ID to enter",
                description: "",
              },
              {
                value: REQUEST_ID_SELECTOR.WITHOUT_TACS_ID,
                label: "I don't have a TaCS ID to enter",
                description: "",
              },
            ]}
            value={selector}
            onChange={(e) => setSelector(e.detail.value)}
          />
        </FormField>
      )}
      {selector === REQUEST_ID_SELECTOR.WITH_TACS_ID ? (
        <>
          <FormField
            description="Enter the TaCS ID"
            errorText={invalidField.tacsId && tacsIDErrorMessage}
            constraintText={
              <>
                TaCS ID must have 11 characters. Character count:{" "}
                {tacsID.length}/11
              </>
            }
            label={
              <span>
                TaCS ID <i>- required</i>
              </span>
            }
          >
            <Input
              invalid={invalidField.tacsId}
              disabled={isEdit}
              data-testid="tacsid-input"
              value={tacsID}
              onChange={({ detail }) => handleOnChangeTacsID(detail.value)}
            />
          </FormField>
          <FormField
            label="Campaign name"
            description="This will auto-populate based on your TaCS ID entry"
          >
            <Input
              data-testid="campaign-name-input"
              disabled={true}
              value={campaignName}
            />
          </FormField>
        </>
      ) : (
        <>
          <FormField
            label="Business Unit"
            description="Select the Business Unit in which the TaCS Campaign was created"
            errorText={
              invalidField.businessUnitSlug && "Please select a business unit"
            }
          >
            <Select
              data-testid="business-unit-select"
              invalid={invalidField.businessUnitSlug}
              selectedOption={businessUnitOption}
              options={businessUnitList}
              loadingText="Loading business units"
              placeholder="Choose business units"
              onChange={({ detail }) => {
                setBusinessUnitOption(detail.selectedOption);
                handleOnChangeCampaign({ value: "", label: "" });
                if (detail.selectedOption.value) {
                  setBusinessUnit(detail.selectedOption.value);
                }
              }}
            />
          </FormField>
          <FormField
            label="Campaign name"
            description="This will auto-populate based on your TaCS ID entry"
            errorText={invalidField.tacsId && "Please select a campaign"}
          >
            <Select
              disabled={!businessUnitOption}
              data-testid="campaign-name-select"
              invalid={invalidField.tacsId}
              selectedOption={{ value: campaignName, label: campaignName }}
              options={campaignList}
              loadingText="Loading campaigns"
              placeholder="Choose campaigns"
              statusType={status}
              onChange={({ detail }) =>
                handleOnChangeCampaign(detail.selectedOption)
              }
              onLoadItems={({ detail }) => handleOnLoadCampaigns(detail)}
            />
          </FormField>
        </>
      )}
    </SpaceBetween>
  );
};

export default TaCSIDSelector;
