import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  BreadcrumbGroup,
  BreadcrumbGroupProps,
  Hotspot,
} from "@cloudscape-design/components";
import { useNavigate, useLocation } from "react-router-dom";
import { PATHS, PATH_LABEL } from "../../data/constants/navigation";
import { getHotspotByPage } from "../tutorials/Tutorials";
import { getPageName } from "../../data/constants/common";

const BreadCrumbs: FC = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const formatItem = (str: string, index: number): string => {
    if (index === 0) return PATH_LABEL[PATHS.MANAGE];

    if (Object.prototype.hasOwnProperty.call(PATH_LABEL, str))
      return PATH_LABEL[str];
    return str;
  };

  const [items, setItems] = useState<BreadcrumbGroupProps.Item[]>([]);
  const { pathname } = location;
  useEffect(() => {
    const items: BreadcrumbGroupProps.Item[] = [];

    const paths = pathname.split("/");

    paths.forEach((path: string, index: number) => {
      //Do not show business group related paths in breadcrumbs.
      if (
        index === 2 &&
        paths[index - 1] === PATHS.CAMPAIGNS &&
        path !== PATHS.CREATE
      ) {
        return;
      }

      items.push({
        href: paths.slice(0, index + 1).join("/"),
        text: formatItem(path, index),
      });
    });

    setItems(items);
  }, [location]);

  const onClickHandler = (
    event: CustomEvent<BreadcrumbGroupProps.ClickDetail>
  ): void => {
    event.preventDefault();
    const {
      detail: { href },
    } = event;
    navigate(href);
  };

  return (
    <Hotspot
      hotspotId={getHotspotByPage(getPageName(pathname)).manageEntity}
      side="left"
      direction="bottom"
    >
      <BreadcrumbGroup
        items={items}
        onClick={onClickHandler}
        expandAriaLabel="Show path"
        ariaLabel="Breadcrumbs"
      />
    </Hotspot>
  );
};

export default BreadCrumbs;
