import { IErrorMessage } from "../interfaces/IErrorMessage";

export const INVALID_INPUT: IErrorMessage = {
  id: "invalid-input",
  message:
    "Invalid request. Please view additional details below and if the issue persists, please reach out to MODE Engineering team for technical support: ",
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Support/Bugs/",
};

export const NO_ACCESS_TO_BG = (businessGroup: string) => ({
  id: "no-access-to-bg",
  message:
    `Business Group ${businessGroup} is currently unavailable to you. We kindly request that you reach out to the owner of this business group in order to request access: `,
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/FAQs/Access/",
});

export const NO_ACCESS_TO_BU = (businessUnit: string | undefined) => ({
  id: "no-access-to-bu",
  message:
    `Business Unit ${businessUnit} is currently unavailable to you. We kindly request that you reach out to the owner of this business unit in order to request access: `,
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/FAQs/Access/",
});

export const DOES_NOT_EXIST = (resourceName: string | undefined) => ({
  id: "does-not-exist",
  message:
    `Sorry, but the ${resourceName} you're attempting to access does not exist. Please reach out to MODE Product team for further support: `,
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Support/Bugs/",
});

export const ALREADY_EXISTS = (resourceName: string | undefined) => ({
  id: "already-exists",
  message:
    `Sorry, but the ${resourceName} you're attempting to create already exists. Please reach out to MODE Product team for further support: `,
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Support/Bugs/",
});

export const INTERNAL_SERVER_MESSAGE: IErrorMessage = {
  id: "internal-server-message",
  message:
    "Please try again, if the error persists reach out to MODE Product team for further support: ",
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Support/Bugs",
};

export const DEFAULT_MESSAGE: IErrorMessage = {
  id: "default-error-message",
  message:
    "Please try again, if the error persists reach out to MODE Product team for further support: ",
  link: "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Support/Bugs",
};
