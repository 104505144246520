import React, { FC, ReactElement } from "react";

import {
  FormField,
  Select,
  Popover,
  Button
} from "@cloudscape-design/components";
import { IDropdownInputProps } from "../../../data/interfaces/IFormInputs";
import styles from "./Inputs.module.css";

const DropdownInput: FC<IDropdownInputProps> = (props: IDropdownInputProps): ReactElement | null => {
  const {
    label,
    description,
    error,
    setErrors,
    placeholder,
    isRequired,
    name,
    onInputChange,
    inputValue,
    options,
    disabled,
    testid,
    infoDescription,
    expandToViewport,
    showCSS,
  } = props;

  const errorText = error[name] != undefined ? error[name]["message"] : "";

  return (
    <div className={`${showCSS != false && styles.itemcol4}`}>
      <FormField
        label={
          <>
            {label} -{" "}
            <small>
              <i>
                {isRequired ? (
                  <span className={styles.required}>required</span>
                ) : (
                  <span className={styles.optional}>optional</span>
                )}
              </i>
            </small>
          </>
        }
        description={description}
        info={
          <Popover header={label} content={infoDescription} triggerType="custom">
            <Button iconName="status-info" variant="inline-icon" data-testid={label + "InfoButton"}/>
          </Popover>
        }
        i18nStrings={{ errorIconAriaLabel: "Error" }}
        errorText={errorText}
      >
        <Select
          expandToViewport={expandToViewport}
          options={options}
          selectedAriaLabel="Selected"
          placeholder={placeholder}
          errorText={errorText}
          recoveryText="Retry"
          filteringType="manual"
          filteringAriaLabel="Filter buckets"
          filteringClearAriaLabel="Clear"
          ariaRequired={true}
          selectedOption={inputValue}
          onChange={(event) => {
            onInputChange(event.detail.selectedOption, name);

            const updatedErrors = error;
            delete updatedErrors[name];
            setErrors(updatedErrors);
          }}
          disabled={disabled}
          name={name}
          data-testid={testid}
        />
      </FormField>
    </div>
  );
};

export default DropdownInput;
