import React, { ReactElement, FC } from "react";
import CurrencyInput from "react-currency-input-field";
import Icon from "@cloudscape-design/components/icon";
import { IMODECurrencyInputProps } from "../../../data/interfaces/IFormInputs";
import styles from "./Inputs.module.css";
import { getLanguageCode } from "../../../views/campaign/config";

const getCurrencySymbol = (currency: string) => {
  const locale = getLanguageCode(currency);
  const symbol = (0)
    .toLocaleString(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
  return symbol;
};

const MODECurrencyInput: FC<IMODECurrencyInputProps> = (
  props: IMODECurrencyInputProps
): ReactElement | null => {
  const {
    name,
    error,
    setErrors,
    isRequired,
    isDisabled,
    label,
    inputValue,
    currency,
    onInputChange,
    onBlurEvent,
    testid,
    // infoDescription // TODO: infoDescription in UI to be implemented in diff SIM
  } = props;

  return (
    <div className={`${styles.itemcol4} ${styles.currencyStyle}`}>
      <div className={styles.currencyFieldLabelContainer}>
        <label className={styles.currencyFieldLabel}>
          {label} - {""}
          <small>
            <i>
              {isRequired ? (
                <span className={styles.required}>required</span>
              ) : (
                <span className={styles.optional}>optional</span>
              )}
            </i>
          </small>
        </label>
        <span className={styles.fieldInfoIcon}>
          <Icon name="status-info" variant="link" />
        </span>
      </div>
      <CurrencyInput
        className={
          error[name] ? styles.inputErrorStyle : styles.currencyInputField
        }
        intlConfig={{ locale: getLanguageCode(currency), currency: currency }}
        name={name}
        placeholder={new Intl.NumberFormat(getLanguageCode(currency), {
          style: "currency",
          currency: currency,
          currencyDisplay: "symbol",
        }).format(123456)}
        decimalsLimit={2}
        onValueChange={(value: any, name: any) => {
          if (name !== undefined) {
            onInputChange(value, name);
            const updatedErrors = error;
            delete updatedErrors[name];
            setErrors(updatedErrors);
          }
        }}
        onBlur={onBlurEvent}
        allowNegativeValue={false}
        value={inputValue}
        prefix={getCurrencySymbol(currency)}
        disabled={isDisabled}
        data-testid={testid}
      />
      {error[name] ? (
        <div className={styles.errorContainer}>
          <Icon name="status-warning" variant="error" size="small" />
          <span className={styles.errorMessage}>{error[name].message}</span>
        </div>
      ) : null}
    </div>
  );
};

export default MODECurrencyInput;
