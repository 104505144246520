import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import Header from "./components/header/Header";
import BreadCrumbs from "./components/layout/BreadCrumbs";
import ViewPort from "./views/common/ViewPort";
import { getNavItems } from "./data/constants/navigation";

import { AppLayout, HelpPanel, SideNavigation, Tabs, TutorialPanel } from "@cloudscape-design/components";
import { AuthContext } from "./context/AuthContext";
import FlashBarMessages from "./components/common/FlashbarMessages";
import { useNavigate } from "react-router-dom";

import { HelpPanelTabs, useTutorialContext } from "./components/tutorials/store";
import { tutorialPanelI18nStrings } from "./components/tutorials/Tutorials";
import { footer, HelpPanelContent } from "./components/tutorials/HelpPanelContent";
import { DOWNLOADABLE_TUTORIAL_URL } from "./components/tutorials/constants";
import ErrorBoundary from "./components/errorboundary/ErrorBoundary";

const App: FC = (): ReactElement => {
  const { selectedBG, isExternal } = useContext(AuthContext);
  const { 
    state: { tutorials, helpPanelTopic, toolsTab, toolsOpen, toolsHide, },
    actions: { setToolsTab, setToolsOpen, }
  } = useTutorialContext();
  const helpPanels = HelpPanelContent();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const [activeHref, setActiveHref] = useState(
    pathName === "/" ? "/campaigns" : pathName
  );
  useEffect(() => {
    setActiveHref(pathName);
  }, [pathName]);

  return (
    <div data-testid="cloudscape">
      <Header />
      <AppLayout
        stickyNotifications
        toolsHide={toolsHide}
        toolsOpen={toolsOpen}
        onToolsChange={(event => setToolsOpen(event.detail.open))}
        tools={
          <Tabs
            activeTabId={toolsTab}
            onChange={event => setToolsTab(event.detail.activeTabId as HelpPanelTabs)}
            tabs={[
              {
                id: HelpPanelTabs.INFO,
                label: "Info",
                content: helpPanels.has(helpPanelTopic) ? (
                  helpPanels.get(helpPanelTopic)
                ) : (
                  <HelpPanel
                    header={<h2>Learn about TaCS</h2>}
                    footer={footer}
                  >
                    <p>Learn about Campaigns on the TaCS wiki</p>
                  </HelpPanel>
                )
              },
              {
                id: HelpPanelTabs.TUTORIAL,
                label: "Tutorials",
                content: (
                  <TutorialPanel
                    i18nStrings={tutorialPanelI18nStrings}
                    tutorials={tutorials}
                    onFeedbackClick={() => ""}
                    downloadUrl={DOWNLOADABLE_TUTORIAL_URL}
                  />
                ),
              },
            ]}
          />
        }
        disableBodyScroll
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        maxContentWidth={Number.MAX_VALUE}
        navigation={
          <SideNavigation
            header={{ href: "/#", text: "TaCS" }}
            activeHref={activeHref}
            items={getNavItems(selectedBG, isExternal)}
            onFollow={(event) => {
              if (!event.detail.external) {
                event.preventDefault();
                const href = event.detail.href;
                setActiveHref(href);
                navigate(href);
              }
            }}
          />
        }
        breadcrumbs={<BreadCrumbs />}
        notifications={<FlashBarMessages />}
        content={
          <ErrorBoundary>
            <ViewPort />
          </ErrorBoundary>
        }
      />
    </div>
  );
};

export default App;
