export const SANDBOX_CONFIG = {
  SANDBOX_1: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:909bca04-4943-4e67-a733-61167083b82d",
    COGNITO_USER_POOL_ID: "us-west-2_moAx9PWo7",
    COGNITO_WEB_CLIENT_ID: "f87akcal725rj7rn6fa92onac",
    COGNITO_DOMAIN:
      "tacs-oidc-app-dev-sandbox.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: "http://localhost:3000",
    COGNITO_REDIRECT_SIGNOUT: "http://localhost:3000",
    API_ENDPOINT:
      "https://jw26rqtbdb.execute-api.us-west-2.amazonaws.com/dev-sandbox",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-dev-sandbox",
    S3_BULK_PROCESSING_BUCKET_NAME:
      "mode-tacs-bulk-processed-bucket-dev-sandbox",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-dev-sandbox",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-dev-sandbox",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-dev-sandbox",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-dev-sandbox",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-dev-sandbox",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-dev-sandbox",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
    "mode-tacs-bulk-upload-user-bucket-dev-sandbox",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
    "mode-tacs-bulk-processed-user-bucket-dev-sandbox",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "308c80100543484ebb21af25acb55347",
    RUM_APP_ID: "c287065f-14ff-4fbc-8c24-1c943aa0dc69",
    SEARCH_SERVICE_ENDPOINT: "https://k5g2wdukgk.execute-api.us-west-2.amazonaws.com/dev-sandbox1/search",
  },
  SANDBOX_2: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:c8a05afc-ed76-4aa2-af6b-a73649f0e596",
    COGNITO_USER_POOL_ID: "us-west-2_2f9ZreX6S",
    COGNITO_WEB_CLIENT_ID: "6uosrnurtbo5ofmo5oo5rj94jd",
    COGNITO_DOMAIN:
      "tacs-oidc-app-dev-sandbox2.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: "http://localhost:3000",
    COGNITO_REDIRECT_SIGNOUT: "http://localhost:3000",
    API_ENDPOINT:
      "https://0j5mmgjtzd.execute-api.us-west-2.amazonaws.com/dev-sandbox2",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-dev-sandbox2",
    S3_BULK_PROCESSING_BUCKET_NAME:
      "mode-tacs-bulk-processed-bucket-dev-sandbox2",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-dev-sandbox2",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-dev-sandbox2",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-dev-sandbox2",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-dev-sandbox2",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-dev-sandbox2",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-dev-sandbox2",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
    "mode-tacs-bulk-upload-user-bucket-dev-sandbox2",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
    "mode-tacs-bulk-processed-user-bucket-dev-sandbox2",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "00c18215438b46b3b25e0d4132e5e1f6",
    RUM_APP_ID: "b6058be1-a544-4d9b-b5c3-43994e8783e0",
    SEARCH_SERVICE_ENDPOINT: "https://hadrmzrw3i.execute-api.us-west-2.amazonaws.com/dev-sandbox2/search",
  },
  SANDBOX_3: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:f9c2e48c-6d73-4f41-9ed9-6da79adce9f8",
    COGNITO_USER_POOL_ID: "us-west-2_godhDDf0R",
    COGNITO_WEB_CLIENT_ID: "6erl542obihl731rn89vrpe97l",
    COGNITO_DOMAIN:
      "tacs-oidc-app-dev-sandbox3.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: "http://localhost:3000",
    COGNITO_REDIRECT_SIGNOUT: "http://localhost:3000",
    API_ENDPOINT:
      "https://h5w8b3s0tb.execute-api.us-west-2.amazonaws.com/dev-sandbox3",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-dev-sandbox3",
    S3_BULK_PROCESSING_BUCKET_NAME:
      "mode-tacs-bulk-processed-bucket-dev-sandbox3",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-dev-sandbox3",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-dev-sandbox3",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-dev-sandbox3",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-dev-sandbox3",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-dev-sandbox3",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-dev-sandbox3",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
    "mode-tacs-bulk-upload-user-bucket-dev-sandbox3",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
    "mode-tacs-bulk-processed-user-bucket-dev-sandbox3",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "136d5058723f4b8e92db6036059ba61b",
    RUM_APP_ID: "47e0c679-8a68-4b60-8087-4914783ff6c0",
    SEARCH_SERVICE_ENDPOINT: "https://wvy9yeg6ga.execute-api.us-west-2.amazonaws.com/dev-sandbox3/search",
  },
  SANDBOX_4: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:b67a7c68-4aea-44af-98ed-b87156a34a82",
    COGNITO_USER_POOL_ID: "us-west-2_KhbNSlWsL",
    COGNITO_WEB_CLIENT_ID: "6rvhe1e9aqas3fgj3e26q6dbkd",
    COGNITO_DOMAIN:
      "tacs-oidc-app-dev-sandbox4.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: "http://localhost:3000",
    COGNITO_REDIRECT_SIGNOUT: "http://localhost:3000",
    API_ENDPOINT:
      "https://8sw70f3qc5.execute-api.us-west-2.amazonaws.com/dev-sandbox4",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-dev-sandbox4",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-dev-sandbox4",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-dev-sandbox4",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-dev-sandbox4",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-dev-sandbox4",
    S3_BULK_PROCESSING_BUCKET_NAME:
      "mode-tacs-bulk-processed-bucket-dev-sandbox4",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-dev-sandbox4",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-dev-sandbox4",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
    "mode-tacs-bulk-upload-user-bucket-dev-sandbox4",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
    "mode-tacs-bulk-processed-user-bucket-dev-sandbox4",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "b617681b9ea142e496f68f5658ac74d7",
    RUM_APP_ID: "82cdef94-5029-49c1-bf2d-56b2bcfbae80",
    SEARCH_SERVICE_ENDPOINT: "https://zz6k2a2uyi.execute-api.us-west-2.amazonaws.com/dev-sandbox4/search",
  },
  SANDBOX_5: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:74953ae1-a62b-45ea-8619-cbef519e571c",
    COGNITO_USER_POOL_ID: "us-west-2_csX5Lwtss",
    COGNITO_WEB_CLIENT_ID: "4aam5cr7599f6o0v0phsqb2vlu",
    COGNITO_DOMAIN:
      "tacs-oidc-app-dev-sandbox5.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: "http://localhost:3000",
    COGNITO_REDIRECT_SIGNOUT: "http://localhost:3000",
    API_ENDPOINT:
      "https://291r0viix9.execute-api.us-west-2.amazonaws.com/dev-sandbox5",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-dev-sandbox5",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-dev-sandbox5",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-dev-sandbox5",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-dev-sandbox5",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-dev-sandbox5",
    S3_BULK_PROCESSING_BUCKET_NAME:
      "mode-tacs-bulk-processed-bucket-dev-sandbox5",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-dev-sandbox5",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-dev-sandbox5",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
      "mode-tacs-bulk-upload-user-bucket-dev-sandbox5",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
      "mode-tacs-bulk-processed-user-bucket-dev-sandbox5",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "aad59e5ef0d64b6c91fcf3f91ddca0f1",
    RUM_APP_ID: "b4b29700-bbe2-4771-9969-243fcb950c65",
    SEARCH_SERVICE_ENDPOINT: "https://a3ui7tc481.execute-api.us-west-2.amazonaws.com/dev-sandbox5/search", 
  }
};
