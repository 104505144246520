export enum TutorialHotspot {
  // Campaigns
  manageCampaign = "manage-campaign",
  createCampaignBtn = "create-campaign-btn",
  filterCampaignItems = "filter-campaign-items",
  paginationAndDisplay = "pagination-and-display",
  
  //Flight plans
  manageFlightPlan = "manage-flight-plan",
  createFlightPlan = "create-flight-plan",
  searchFlightPlan = "search-flight-plan",
  viewDisplayOptions = "view-display-options",

  //Default
  defaultContent = "default-content",
}

export const HOT_SPOTS = {
  CAMPAIGNS: {
    manageEntity: TutorialHotspot.manageCampaign,
    createNewItem: TutorialHotspot.createCampaignBtn,
    filterItems: TutorialHotspot.filterCampaignItems,
    changeDashboardSettings: TutorialHotspot.paginationAndDisplay,
  },
  FLIGHT_PLANS: {
    manageEntity: TutorialHotspot.manageFlightPlan,
    createNewItem: TutorialHotspot.createFlightPlan,
    filterItems: TutorialHotspot.searchFlightPlan,
    changeDashboardSettings: TutorialHotspot.viewDisplayOptions,
  },
  DEFAULT: {
    default : TutorialHotspot.defaultContent,
  }
};

export enum TUTORIALS {
  MANAGE_CAMPAIGN = "manage-campaigns",
  MANAGE_FLIGHT_PLAN = "manage-flight-plans",
}

export const CAMPAIGN_STEPS = 4;
export const FLIGHT_PLANS_STEPS = 4;
export const DOWNLOADABLE_TUTORIAL_URL = "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS"; //Placeholder until tutorial content is ready
export const MODE_WIKI_URL = "https://w.amazon.com/bin/view/MODE/";
export const TaCS_WIKI_URL = "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS";
export const CAMPAIGN_WIKI_URL = "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Ontology/Entity/Campaign/";
export const FLIGHT_PLANS_WIKI_URL = "https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/Ontology/Entity/FlightPlan/";
export const TUTORIALS_COMPLETED = "tutorialsCompleted";
