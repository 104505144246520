import React, { createContext, FC, useState } from "react";
import {
  FlashbarProps,
  ExpandableSection,
} from "@cloudscape-design/components";
import { v4 as uuidv4 } from "uuid";
import styles from "./Context.module.css";

export type MessagesContextType = {
  messages: FlashbarProps.MessageDefinition[];
  addMessage: (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string
  ) => void;
  removeMessage: (id: string) => void;
};

export const MessageContext = createContext<MessagesContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const MessagesProvider: FC<Props> = ({ children }) => {
  const [messages, setMessages] = useState<FlashbarProps.MessageDefinition[]>(
    []
  );

  const addMessage = (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string
  ) => {
    let id = `message_${uuidv4()}`;
    if (messageData?.id) {
      id = messageData?.id;
    }

    const newMessage: FlashbarProps.MessageDefinition = {
      id: id,
      header: message.header,
      content: (
        <div>
          {message.content}
          {errorMessage && (
            <div className={styles.additionalDetails}>
              <ExpandableSection
                variant="footer"
                headerText="Additional Details Below"
                disableContentPaddings
              >
                {errorMessage}
              </ExpandableSection>
            </div>
          )}
        </div>
      ),
      dismissible: message.dismissible,
      dismissLabel: "Dismiss alert",
      type: message.type,
      onDismiss: () => removeMessage(id),
      action: message.action,
    };
    setMessages([...messages, newMessage]);
  };

  const removeMessage = (id: string) => {
    setMessages((items) => items.filter((item) => item.id !== id));
  };

  return (
    <MessageContext.Provider value={{ messages, addMessage, removeMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessagesProvider;
