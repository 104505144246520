import React, { useContext } from "react";
import { SideNavigationProps } from "@cloudscape-design/components/side-navigation";

import { allowFeature } from "../helpers/featureGate";
import { IBusinessGroup } from "../interfaces/IUser";
import { LINKS_CONFIG } from "./links";
import NewFeatureBox from "../../components/common/NewFeatureBox";
import { AuthContext } from "../../context/AuthContext";

const getFlightPlanSideNavMenu = (
  selectedBG: IBusinessGroup | null
): ReadonlyArray<
  | SideNavigationProps.Section
  | SideNavigationProps.Link
  | SideNavigationProps.LinkGroup
  | SideNavigationProps.ExpandableLinkGroup
> => {
  return allowFeature(selectedBG, true)
    ? [
      {
        type: "link",
        text: PATH_LABEL[PATHS.FLIGHT_PLANS],
        href: `/${PATHS.FLIGHT_PLANS}`,
        info: <NewFeatureBox featureLaunchDate={new Date("2023-12-15")} />,
      },
    ]
    : [];
};

const getBulkSideNavMenu = (
  selectedBG: IBusinessGroup | null,
  isExternal: boolean
): ReadonlyArray<
  | SideNavigationProps.Section
  | SideNavigationProps.Link
  | SideNavigationProps.LinkGroup
  | SideNavigationProps.ExpandableLinkGroup
> => {
  return isExternal && !allowFeature(selectedBG)
    ? []
    : [{ type: "link", text: PATH_LABEL[PATHS.BULK], href: `/${PATHS.BULK}` }];
};

const getMediaItemSideNavMenu = (
  selectedBG: IBusinessGroup | null
): ReadonlyArray<
  | SideNavigationProps.Section
  | SideNavigationProps.Link
  | SideNavigationProps.LinkGroup
  | SideNavigationProps.ExpandableLinkGroup
> => {
  return allowFeature(selectedBG, true)
    ? [
      {
        type: "link-group",
        text: PATH_LABEL[PATHS.MEDIA_ITEMS],
        href: `/${PATHS.MEDIA_ITEMS}`,
        items: [
          {
            type: "link",
            text: PATH_LABEL[PATHS.REQUEST_IDS],
            href: `/${PATHS.MEDIA_ITEMS}/${PATHS.REQUEST_IDS}`,
            info: (
              <NewFeatureBox featureLaunchDate={new Date("2024-01-01")} />
            ),
          },
        ],
      },
    ]
    : [];
};

const adminNavigationItem = (): SideNavigationProps.Item => {

  const { userAttributes } = useContext(AuthContext);
  const userIsAdmin = userAttributes?.isAdmin;

  const adminNavItems: SideNavigationProps.Item =
  userIsAdmin ?
    {
      type: "section-group",
      title: "Admin",
      items: [
        {
          type: "link-group",
          text: PATH_LABEL[PATHS.BUSINESS_MANAGEMENT],
          href: `/${PATHS.BUSINESS_MANAGEMENT}/${PATHS.BUSINESS_GROUPS}`,
          items: [
            {
              type: "link",
              text: PATH_LABEL[PATHS.BUSINESS_GROUPS],
              href: `/${PATHS.BUSINESS_MANAGEMENT}/${PATHS.BUSINESS_GROUPS}`,
            },
            {
              type: "link",
              text: PATH_LABEL[PATHS.BUSINESS_UNITS],
              href: `/${PATHS.BUSINESS_MANAGEMENT}/${PATHS.BUSINESS_UNITS}`,
            },
          ]
        },
        {
          type: "link",
          text: PATH_LABEL[PATHS.BUSINESS_LOGIC],
          href: `/${PATHS.BUSINESS_LOGIC}`,
        },
      ],
    }
    : {} as SideNavigationProps.Item;

  return adminNavItems;
};

export const getNavItems = (
  selectedBG: IBusinessGroup | null,
  isExternal: boolean,
): ReadonlyArray<SideNavigationProps.Item> => {
  return [
    {
      type: "section-group",
      title: "Manage",
      items: [
        {
          type: "link",
          text: PATH_LABEL[PATHS.CAMPAIGNS],
          href: `/${PATHS.CAMPAIGNS}`,
        },
        ...getFlightPlanSideNavMenu(selectedBG),
        ...getMediaItemSideNavMenu(selectedBG),
        ...getBulkSideNavMenu(selectedBG, isExternal),
      ],
    },
    adminNavigationItem(),
    { type: "divider" },
    {
      type: "link",
      text: "Documentation",
      href: LINKS_CONFIG.DOCUMENTATION_WIKI,
      external: true,
    },
  ];
};

export const PATH_LABEL: { [Key: string]: string } = {
  manage: "Manage",
  campaigns: "Campaigns",
  "flight-plans": "Flight Plans",
  "media-items": "Media Items",
  bulk: "Bulk jobs",
  "request-ids": "Request IDs",
  create: "Create",
  edit: "Edit",
  admin: "TaCS",
  "business-management": "Business Management",
  "business-groups": "Business Groups",
  "business-units": "Business Units",
  "business-logic": "Business Logic",
};

export const PATHS = {
  MANAGE: "manage",
  CAMPAIGNS: "campaigns",
  FLIGHT_PLANS: "flight-plans",
  MEDIA_ITEMS: "media-items",
  BULK: "bulk",
  REQUEST_IDS: "request-ids",
  CREATE: "create",
  EDIT: "edit",
  ADMIN: "admin",
  BUSINESS_MANAGEMENT: "business-management",
  BUSINESS_GROUPS: "business-groups",
  BUSINESS_UNITS: "business-units",
  BUSINESS_LOGIC: "business-logic",
};
