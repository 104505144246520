export const fixedPlannedSpendPieChartInputs = (fixedPlannedSpendData: any) => {
  return [
    {
      title: "Cinema",
      value:
        fixedPlannedSpendData.campaigncinemafixed == undefined
          ? 0
          : fixedPlannedSpendData.campaigncinemafixed,
    },
    {
      title: "Digital",
      value:
        fixedPlannedSpendData.campaigndigitalfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaigndigitalfixed,
    },
    {
      title: "Influencer",
      value:
        fixedPlannedSpendData.campaigninfluencersfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaigninfluencersfixed,
    },
    {
      title: "OOH",
      value:
        fixedPlannedSpendData.campaignoohfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaignoohfixed,
    },
    {
      title: "Print",
      value:
        fixedPlannedSpendData.campaignprintfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaignprintfixed,
    },
    {
      title: "Radio",
      value:
        fixedPlannedSpendData.campaignradiofixed == undefined
          ? 0
          : fixedPlannedSpendData.campaignradiofixed,
    },
    {
      title: "Social",
      value:
        fixedPlannedSpendData.campaignsocialfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaignsocialfixed,
    },
    {
      title: "TV",
      value:
        fixedPlannedSpendData.campaigntvfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaigntvfixed,
    },
    {
      title: "Other",
      value:
        fixedPlannedSpendData.campaignotherfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaignotherfixed,
    },
    {
      title: "Unallocated",
      value:
        fixedPlannedSpendData.campaignunallocatedfixed == undefined
          ? 0
          : fixedPlannedSpendData.campaignunallocatedfixed,
    },
  ];
};

export const variablePlannedSpendPieChartInputs = (variablePlannedSpendData: any) => {
  return [
    {
      title: "Digital",
      value:
        variablePlannedSpendData.campaigndigitalvariable == undefined
          ? 0
          : variablePlannedSpendData.campaigndigitalvariable,
    },
    {
      title: "TV",
      value:
        variablePlannedSpendData.campaigntvvariable == undefined
          ? 0
          : variablePlannedSpendData.campaigntvvariable,
    },
    {
      title: "Social",
      value:
        variablePlannedSpendData.campaignsocialvariable == undefined
          ? 0
          : variablePlannedSpendData.campaignsocialvariable,
    },
    {
      title: "Associates",
      value:
        variablePlannedSpendData.campaignassociatesvariable == undefined
          ? 0
          : variablePlannedSpendData.campaignassociatesvariable,
    },
    {
      title: "Mail",
      value:
        variablePlannedSpendData.campaignmailvariable == undefined
          ? 0
          : variablePlannedSpendData.campaignmailvariable,
    },
    {
      title: "Mobile",
      value:
        variablePlannedSpendData.campaignmobilevariable == undefined
          ? 0
          : variablePlannedSpendData.campaignmobilevariable,
    },
    {
      title: "Search",
      value:
        variablePlannedSpendData.campaignsearchvariable == undefined
          ? 0
          : variablePlannedSpendData.campaignsearchvariable,
    },
    {
      title: "Unallocated",
      value:
        variablePlannedSpendData.campaignunallocatedvariable == undefined
          ? 0
          : variablePlannedSpendData.campaignunallocatedvariable,
    },
  ];
};
