import React, {
  FC,
  ReactElement,
  useEffect,
  useState,
  useContext,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tabs,
  Grid,
  Button,
  StatusIndicator,
  Popover,
  SpaceBetween,
  ColumnLayout,
  ContentLayout,
  Header,
  Container,
} from "@cloudscape-design/components";
import SpendCard from "../../components/cards/SpendCard";
import SpendPieChartCard from "../../components/cards/SpendPieChartCard";
import ValueWithLabel from "../../components/cards/KeyValuePairCard";
import ScreenLoader from "../../components/common/ScreenLoader";
import { getEnvironmentVariables } from "../../data/constants/environment";
import { NO_ACCESS_TO_BU } from "../../data/constants/errorMessages";
import {
  RESOURCE_CAMPAIGN,
  VARIABLE_SPEND_SUFFIX,
  FIXED_SPEND_SUFFIX,
  ENTITY_TYPE,
} from "../../data/constants/common";
import { returnErrorMessage } from "../../data/helpers/returnErrorMessages";
import { formatDate } from "../../data/helpers/dates";
import styles from "./Campaign.module.css";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";
import { AuthContext } from "../../context/AuthContext";
import {
  CAMPAIGN_MEASUREMENT_MODELS,
  MARKETPLACE_OPTIONS,
  StatusColors,
} from "./config";
import {
  csvDownload,
  generateTemplateDownloadCSVName,
} from "../../data/helpers/utils";
import {
  fixedPlannedSpendPieChartInputs,
  variablePlannedSpendPieChartInputs,
} from "../../data/constants/campaign/SpendPieChartInputs";
import {
  FixedPlannedSpendInputs,
  VariablePlannedSpendInputs,
} from "../../data/constants/campaign/SpendInputs";
import ArchiveModal from "../../components/modals/ArchiveModal";
import { isAuthorizedActionForExternalUser } from "../../data/helpers/featureGate";

const CampaignView: FC<object> = (): ReactElement | null => {
  const { businessunitSlug, tacsid } = useParams<{
    businessunitSlug: string;
    tacsid?: string;
  }>();
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const {
    getIdToken,
    selectedBG,
    customCampaignAttributes: customAttributes,
    hasReadOnlyAccess,
    isAllowedAccessToBU,
    userBusinessGroupMap,
    updateCurrentSelectedBG,
    isExternal
  } = useContext(AuthContext);
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [statusStyleColor, setstatusStyleColor] = useState<string>("#414D5C");

  const [fixedPlannedSpendData, setFixedPlannedSpendData] = useState<any>({});
  const [variablePlannedSpendData, setVariablePlannedSpendData] = useState<any>(
    {}
  );
  const [fixedTotal, setFixedTotal] = useState<number>(0);
  const [variableTotal, setVariableTotal] = useState<number>(0);
  const [isArchiveModalVisible, setArchiveModalVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${
        getEnvironmentVariables().API_ENDPOINT
      }/campaigns/${businessunitSlug}/${tacsid}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        method: "GET",
      });
      const responseData = await response.json();

      if (!response.ok) {
        handleErrorResponse(response.status, responseData.message);
        return;
      }

      handleSuccessResponse(responseData.campaign);
    };

    const handleSuccessResponse = (data: any) => {
      setData(data);
      handleSpendData(data);
      setstatusStyleColor(
        StatusColors[data?.campaignsystemstatus as keyof typeof StatusColors]
      );
      setIsLoading(false);
    };

    const handleErrorResponse = (statusCode: number, errorMessage: string) => {
      setIsLoading(false);
      setHasError(true);
      const messageError = returnErrorMessage(
        statusCode,
        businessunitSlug,
        RESOURCE_CAMPAIGN
      );
      addMessage(
        {
          type: "error",
          content: messageError.message,
          dismissible: true,
        },
        messageError,
        errorMessage
      );
    };

    setIsLoading(true);

    if (userBusinessGroupMap != null) {
      if (
        isAllowedAccessToBU(businessunitSlug, userBusinessGroupMap) === true
      ) {
        updateCurrentSelectedBG(businessunitSlug, userBusinessGroupMap);
        fetchData();
      } else {
        setIsLoading(false);
        setHasError(true);
        addMessage(
          {
            type: "error",
            content: NO_ACCESS_TO_BU(businessunitSlug).message,
            dismissible: true,
          },
          NO_ACCESS_TO_BU(businessunitSlug)
        );
      }
    }
  }, [tacsid, userBusinessGroupMap]);

  const handleSpendData = (spendData: any) => {
    if (spendData != null) {
      const objKeys = Object.keys(spendData);
      const fixedPlannedSpendKeys = objKeys.filter((str) =>
        str.includes(FIXED_SPEND_SUFFIX)
      );

      let fixedSum = 0;
      fixedPlannedSpendKeys.map((spendItem: any) => {
        setFixedPlannedSpendData((prevValues: any) => ({
          ...prevValues,
          [spendItem]: spendData[spendItem],
        }));
        fixedSum += spendData[spendItem];
        setFixedTotal(fixedSum);
      });

      const variablePlannedSpendKeys = objKeys.filter((str) =>
        str.includes(VARIABLE_SPEND_SUFFIX)
      );

      let variableSum = 0;
      variablePlannedSpendKeys.map((spendItem: any) => {
        setVariablePlannedSpendData((prevValues: any) => ({
          ...prevValues,
          [spendItem]: spendData[spendItem],
        }));
        variableSum += spendData[spendItem];
        setVariableTotal(variableSum);
      });
    }
  };

  const FixedSpend = () => {
    return (
      <div className={styles.spendFlex}>
        <div className={styles.flexHalf}>
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
            ]}
          >
            <SpendCard
              name="Unallocated"
              currency={data.currency}
              spendAmt={fixedPlannedSpendData.campaignunallocatedfixed}
              infoDescription="Initial fixed planned spend that has yet to be allocated to a channel."
              testid="unallocated-field"
            />

            {FixedPlannedSpendInputs.map((spend: any, index: number) => (
              <SpendCard
                key={index}
                name={spend.spendChannel}
                currency={data.currency}
                spendAmt={fixedPlannedSpendData[spend.name]}
                infoDescription={spend.infoDescription}
                testid={`${spend.testid}-field`}
              />
            ))}
          </Grid>
        </div>
        <div className={styles.flexHalf} style={{ paddingLeft: "20px" }}>
          <Tabs
            tabs={[
              {
                label: "Planned",
                id: "fixedPlannedSpend",
                content: (
                  <SpendPieChartCard
                    data={fixedPlannedSpendPieChartInputs(data)}
                    total={fixedTotal}
                    currency={data.currency || "USD"}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    );
  };
  const VariableSpend = () => {
    return (
      <div className={styles.spendFlex}>
        <div className={styles.flexHalf}>
          <Grid
            gridDefinition={[
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
              { colspan: 4 },
            ]}
          >
            <SpendCard
              name="Unallocated"
              currency={data.currency}
              spendAmt={variablePlannedSpendData.campaignunallocatedvariable}
              infoDescription="Initial variable planned spend that has yet to be allocated to a channel."
              testid="unallocated-field-variable"
            />

            {VariablePlannedSpendInputs.map((spend: any, index: number) => (
              <SpendCard
                key={index}
                name={spend.spendChannel}
                currency={data.currency}
                spendAmt={variablePlannedSpendData[spend.name]}
                infoDescription={spend.infoDescription}
                testid={`${spend.testid}-field`}
              />
            ))}
          </Grid>
        </div>
        <div className={styles.flexHalf} style={{ paddingLeft: "20px" }}>
          <Tabs
            tabs={[
              {
                label: "Planned",
                id: "variablePlannedSpend",
                content: (
                  <SpendPieChartCard
                    data={variablePlannedSpendPieChartInputs(data)}
                    total={variableTotal}
                    currency={data.currency || "USD"}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const getMeasurementLabel = (measurement: string) => {
    const measurementObj =
      CAMPAIGN_MEASUREMENT_MODELS.find((item) => item.value === measurement) ||
      {};
    return measurementObj.label;
  };

  const onEdit = (): void => {
    const id = data.tacsid;
    const businessunitSlug = data.businessunitSlug;
    navigate(`/campaigns/${businessunitSlug}/${id}/edit`);
  };

  const onExport = (): void => {
    csvDownload(
      [data],
      customAttributes,
      generateTemplateDownloadCSVName(
        ENTITY_TYPE.CAMPAIGNS,
        selectedBG?.businessGroupName || "campaigns",
        true
      ),
      ENTITY_TYPE.CAMPAIGNS
    );
  };

  const shouldDisableButtton = () => {
    if (
      selectedBG &&
      Object.keys(data).length != 0 &&
      selectedBG?.businessUnits[data?.businessunitSlug].isAccessReadOnly
    ) {
      return true;
    }

    return false;
  };

  const handleArchive = () => {
    setArchiveModalVisible(true);
  };

  const handleOnClickArchive = async () => {
    await postArchiveCampaign();
  };

  const postArchiveCampaign = async () => {
    const url = `${
      getEnvironmentVariables().API_ENDPOINT
    }/archive/campaigns?bu=${data.businessunitSlug}`;
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        method: "POST",
        body: JSON.stringify({ tacsidList: [data.tacsid] }),
      });

      if (response.ok) {
        addMessage({
          type: "success",
          content: "Campaigns archived successfully!",
          dismissible: true,
        });
        navigate("/campaigns");
      } else {
        returnErrorMessage(response.status).message;
      }
    } catch (error) {
      addMessage({
        type: "error",
        content:
          "Failed to archive campaigns. Please try again later. " + error,
        dismissible: true,
      });
    }
  };

  return (
    <div data-testid="campaign-view">
      {isLoading ? (
        <div>
          <ScreenLoader />
        </div>
      ) : (
        <div data-test-id="campaign-data">
          {hasError ? (
            <></>
          ) : (
            <ContentLayout
              header={
                <Header
                  actions={
                    <div className={`${styles.flex} ${styles.relative}`}>
                      {hasReadOnlyAccess(data.businessunitSlug) && (
                        <p>Read Only Access</p>
                      )}
                      <div className={styles.flex}>
                        <div className={`${styles.archiveWidth}`}>
                          <Button
                            data-testid="archive-button"
                            onClick={() => {
                              handleArchive();
                            }}
                            disabled={
                              shouldDisableButtton() ||
                              isAuthorizedActionForExternalUser(
                                ENTITY_TYPE.CAMPAIGNS,
                                isExternal
                              )
                            }
                            className={styles.headersMR}
                          >
                            Archive
                          </Button>
                        </div>
                        <div className={styles.exportWidth}>
                          <Button
                            className={styles.headersMR}
                            data-testid="onExportButton"
                            onClick={onExport}
                          >
                            Export
                          </Button>
                        </div>
                        <div className={styles.editWidth}>
                          <Button
                            onClick={onEdit}
                            data-testid="onEditButton"
                            disabled={
                              shouldDisableButtton() ||
                              isAuthorizedActionForExternalUser(
                                ENTITY_TYPE.CAMPAIGNS,
                                isExternal
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </div>
                  }
                >
                  {data.campaignname} in {data.businessunitSlug}
                </Header>
              }
            >
              <Container>
                <div className={styles.campaignContainer}>
                  <div
                    className={`${styles.detailHeaderContainer} ${styles.detailHeader}`}
                  >
                    <section
                      className={`${styles.flex} ${styles.flexWrap}`}
                      style={{ padding: "0 10px" }}
                    >
                      <div className={styles.flex}>
                        <h2>TaCS ID</h2>
                        <div className={styles.flex}>
                          <span className={styles.metadataValues}>
                            {data.tacsid}
                          </span>
                          <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            triggerType="custom"
                            content={
                              <StatusIndicator type="success">
                                TaCS ID copied
                              </StatusIndicator>
                            }
                          >
                            <Button
                              className="copy-id"
                              data-testid="copy-id"
                              iconName="copy"
                              variant="inline-icon"
                              onClick={() =>
                                navigator.clipboard.writeText(data.tacsid)
                              }
                            />
                          </Popover>
                        </div>
                      </div>
                      <div className={styles.flex}>
                        <h2>Start date</h2>
                        <span className={styles.metadataValues}>
                          {" "}
                          {formatDate(data?.campaignstartdateplanned)}
                        </span>
                      </div>
                      <div className={styles.flex}>
                        <h2>End date</h2>
                        <span className={styles.metadataValues}>
                          {" "}
                          {formatDate(data?.campaignenddateplanned)}
                        </span>
                      </div>
                      <div className={styles.flexInfo}>
                        <h2 className={styles.headersMR}>Status</h2>
                        <span
                          className={styles.metadataValues}
                          style={{
                            color: `${statusStyleColor}`,
                            fontWeight: "700",
                          }}
                        >
                          {data.campaignsystemstatus == undefined ? (
                            <>Unknown</>
                          ) : (
                            data.campaignsystemstatus
                          )}
                        </span>
                        <div className={styles.info}>
                          <Popover
                            header="Status"
                            content="The stage of the campaign in TaCS."
                            triggerType="custom"
                          >
                            <Button
                              iconName="status-info"
                              variant="inline-icon"
                              data-testid="statusInfoButton"
                            />
                          </Popover>
                        </div>
                      </div>
                    </section>
                  </div>
                  <section>
                    <section
                      className={`${styles.flex} ${styles.createdPadding} ${styles.responsiveMedFont} ${styles.flexWrap}`}
                    >
                      <div className={`${styles.flex}`}>
                        <h3>Created by</h3>
                        <span className={styles.createdValue}>
                          {data?.metadata?.createdBy}
                        </span>
                      </div>
                      <div className={styles.flex}>
                        <h3>Created date</h3>
                        <span className={styles.createdValue}>
                          {formatDate(data?.metadata?.creationDate)}
                        </span>
                      </div>
                      <div className={styles.flex}>
                        <h3>Last updated by</h3>
                        <span className={styles.createdValue}>
                          {data?.metadata?.lastUpdatedBy ||
                            data?.metadata?.createdBy}
                        </span>
                      </div>
                      <div className={styles.flex}>
                        <h3>Last updated on</h3>
                        <span className={styles.createdValue}>
                          {formatDate(
                            data?.metadata?.lastUpdatedDate
                              ? data?.metadata?.lastUpdatedDate
                              : data?.metadata?.creationDate
                          )}
                        </span>
                      </div>
                      <div className={styles.flex}>
                        <h3>Marketplace</h3>
                        <span
                          className={styles.createdValue}
                          data-testid="marketplace-value"
                        >
                          {
                            MARKETPLACE_OPTIONS.find(
                              (option) =>
                                option.value === data.campaignmarketplace
                            )?.label
                          }
                        </span>
                      </div>
                    </section>
                  </section>
                  <section>
                    <h2>Spend Breakdown</h2>

                    <div className={styles.spendHeader}>
                      <span className={styles.budgetClassKey}>
                        Budget Class - <span>{data.budgetclass}</span>
                      </span>
                      <div className={styles.info}>
                        <Popover
                          header="Budget class"
                          content="A 4 character string which represents a unique funding source from the general ledger. Provided by MODE Operations at the time of BU creation."
                          triggerType="custom"
                        >
                          <Button
                            iconName="status-info"
                            variant="inline-icon"
                            data-testid="budgetClassInfoButton"
                          />
                        </Popover>
                      </div>
                    </div>
                    <section>
                      <Tabs
                        tabs={[
                          {
                            label: "Fixed Spend",
                            id: "fixedSpend",
                            content: <FixedSpend />,
                          },
                          {
                            label: "Variable Spend",
                            id: "variableSpend",
                            content: <VariableSpend />,
                          },
                        ]}
                      />
                    </section>
                  </section>
                  <section>
                    <h2>Additional Details</h2>
                    <div className={styles.additionalDetailsPadding}>
                      <ColumnLayout columns={4} variant="text-grid">
                        {customAttributes && (
                          <>
                            {data?.customattributes &&
                            customAttributes?.attributes.length > 0 ? (
                                customAttributes?.attributes.map(
                                  (attribute: any) => {
                                    return (
                                      <SpaceBetween
                                        size="l"
                                        key={attribute?.slug}
                                      >
                                        <div
                                          className={
                                            styles.additionalItemContainer
                                          }
                                        >
                                          <ValueWithLabel
                                            label={attribute?.uidisplay}
                                          >
                                            {
                                              attribute?.values.find(
                                                (value: any) =>
                                                  value?.slug ===
                                                data?.customattributes[
                                                  attribute?.slug
                                                ]
                                              )?.uidisplay
                                            }
                                          </ValueWithLabel>
                                        </div>
                                      </SpaceBetween>
                                    );
                                  }
                                )
                              ) : (
                                <>No custom attributes available</>
                              )}
                          </>
                        )}
                      </ColumnLayout>
                    </div>
                  </section>
                  <section>
                    <div className={styles.flexInfo}>
                      <h2 className={styles.headersMR}>Measurement</h2>
                      <Popover
                        header="Measurements"
                        content="Input to identify the measurement models that will be used for the Campaign. This also sets requirements for third party tags."
                        triggerType="custom"
                      >
                        <Button
                          iconName="status-info"
                          variant="inline-icon"
                          data-testid="measurementsInfoButton"
                        />
                      </Popover>
                    </div>

                    <div className={styles.measurementSection}>
                      {data?.campaignmeasurement ? (
                        data?.campaignmeasurement.map((measurement: any) => {
                          return (
                            <div
                              className={styles.measurementCard}
                              key={measurement}
                            >
                              <span className={styles.measurementHeader}>
                                {getMeasurementLabel(measurement)}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <>No measurements applied</>
                      )}
                    </div>
                  </section>
                </div>
              </Container>
            </ContentLayout>
          )}
        </div>
      )}
      <ArchiveModal
        isVisible={isArchiveModalVisible}
        setVisible={setArchiveModalVisible}
        entities={[
          {
            tacsid: data.tacsid,
            campaignname: data.campaignname,
            accessLevel: data.accessLevel,
          },
        ]}
        handleOnClickArchive={handleOnClickArchive}
        entityType={ENTITY_TYPE.CAMPAIGNS}
      />
    </div>
  );
};

export default CampaignView;
