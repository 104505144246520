import { Storage } from "aws-amplify";
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Box,
  Button,
  ButtonProps,
  CollectionPreferencesProps,
  Header,
  Icon,
  Modal,
  PropertyFilterProps,
  SpaceBetween,
} from "@cloudscape-design/components";
import Tabs from "@cloudscape-design/components/tabs";

import BulkJobCloudscapeTable from "../../components/table/BulkJobCloudscapeTable";
import { AuthContext } from "../../context/AuthContext";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";
import {
  API_CALL_NAME,
  ENTITY_TYPE,
  PAGE_SIZE,
} from "../../data/constants/common";
import { getEnvironmentVariables } from "../../data/constants/environment";
import { DEFAULT_MESSAGE } from "../../data/constants/errorMessages";
import { SelectedBGLocalStorage } from "../../data/constants/localStorage";
import { returnErrorMessage } from "../../data/helpers/returnErrorMessages";
import {
  csvDownload,
  generateTemplateDownloadCSVName,
} from "../../data/helpers/utils";
import { BulkJob } from "../../data/interfaces/IBulk";
import { IPaginated } from "../../data/interfaces/ICampaign";
import { ISettingsMap } from "../../data/interfaces/ITable";
import styles from "./Bulk.module.css";
import {
  FILTERING_PROPERTIES,
  PREFERENCES_VISIBLE_COLUMNS,
  PREFERENCES_VISIBLE_CONTENT,
  RAW_COLUMNS,
  getObjectKeyPath,
} from "./config";

import { allowFeature } from "../../data/helpers/featureGate";

const BulkDashboard: FC<object> = (): ReactElement | null => {
  const {
    getIdToken,
    selectedBG,
    customCampaignAttributes,
    customMediaItemAttributes,
    isExternal,
    userAttributes,
  } = useContext(AuthContext);
  const ref = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>();
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const [message, setMessage] = useState<string>("");
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [activeTabId, setActiveTabId] = React.useState(
    isExternal ? ENTITY_TYPE.FLIGHT_PLANS : ENTITY_TYPE.CAMPAIGNS
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.files && setFile(event.target.files[0]);
    event.target.value = "";
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [items, setItems] = useState<IPaginated>({
    items: [],
    page: {
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0,
    },
  });
  const [searchQuery, setSearchQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: "and",
  });
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences | any
  >({
    pageSize: 10,
    wrapLines: true,
    visibleContent: PREFERENCES_VISIBLE_COLUMNS,
  });

  const getCustomAttributes = () => {
    switch (activeTabId) {
    case ENTITY_TYPE.CAMPAIGNS:
      return customCampaignAttributes;
    case ENTITY_TYPE.MEDIA_ITEMS:
      return customMediaItemAttributes;
    default:
      return null;
    }
  };

  const handleUploadClick = async () => {
    if (!file) return;
    setDisable(true);
    setMessage("Uploading...");
    const bulkJobOrError: BulkJob | string = await createBulkJob();
    typeof bulkJobOrError === "string"
      ? setMessage(bulkJobOrError)
      : await uploadFileToS3Bucket(bulkJobOrError, file);
    setDisable(false);
  };

  const createBulkJob = async (): Promise<BulkJob | string> => {
    const url = `${getEnvironmentVariables().API_ENDPOINT}/bulkjobs`;
    const bodyParams = JSON.stringify({
      bulkJob: {
        originalFileName: file?.name,
        businessGroupSlug: selectedBG?.businessGroupSlug,
        entityType: activeTabId,
      },
    });

    try {
      const bulkJobResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        body: bodyParams,
      });

      return bulkJobResponse.ok
        ? await bulkJobResponse.json()
        : returnErrorMessage(bulkJobResponse.status).message;
    } catch (error) {
      return "Error creating Bulk Job. Please try again";
    }
  };

  const getBulkUploadBucketName = () => {
    switch (activeTabId) {
    case ENTITY_TYPE.CAMPAIGNS:
      return getEnvironmentVariables().S3_BULK_UPLOAD_BUCKET_NAME;
    case ENTITY_TYPE.FLIGHT_PLANS:
      return getEnvironmentVariables()
        .S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME;
    case ENTITY_TYPE.MEDIA_ITEMS:
      return getEnvironmentVariables()
        .S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME;
    case ENTITY_TYPE.MEDIA_ID_REQUEST:
      return getEnvironmentVariables()
        .S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME;
    case ENTITY_TYPE.USERS:
      return getEnvironmentVariables()
        .S3_BULK_UPLOAD_USER_BUCKET_NAME;
    }
  };

  const uploadFileToS3Bucket = async (bulkJobData: BulkJob, file: File) => {
    try {
      const objectKeyPath = getObjectKeyPath(
        activeTabId,
        bulkJobData.bulkJobId,
        selectedBG?.businessGroupSlug?.toLowerCase()
      );

      await Storage.put(objectKeyPath + file.name, file, {
        contentType: "text/csv",
        bucket: getBulkUploadBucketName(),
      });

      setFile(null);
      if (ref && ref.current) ref.current.value = "";
      addMessage({
        type: "success",
        content: "File uploaded successfully!",
        dismissible: true,
      });
      setMessage("");
      setShowCreateModal(false);
    } catch (error) {
      addMessage({
        type: "error",
        content: "Failed to upload file: " + error,
        dismissible: true,
      });
    }
  };

  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    setShowCreateModal(true);
  };

  const SHARED_PROPS = {
    columnDefinitions: RAW_COLUMNS,
    resizableColumns: false,
    resourceName: "Bulk Job",
    resourceNamePlural: "Bulk Jobs",
    sortingColumn: { sortingField: "jobId" },
    sortingDescending: false,
    visibleContentDefault: PREFERENCES_VISIBLE_COLUMNS,
    visibleContentOptions: PREFERENCES_VISIBLE_CONTENT,
    filteringProperties: FILTERING_PROPERTIES,
    handleOnCreate: handleOnCreate,
  };

  const configureQueryParams = (query?: PropertyFilterProps.Query) => {
    let queryParams = "";

    query?.tokens.forEach((token) => {
      if (token.propertyKey !== undefined) {
        queryParams += `&${token.propertyKey}=${token.value}`;
      }
    });

    return queryParams;
  };

  const fetchData = async (query?: PropertyFilterProps.Query) => {
    setIsLoading(true);

    const url =
      `${getEnvironmentVariables().API_ENDPOINT}/${API_CALL_NAME.BULK_JOBS}/?` +
      `entityType=${activeTabId}&currPage=${currentPage}&pageSize=${
        preferences.pageSize
      }${configureQueryParams(query)}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
      });
      const data = await response.json();

      if (!response.ok) {
        setItems({
          items: [],
          page: {
            number: 0,
            numberOfElements: 0,
            size: 0,
            totalElements: 0,
            totalPages: 0,
          },
        });
        const messageError = returnErrorMessage(
          response.status,
          "Unable to fetch bulk jobs",
          SHARED_PROPS.resourceName
        );
        addMessage(
          {
            type: "error",
            content: messageError.message,
            dismissible: true,
          },
          messageError,
          data.message
        );
        return;
      }

      const dataItemsKey = Object.keys(data)[0];
      setItems({ items: data[dataItemsKey], page: data.page });
    } catch (error: any) {
      addMessage(
        {
          header: "Failed to load resources",
          type: "error",
          content: DEFAULT_MESSAGE.message,
          dismissible: true,
        },
        error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const shouldDisableCreateButton = () => {
    // If the user has at least 1BU that they can write to within the selected BG
    // The 'Create' button will not be disabled
    if (selectedBG) {
      for (const key of Object.keys(selectedBG?.businessUnits)) {
        if (selectedBG?.businessUnits[key].isAccessReadOnly == false) {
          return false;
        }
      }
    }

    return true;
  };

  const dismissCreateModal = () => {
    setShowCreateModal(false);
    setFile(null);
  };

  const getBulkTabs = () => {
    let tabs = [
      {
        label: getTabLabel(ENTITY_TYPE.CAMPAIGNS),
        id: ENTITY_TYPE.CAMPAIGNS,
      },
      {
        label: getTabLabel(ENTITY_TYPE.FLIGHT_PLANS),
        id: ENTITY_TYPE.FLIGHT_PLANS,
      },
      {
        label: getTabLabel(ENTITY_TYPE.MEDIA_ITEMS),
        id: ENTITY_TYPE.MEDIA_ITEMS,
      },
      {
        label: getTabLabel(ENTITY_TYPE.MEDIA_ID_REQUEST),
        id: ENTITY_TYPE.MEDIA_ID_REQUEST,
      },
      {
        label: getTabLabel(ENTITY_TYPE.USERS),
        id: ENTITY_TYPE.USERS,
      },
    ];

    if (!allowFeature(selectedBG, true)) {
      tabs = tabs.filter((tab) => tab.id === ENTITY_TYPE.CAMPAIGNS);
    }

    if (isExternal) {
      tabs = tabs.filter((tab) => tab.id !== ENTITY_TYPE.CAMPAIGNS);
    }

    if (!userAttributes?.isAdmin) {
      tabs = tabs.filter((tab) => tab.id !== ENTITY_TYPE.USERS);
    }

    return tabs;
  };

  const getTabLabel = (tabId: string) => {
    switch (tabId) {
    case ENTITY_TYPE.FLIGHT_PLANS:
      return "Flight plans";
    case ENTITY_TYPE.MEDIA_ITEMS:
      return "Media items";
    case ENTITY_TYPE.MEDIA_ID_REQUEST:
      return "Media ID request";
    case ENTITY_TYPE.USERS:
      return "Users";
    case ENTITY_TYPE.CAMPAIGNS:
    default:
      return "Campaigns";
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTabId]);

  useEffect(() => {
    const tableSettings: string | null = window.localStorage.getItem(
      `${SHARED_PROPS.resourceName}Table`
    );
    const parsedSettings: ISettingsMap = tableSettings
      ? JSON.parse(tableSettings)
      : {};
    const currBusinessGroupData: any = localStorage.getItem(
      SelectedBGLocalStorage.SELECTED_BG_NAME
    );
    const parsedBGData = currBusinessGroupData
      ? JSON.parse(currBusinessGroupData)
      : "";
    const currBusinessGroup = parsedBGData
      ? parsedBGData?.businessGroupSlug
      : null;

    let preferencesData = {
      pageSize: PAGE_SIZE,
      wraplines: true,
      visibleContent: PREFERENCES_VISIBLE_COLUMNS,
    };
    if (parsedSettings[currBusinessGroup])
      preferencesData = parsedSettings[currBusinessGroup];

    setPreferences(preferencesData);

    if (selectedBG?.businessUnits != null) {
      fetchData(searchQuery);
    }
  }, [preferences.pageSize, currentPage, searchQuery, selectedBG, activeTabId]);

  return (
    <div className={styles.relative}>
      <div data-testid="bulk-jobs-dashboard">
        <BulkJobCloudscapeTable
          tabs={
            <Tabs
              disableContentPaddings
              onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
              activeTabId={activeTabId}
              tabs={getBulkTabs()}
            />
          }
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    ariaLabel="refresh"
                    iconName="refresh"
                    onClick={() => fetchData(searchQuery)}
                    data-testid="awsui-refresh"
                  />

                  <Button
                    data-testid="awsui-button-primary"
                    disabled={shouldDisableCreateButton()}
                    onClick={handleOnCreate}
                    variant="primary"
                  >
                    Create {getTabLabel(activeTabId).toLowerCase()} job
                  </Button>
                </SpaceBetween>
              }
            >
              Manage bulk jobs
            </Header>
          }
          {...SHARED_PROPS}
          currentPage={currentPage}
          isLoading={isLoading}
          items={items}
          preferences={preferences}
          searchQuery={searchQuery}
          setCurrentPage={setCurrentPage}
          setPreferences={setPreferences}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <div data-testid="bulk-jobs-create" className={styles.modal}>
        <Modal
          onDismiss={() => dismissCreateModal()}
          visible={showCreateModal}
          closeAriaLabel="Close modal"
          data-testid="bulk-create-modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  data-testid="cancel-modal"
                  onClick={() => dismissCreateModal()}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUploadClick}
                  data-testid="upload-submit"
                  disabled={disable}
                >
                  Upload
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={`Bulk create ${getTabLabel(activeTabId).toLocaleLowerCase()}`}
        >
          <div>
            <div className={styles.uploadBox}>
              <Button
                onClick={() =>
                  csvDownload(
                    [],
                    getCustomAttributes(),
                    generateTemplateDownloadCSVName(
                      activeTabId,
                      selectedBG?.businessGroupName || "",
                      true
                    ),
                    activeTabId
                  )
                }
                variant="link"
                data-testid="download-csv-template"
              >
                <Icon name="download" />
                {` Download ${generateTemplateDownloadCSVName(
                  activeTabId,
                  selectedBG?.businessGroupName || "",
                  true
                )}`}
                .csv template
              </Button>
              <input
                type="file"
                ref={ref}
                className={styles.inputButton}
                onChange={handleFileChange}
                accept=".csv"
                id="upload-button"
                hidden
              />
              <label
                className={styles.customFileUpload}
                htmlFor="upload-button"
                data-testid="input-field"
              >
                Select file
              </label>
            </div>
            <div>
              {file ? (
                <div className={styles.fileInfoBox}>
                  <div className={styles.fileIcon}>
                    <Icon name="file-open" size="large" />
                  </div>
                  <div>
                    <p className={styles.fileName}>
                      File:{" "}
                      {file.name.length > 26
                        ? `${file.name.slice(0, 9)}...${file.name.slice(
                          -7,
                          file.name.length
                        )}`
                        : file.name}
                    </p>
                  </div>
                  <div className={styles.fileDetails}>
                    <p>Filetype: {file.type}</p>
                    <p>Size: {file.size} bytes</p>
                  </div>
                </div>
              ) : (
                <div className={styles.fileInfoBox}>No file selected.</div>
              )}
              {message ? <p>{message}</p> : null}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default BulkDashboard;
