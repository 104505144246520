import { Flashbar } from "@cloudscape-design/components";
import React, {FC, ReactElement, useContext} from "react";
import { MessageContext, MessagesContextType } from "../../context/MessagingContext";

const FlashBarMessages:FC<object> = (): ReactElement | null => {
  const {messages} = useContext(MessageContext) as MessagesContextType;
  return (
    <div data-testid="flashbarMessages">
      <Flashbar 
        items={messages}
        data-testid="flashbar"
        stackItems
      />
    </div>
  );
};

export default FlashBarMessages;