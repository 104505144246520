// DATE Constants
export const LOCALES_DEFAULT = "default";

export const NUMERIC_FORMAT = "numeric";

export const TWO_DIGIT_FORMAT = "2-digit";

export const UTC = "UTC";

export const CUSTOM_ATTRIBUTE_BULK_PREFIX = "customattributes.";
export const CAMPAIGN_PLANNED_START_DATE = "campaignstartdateplanned";
export const CAMPAIGN_PLANNED_END_DATE = "campaignenddateplanned";
export const FLIGHTPLANS_START_DATE = "startDate";
export const FLIGHTPLANS_END_DATE = "endDate";
export const CAMPAIGN_NAME = "campaignname";
export const FLIGHTPLANS_NAME = "name";
export const MEDIA_ITEM_START_DATE = "startDate";
export const MEDIA_ITEM_END_DATE = "endDate";

export const DATE_FORMAT = "YYYY-MM-DD";

// Resource Names
export const RESOURCE_CAMPAIGN = "Campaign";
export const RESOURCE_FLIGHT_PLAN = "Flight plan";
export const RESOURCE_BULK = "Bulk jobs";

// Resource Plural Names
export const RESOURCE_CAMPAIGN_PLURAL = "Campaigns";

export const SELECTED_BU = "selectedBU";

// Spends
export const VARIABLE_SPEND_SUFFIX = "variable";
export const FIXED_SPEND_SUFFIX = "fixed";
export const UNALLOCATED_SPEND_SUFFIX = "unallocated";

// Scroll
export const SCROLL_BEHAVIOR = "smooth";
export const SCROLL_BLOCK = "start";

// Refs name
export const REF_BUSINESS_UNIT = "businessUnitRef";
export const REF_BASIC_DETAILS = "basicDetailsRef";
export const REF_CUSTOM_ATTRIBUTES = "customAttributesRef";
export const REF_SPEND = "spendRef";

//API Calls
export const API_CALL_NAME = {
  CAMPAIGNS: "campaigns",
  BULK_JOBS: "bulkjobs",
  FLIGHT_PLANS: "flightplans",
  MEDIA_ITEMS: "mediaitems",
  USERS: "users",
};

//Table Selection Types
export const TABLE_SELECTION_TYPE_SINGLE = "single";
export const TABLE_SELECTION_TYPE_MULTI = "multi";
// Access Levels
export const ACCESS_LEVEL = {
  READ_ONLY: "r",
  READ_WRITE: "rw",
  UNKNOWN: "unk",
};

/**
 * Entity types
 * Refer to Smithy model
 * https://code.amazon.com/packages/MODETaCSMetadataServiceModel/blobs/399597da3109b3db8af415a076e16f6b52ba6680/--/model/resources/common/datatypes.smithy#L219-L227
 */
export const ENTITY_TYPE = {
  CAMPAIGNS: "campaign",
  FLIGHT_PLANS: "flight-plan",
  MEDIA_ITEMS: "media-item",
  //TODO: Correct reference to below as entity types SIM: https://issues.amazon.com/issues/V1494800300
  MEDIA_ID_REQUEST: "media-id-request",
  USERS: "user",
};

export const ADMIN_ENTITY_TYPE = {
  BUSINESS_UNITS: "businessUnits",
  BUSINESS_GROUPS: "businessGroups",
  ATTRIBUTES: "attributes",
};

export const BULK_JOB_STATUS = {
  FAILED: "Failed",
  SYSTEM_ERROR: "System Error",
  SUCCEEDED: "Succeeded",
};

/**
 * The unique identifier of each entity item or business item
 */
export enum ITEM_UNIQUE_ID {
  /**
   * The unique identifier for a campaign.
   */
  CAMPAIGN = "tacsid",

  /**
   * The unique identifier for a flight plan.
   */
  FLIGHT_PLAN = "id",

  /**
   * The unique identifier for a media item.
   */
  MEDIA_ITEM = "id",

  /**
   * The unique identifier for a business group.
   */
  BUSINESS_GROUP = "businessGroupSlug",

  /**
   * The unique identifier for a business unit.
   */
  BUSINESS_UNIT = "businessUnitSlug",

  /**
   * The unique identifier for an attribute.
   */
  ATTRIBUTE = "slug",
}

//Table Preferences
export const PAGE_SIZE = 10;

export const REQUEST_ID_SELECTOR = {
  WITH_TACS_ID: "with-tacs-id",
  WITHOUT_TACS_ID: "without-tacs-id"
};

export const BUSINESS_ROUTE_PATHS = {
  BUSINESS_MANAGEMENT: "/business-management",
  BUSINESS_LOGIC: "/business-logic",
  ATTRIBUTES: "/attributes",
  BUSINESS_GROUPS: "/business-groups",
  BUSINESS_UNITS: "/business-units",
  MEDIA_ITEMS: "/media-items",
  CREATE: "/create",
  EDIT: "/edit",
};

export const REQUEST_METHOD = {
  POST: "POST",
  PUT: "PUT",
  GET: "GET",
  PATCH: "PATCH",
};

export enum PAGE {
  CAMPAIGNS = "Campaigns",
  FLIGHT_PLANS = "Flight Plans",
  MEDIA_ITEMS = "Media Items",
  BULK = "Bulk",
  USERS = "Users",
  BUSINESS_GROUPS = "Business Groups",
  BUSINESS_UNITS = "Business Units",
  BUSINESS_LOGIC = "Business Logic",
}

export const getPageName = (pathName: string): PAGE => {
  switch (pathName) {
  case "/campaigns":
    return PAGE.CAMPAIGNS;
  case "/flight-plans":
    return PAGE.FLIGHT_PLANS;
  case "/media-items":
    return PAGE.MEDIA_ITEMS;
  case "/bulk":
    return PAGE.BULK;
  case "/business-logic":
    return PAGE.BUSINESS_LOGIC;
  case "/business-groups":
    return PAGE.BUSINESS_GROUPS;
  case "/business-units":
    return PAGE.BUSINESS_UNITS;
    //Add additional pages
  default:
    return PAGE.CAMPAIGNS;
  }
};

/**
 * Capitalizes the first character of each word in the provided string.
 *
 * @param {string} str - The input string to be capitalized.
 * @param {boolean} [lower=false] - An optional flag to convert the entire string to lowercase before capitalizing.
 * @returns {string} - The capitalized string.
 */
export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

export const UNAUTHORIZED_WRITE_ACCESS = "Unauthorized write access";
