import React, { FC, ReactElement } from "react";
import { Popover, Button } from "@cloudscape-design/components";
import styles from "./SpendCard.module.css";
import { getLanguageCode } from "../../views/campaign/config";

interface IProps {
  name: string;
  currency: string;
  spendAmt: number;
  infoDescription?: string;
  testid: string;
}

const SpendCard: FC<IProps> = (props: IProps): ReactElement | null => {
  const { name, currency, spendAmt, infoDescription, testid } = props;
  return (
    <div className={styles.spendItemContainer} data-testid={testid}>
      <div className={styles.container}>
        <span className={styles.flex}>
          <h3 className={styles.spendHeader}>{name}</h3>
          <Popover header={name} content={infoDescription} triggerType="custom">
            <Button
              iconName="status-info"
              variant="inline-icon"
              data-testid={name + "InfoButton"}
            />
          </Popover>
        </span>

        <div className={styles.spend}>
          <span className={styles.spendName}>Planned Spend</span>
          <br />
          <span>
            <span>
              {spendAmt ? (
                <>
                  {new Intl.NumberFormat(getLanguageCode(currency || "USD"), {
                    style: "currency",
                    currency: currency || "USD",
                  }).format(spendAmt)}{" "}
                  {currency}
                </>
              ) : (
                " N/A"
              )}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SpendCard;
