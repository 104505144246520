import { DateTime } from "luxon";

// Function that will format dates to YYYY-MM-DD
export const formatDate = (unixTimestamp: string) => {
  if (unixTimestamp === undefined || unixTimestamp === "undefined") return "";
  const date = DateTime.fromSeconds(Number(unixTimestamp), { zone: "UTC" });
  return date.toFormat("yyyy-MM-dd");
};

//Function that will formate to dates to YYYY
export const formatDateWithTime = (unixTimestamp: string) => {
  const date = DateTime.fromSeconds(Number(unixTimestamp), { zone: "UTC" });
  return date.toFormat("yyyy-MM-dd_HHmmss");
};

export const getEpochTime = (date: string) => {
  return Math.floor(new Date(date).getTime()/1000.0);
};

// Maximum date for media items
export const MEDIA_ITEMS_MAXIMUM_DATE = "2090-12-31";

// Minimum date for media items
export const MEDIA_ITEMS_MINIMUM_DATE = "1990-01-01";

/**
 * Calculates the minimum and maximum dates based on the provided parameters.
 *
 * @param {string} currentProperty - The current property being processed. Can be either "startDate" or "endDate".
 * @param {number} [eventStartDate] - The start date of the event, in Unix timestamp format.
 * @param {number} [eventEndDate] - The end date of the event, in Unix timestamp format.
 * @returns {object} An object containing the calculated `maxDate` and `minDate`.
 */
export const getMinAndMaxDates = (currentProperty: string, eventStartDate?: number, eventEndDate?: number) => {

  let maxDate = MEDIA_ITEMS_MAXIMUM_DATE;
  let minDate = MEDIA_ITEMS_MINIMUM_DATE;

  if (currentProperty === "startDate" && !!eventEndDate) {
    maxDate = formatDate(String(eventEndDate - 86400));
    minDate = MEDIA_ITEMS_MINIMUM_DATE;
  } else {
    maxDate = MEDIA_ITEMS_MAXIMUM_DATE;
    minDate = eventStartDate ? formatDate(String(eventStartDate)) : MEDIA_ITEMS_MINIMUM_DATE;
  }

  return { maxDate, minDate };
};
