import React, { FC, ReactElement } from "react";

import { FormField, Input, Popover, Button } from "@cloudscape-design/components";
import { ITextInputProps } from "../../../data/interfaces/IFormInputs";
import styles from "./Inputs.module.css";

const TextInput: FC<ITextInputProps> = (props: ITextInputProps): ReactElement | null => {
  const {
    label,
    description,
    error,
    setErrors,
    placeholder,
    isRequired,
    isDisabled,
    name,
    onInputChange,
    onBlurEvent,
    inputValue,
    testid,
    infoDescription,
  } = props;

  const errorText = error[name] != undefined ? error[name]["message"] : "";

  return (
    <div className={styles.itemcol4}>
      <FormField
        label={
          <>
            {label} -{" "}
            <small>
              <i>
                {isRequired ? (
                  <span className={styles.required}>required</span>
                ) : (
                  <span className={styles.optional}>optional</span>
                )}
              </i>
            </small>
          </>
        }
        description={description}
        info={
          <Popover header={label} content={infoDescription} triggerType="custom">
            <Button iconName="status-info" variant="inline-icon" data-testid={label + "InfoButton"}/>
          </Popover>
        }
        i18nStrings={{ errorIconAriaLabel: "Error" }}
        errorText={errorText}
      >
        <Input
          value={inputValue}
          ariaRequired={true}
          placeholder={placeholder}
          onChange={(event) => {
            onInputChange(event.detail.value, name);

            const updatedErrors = error;
            delete updatedErrors[name];
            setErrors(updatedErrors);
          }}
          onBlur={onBlurEvent}
          name={name}
          disabled={isDisabled}
          data-testid={testid}
        />
      </FormField>
    </div>
  );
};

export default TextInput;
