import React, { FC, ReactElement } from "react";
import AppRoutes from "../../data/constants/routes";
import styles from "./ViewPort.module.css";

const ViewPort: FC<object> = (): ReactElement | null => {
  return (
    <div className={styles.viewport} data-testid="view-port">
      <AppRoutes />
    </div>
  );
};

export default ViewPort;