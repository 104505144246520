import React from "react";
import {
  SelectProps,
  PropertyFilterProps,
  TableProps,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";
import { Link } from "react-router-dom";
import {
  ICampaign,
  ICurrencyLanguageMapping,
} from "../../data/interfaces/ICampaign";
import {
  validateStringBetween,
  validateSelect,
  validateDate,
  validateNumber,
  validateDateStart,
} from "../../data/helpers/validators";
import {
  REF_BUSINESS_UNIT,
  REF_BASIC_DETAILS,
  REF_CUSTOM_ATTRIBUTES,
  REF_SPEND,
} from "../../data/constants/common";
import { formatDate } from "../../data/helpers/dates";
import { DateForm } from "../../components/table/DateForm";

export const COLUMN_ID = {
  BUDGET_CLASS: "budgetclass",
  BUSINESS_GROUP_SLUG: "businessgroupslug",
  BUSINESS_GROUP: "businessgroup",
  BUSINESS_UNIT_SLUG: "businessunitSlug",
  BUSINESS_UNIT: "businessUnitName",
  CAMPAIGN_MARKETPLACE: "campaignmarketplace",
  CAMPAIGN_NAME: "campaignname",
  CAMPAIGN_SYSTEM_STATUS: "campaignsystemstatus",
  CAMPAIGN_TYPE: "campaigntype",
  CREATED_BY: "createdBy",
  CREATION_DATE: "creationDate",
  CURRENCY: "currency",
  END_DATE: "campaignenddateplanned",
  FIXED_PLANNED_SPEND: "fixedplannedspend",
  LAST_UPDATED_BY: "lastUpdatedBy",
  LAST_UPDATED_DATE: "lastUpdatedDate",
  START_DATE: "campaignstartdateplanned",
  TACSID: "tacsid",
  VARIABLE_PLANNED_SPEND: "variableplannedspend",
};

export const RAW_COLUMNS: TableProps.ColumnDefinition<ICampaign>[] = [
  {
    id: COLUMN_ID.TACSID,
    header: "TaCS ID",
    cell: (e: ICampaign) => (
      <Link to={`/campaigns/${e.businessunitSlug}/${e.tacsid}`}>
        {e.tacsid}
      </Link>
    ),
    sortingField: COLUMN_ID.TACSID,
  },
  {
    id: COLUMN_ID.CAMPAIGN_NAME,
    header: "Campaign name",
    cell: (e: ICampaign) => e.campaignname,
    sortingField: COLUMN_ID.CAMPAIGN_NAME,
    minWidth: "180px",
  },
  {
    id: COLUMN_ID.FIXED_PLANNED_SPEND,
    header: "Fixed planned spend",
    cell: (e: ICampaign) =>
      `${new Intl.NumberFormat(getLanguageCode(e.currency), {
        style: "currency",
        currency: e.currency,
      }).format(e.spendTotals?.fixedSpend)} ${e.currency}`,
    sortingField: COLUMN_ID.FIXED_PLANNED_SPEND,
    minWidth: "165px",
  },
  {
    id: COLUMN_ID.VARIABLE_PLANNED_SPEND,
    header: "Variable planned spend",
    cell: (e: ICampaign) =>
      `${new Intl.NumberFormat(getLanguageCode(e.currency), {
        style: "currency",
        currency: e.currency,
      }).format(e.spendTotals?.variableSpend)} ${e.currency}`,
    sortingField: COLUMN_ID.VARIABLE_PLANNED_SPEND,
    minWidth: "165px",
  },
  {
    id: COLUMN_ID.START_DATE,
    header: "Start date",
    cell: (e: ICampaign) => formatDate(e.campaignstartdateplanned),
    sortingField: COLUMN_ID.START_DATE,
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.END_DATE,
    header: "End date",
    cell: (e: ICampaign) => formatDate(e.campaignenddateplanned),
    sortingField: COLUMN_ID.END_DATE,
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.CREATION_DATE,
    header: "Creation date",
    cell: (e: ICampaign) => formatDate(e.metadata?.creationDate),
    sortingField: COLUMN_ID.CREATION_DATE,
    minWidth: "155px",
  },
  {
    id: COLUMN_ID.CREATED_BY,
    header: "Created by",
    cell: (e: ICampaign) => e.metadata.createdBy,
    sortingField: COLUMN_ID.CREATED_BY,
    minWidth: "135px",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_DATE,
    header: "Last updated date",
    cell: (e: ICampaign) =>
      e.metadata?.lastUpdatedDate
        ? formatDate(e.metadata?.lastUpdatedDate)
        : formatDate(e.metadata?.creationDate),
    sortingField: COLUMN_ID.LAST_UPDATED_DATE,
    minWidth: "175px",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_BY,
    header: "Last updated by",
    cell: (e: ICampaign) => e.metadata.lastUpdatedBy || e.metadata.createdBy,
    sortingField: COLUMN_ID.LAST_UPDATED_BY,
    minWidth: "175px",
  },
  {
    id: COLUMN_ID.BUSINESS_UNIT,
    header: "Business unit",
    cell: (e: ICampaign) => e.businessUnitName,
    sortingField: COLUMN_ID.BUSINESS_UNIT,
    minWidth: "160px",
  },
  {
    id: COLUMN_ID.CAMPAIGN_SYSTEM_STATUS,
    header: "Status",
    cell: (e: ICampaign) => e.campaignsystemstatus,
    sortingField: COLUMN_ID.CAMPAIGN_SYSTEM_STATUS,
    minWidth: "160px",
  },
  {
    id: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    header: "Marketplace",
    cell: (e: ICampaign) =>
      MARKETPLACE_OPTIONS.find(
        (option) => option.value === e.campaignmarketplace
      )?.label,
    sortingField: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    minWidth: "160px",
  },
  {
    id: COLUMN_ID.BUDGET_CLASS,
    header: "Budget class",
    cell: (e: ICampaign) => e.budgetclass,
    sortingField: COLUMN_ID.BUDGET_CLASS,
    minWidth: "160px",
  },
];

export const TABLE_VISIBLE_COLUMNS = [
  COLUMN_ID.TACSID,
  COLUMN_ID.CAMPAIGN_NAME,
  COLUMN_ID.FIXED_PLANNED_SPEND,
  COLUMN_ID.VARIABLE_PLANNED_SPEND,
  COLUMN_ID.START_DATE,
  COLUMN_ID.END_DATE,
  COLUMN_ID.LAST_UPDATED_DATE,
  COLUMN_ID.CREATION_DATE,
  COLUMN_ID.CREATED_BY,
  COLUMN_ID.LAST_UPDATED_BY,
  COLUMN_ID.BUSINESS_UNIT,
  COLUMN_ID.CAMPAIGN_SYSTEM_STATUS,
  COLUMN_ID.CAMPAIGN_MARKETPLACE,
  COLUMN_ID.BUDGET_CLASS,
  "bigrock",
];

export const PREFERENCES_VISIBLE_COLUMNS = [
  COLUMN_ID.TACSID,
  COLUMN_ID.CAMPAIGN_NAME,
  COLUMN_ID.CREATED_BY,
  COLUMN_ID.BUSINESS_UNIT,
  COLUMN_ID.CAMPAIGN_SYSTEM_STATUS,
  COLUMN_ID.CAMPAIGN_MARKETPLACE,
  COLUMN_ID.BUDGET_CLASS,
];

export const StatusColors = {
  Ready: "#059D1E",
  Created: "#E07941",
  Ended: "#0073BB",
  Unknown: "#414D5C",
} as const;

export const PREFERENCES_VISIBLE_CONTENT: CollectionPreferencesProps.VisibleContentOption[] = [
  {
    id: COLUMN_ID.TACSID,
    label: "TaCS ID",
    editable: false,
  },
  {
    id: COLUMN_ID.CAMPAIGN_NAME,
    label: "Campaign name",
    editable: false,
  },
  {
    id: COLUMN_ID.FIXED_PLANNED_SPEND,
    label: "Fixed planned spend",
  },
  {
    id: COLUMN_ID.VARIABLE_PLANNED_SPEND,
    label: "Variable planned spend",
  },
  {
    id: COLUMN_ID.START_DATE,
    label: "Start date",
  },
  {
    id: COLUMN_ID.END_DATE,
    label: "End date",
  },
  {
    id: COLUMN_ID.CREATION_DATE,
    label: "Created on",
  },
  {
    id: COLUMN_ID.CREATED_BY,
    label: "Created by",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_DATE,
    label: "Last updated date",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_BY,
    label: "Last updated by",
  },
  {
    id: COLUMN_ID.BUSINESS_UNIT,
    label: "Business unit",
  },
  {
    id: COLUMN_ID.CAMPAIGN_SYSTEM_STATUS,
    label: "Status",
  },
  {
    id: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    label: "Marketplace",
  },
  {
    id: COLUMN_ID.BUDGET_CLASS,
    label: "Budget class",
  },
];

export const CURRENCY_OPTIONS: Array<SelectProps.Option> = [
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
  { label: "CAD", value: "CAD" },
  { label: "AED", value: "AED" },
  { label: "ARS", value: "ARS" },
  { label: "AUD", value: "AUD" },
  { label: "BRL", value: "BRL" },
  { label: "CHF", value: "CHF" },
  { label: "CLP", value: "CLP" },
  { label: "COP", value: "COP" },
  { label: "CZK", value: "CZK" },
  { label: "DKK", value: "DKK" },
  { label: "EGP", value: "EGP" },
  { label: "HUF", value: "HUF" },
  { label: "IDR", value: "IDR" },
  { label: "INR", value: "INR" },
  { label: "JPY", value: "JPY" },
  { label: "MXN", value: "MXN" },
  { label: "MYR", value: "MYR" },
  { label: "NGN", value: "NGN" },
  { label: "NOK", value: "NOK" },
  { label: "NZD", value: "NZD" },
  { label: "PHP", value: "PHP" },
  { label: "PLN", value: "PLN" },
  { label: "RON", value: "RON" },
  { label: "SAR", value: "SAR" },
  { label: "SEK", value: "SEK" },
  { label: "TRY", value: "TRY" },
];

const CURRENCY_TO_LANGUAGE: ICurrencyLanguageMapping = {
  USD: "en-US",
  GBP: "en-GB",
  EUR: "en-GB",
  CAD: "en-CA",
  AED: "ar-AE",
  ARS: "es-AR",
  AUD: "en-AU",
  BRL: "pt-BR",
  CHF: "de-CH",
  CLP: "es-CL",
  COP: "es-CO",
  CZK: "cs-CZ",
  DKK: "da-DK",
  EGP: "ar-EH",
  HUF: "hu-HU",
  IDR: "id-ID",
  INR: "en-IN",
  JPY: "ja-JP",
  MXN: "es-MX",
  MYR: "ms-MY",
  NGN: "en-NG",
  NOK: "nb-NO",
  NZD: "en-NZ",
  PHP: "en-PH",
  PLN: "pl-PL",
  RON: "ro-RO",
  SAR: "ar-TN",
  SEK: "sv-SE",
  TRY: "tr-TR",
};

export const getLanguageCode = (currencyCode: string): string => {
  return CURRENCY_TO_LANGUAGE[currencyCode] || "en-US";
};

export const MARKETPLACE_OPTIONS: Array<SelectProps.Option> = [
  { label: "Argentina (AR)", value: "AR" },
  { label: "Australia (AU)", value: "AU" },
  { label: "Austria (AT)", value: "AT" },
  { label: "Belgium (BE)", value: "BE" },
  { label: "Brazil (BR)", value: "BR" },
  { label: "Canada (CA)", value: "CA" },
  { label: "Chile (CL)", value: "CL" },
  { label: "Colombia (CO)", value: "CO" },
  { label: "Denmark (DK)", value: "DK" },
  { label: "Egypt (EG)", value: "EG" },
  { label: "Emerging LatAm Hub (EL)", value: "EL" },
  { label: "Finland (FI)", value: "FI" },
  { label: "France (FR)", value: "FR" },
  { label: "Germany (DE)", value: "DE" },
  { label: "United Kingdom (GB)", value: "GB" },
  { label: "India (IN)", value: "IN" },
  { label: "Indonesia (ID)", value: "ID" },
  { label: "Ireland (IE)", value: "IE" },
  { label: "Italy (IT)", value: "IT" },
  { label: "Japan (JP)", value: "JP" },
  { label: "Kingdom of Saudi Arabia (SA)", value: "SA" },
  { label: "Malaysia (MY)", value: "MY" },
  { label: "Mexico (MX)", value: "MX" },
  { label: "Netherlands (NL)", value: "NL" },
  { label: "New Zealand (NZ)", value: "NZ" },
  { label: "Nigeria (NG)", value: "NG" },
  { label: "Norway (NO)", value: "NO" },
  { label: "Philippines (PH)", value: "PH" },
  { label: "Poland (PL)", value: "PL" },
  { label: "Portugal (PT)", value: "PT" },
  { label: "Singapore (SG)", value: "SG" },
  { label: "South Africa (ZA)", value: "ZA" },
  { label: "Spain (ES)", value: "ES" },
  { label: "Sweden (SE)", value: "SE" },
  { label: "Thailand (TH)", value: "TH" },
  { label: "Turkey (TR)", value: "TR" },
  { label: "United Arab Emirates (AE)", value: "AE" },
  { label: "United States of America (US)", value: "US" },
  { label: "Multiple Marketplaces (WW)", value: "WW" },
];

export const CAMPAIGN_MEASUREMENT_MODELS: Array<SelectProps.Option> = [
  {
    value: "ace",
    label: "ACE",
  },
  {
    value: "holi",
    label: "HoLI",
  },
  {
    value: "kantar",
    label: "Kantar",
  },
  {
    value: "syrt",
    label: "SyRT",
  },
  {
    value: "percepto",
    label: "Percepto",
  },
  {
    value: "ipsos",
    label: "Ipsos",
  },
  {
    value: "wildebeest",
    label: "Wildebeest",
  },
  {
    value: "forecasto",
    label: "Forecasto",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const validateForm = (
  values: Record<string, any>,
  selectedCustomAttributes: Record<string, any>,
  bgCustomAttributes: Record<string, any>,
  selectedBU: any
) => {
  const errors: any = {};

  const {
    campaignname,
    campaignmarketplace,
    campaignstartdateplanned,
    campaignenddateplanned,
    campaignunallocatedfixed,
    campaigninfluencersfixed,
    campaigncinemafixed,
    campaigndigitalfixed,
    campaignsocialfixed,
    campaignoohfixed,
    campaignprintfixed,
    campaignradiofixed,
    campaigntvfixed,
    campaignotherfixed,
    campaignunallocatedvariable,
    campaigndigitalvariable,
    campaigntvvariable,
    campaignsocialvariable,
    campaignassociatesvariable,
    campaignmailvariable,
    campaignmobilevariable,
    campaignsearchvariable,
  } = values;

  let hasPlannedSpends = false;
  const spends = [
    campaignunallocatedfixed,
    campaigninfluencersfixed,
    campaigncinemafixed,
    campaigndigitalfixed,
    campaignsocialfixed,
    campaignoohfixed,
    campaignprintfixed,
    campaignradiofixed,
    campaigntvfixed,
    campaignotherfixed,
    campaignunallocatedvariable,
    campaigndigitalvariable,
    campaigntvvariable,
    campaignsocialvariable,
    campaignassociatesvariable,
    campaignmailvariable,
    campaignmobilevariable,
    campaignsearchvariable,
  ];

  spends.forEach((spend) => {
    if (spend > 0) hasPlannedSpends = true;
    return;
  });

  if (!validateSelect(selectedBU))
    errors["selectedBU"] = {
      message: "Business Unit is required.",
      name: "selectedBU",
      type: "required",
      section: REF_BUSINESS_UNIT,
    };

  if (!validateStringBetween(campaignname, 1, 50))
    errors["campaignname"] = {
      message: "Campaign name is required and must be less than 50 characters.",
      name: "campaignname",
      type: "required",
      section: REF_BASIC_DETAILS,
    };

  if (!validateSelect(campaignmarketplace))
    errors["campaignmarketplace"] = {
      message: "Marketplace is required.",
      name: "campaignmarketplace",
      type: "required",
      section: REF_BASIC_DETAILS,
    };

  if (
    !validateDate(campaignstartdateplanned) ||
    !validateDateStart(campaignstartdateplanned)
  )
    errors["campaignstartdateplanned"] = {
      message: "Start date after 2021/01/01 is required.",
      name: "campaignstartdateplanned",
      type: "required",
      section: REF_BASIC_DETAILS,
    };

  if (!validateDate(campaignenddateplanned))
    errors["campaignenddateplanned"] = {
      message: "End date is required.",
      name: "campaignenddateplanned",
      type: "required",
      section: REF_BASIC_DETAILS,
    };

  if (
    !("campaignstartdateplanned" in errors) &&
    !("campaignenddateplanned" in errors)
  ) {
    if (campaignstartdateplanned > campaignenddateplanned) {
      errors["campaignstartdateplanned"] = {
        message: "Start date must be before end date.",
        name: "campaignstartdateplanned",
        type: "required",
        section: REF_BASIC_DETAILS,
      };

      errors["campaignenddateplanned"] = {
        message: "End date must be after start date.",
        name: "campaignenddateplanned",
        type: "required",
        section: REF_BASIC_DETAILS,
      };
    }
  }

  bgCustomAttributes?.attributes.forEach((bgCustomAttribute: any) => {
    if (
      !Object.prototype.hasOwnProperty.call(
        selectedCustomAttributes,
        bgCustomAttribute.slug
      )
    ) {
      errors[bgCustomAttribute.slug] = {
        message: `${bgCustomAttribute.slug} is required.`,
        name: bgCustomAttribute.slug,
        type: "required",
        section: REF_CUSTOM_ATTRIBUTES,
      };
    }
  });

  if (hasPlannedSpends == false)
    errors["campaignunallocatedfixed"] = {
      message: "Unallocated Fixed Planned Spend must be a positive number.",
      name: "campaignunallocatedfixed",
      type: "required",
      section: REF_SPEND,
    };

  if (campaignunallocatedfixed != undefined && campaignunallocatedfixed != "") {
    if (
      !validateNumber(campaignunallocatedfixed) ||
      campaignunallocatedfixed < 0
    )
      errors["campaignunallocatedfixed"] = {
        message: "Unallocated Fixed Planned Spend must be a positive number.",
        name: "campaignunallocatedfixed",
        type: "required",
        section: REF_SPEND,
      };
  }

  if (campaigninfluencersfixed != undefined && campaigninfluencersfixed != "") {
    if (
      !validateNumber(campaigninfluencersfixed) ||
      campaigninfluencersfixed < 0
    )
      errors["campaigninfluencersfixed"] = {
        message: "Influencers Fixed Planned Spend must be a positive number.",
        name: "campaigninfluencersfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaigncinemafixed != undefined && campaigncinemafixed != "") {
    if (!validateNumber(campaigncinemafixed) || campaigncinemafixed < 0)
      errors["campaigncinemafixed"] = {
        message: "Cinema Fixed Planned Spend must be a positive number.",
        name: "campaigncinemafixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaigndigitalfixed != undefined && campaigndigitalfixed != "") {
    if (!validateNumber(campaigndigitalfixed) || campaigndigitalfixed < 0)
      errors["campaigndigitalfixed"] = {
        message: "Digital Fixed Planned Spend must be a positive number.",
        name: "campaigndigitalfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignsocialfixed != undefined && campaignsocialfixed != "") {
    if (!validateNumber(campaignsocialfixed) || campaignsocialfixed < 0)
      errors["campaignsocialfixed"] = {
        message: "Social Fixed Planned Spend must be a positive number.",
        name: "campaignsocialfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignoohfixed != undefined && campaignoohfixed != "") {
    if (!validateNumber(campaignoohfixed) || campaignoohfixed < 0)
      errors["campaignoohfixed"] = {
        message: "OOH Fixed Planned Spend must be a positive number.",
        name: "campaignoohfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignprintfixed != undefined && campaignprintfixed != "") {
    if (!validateNumber(campaignprintfixed) || campaignprintfixed < 0)
      errors["campaignprintfixed"] = {
        message: "Print Fixed Planned Spend must be a positive number.",
        name: "campaignprintfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignradiofixed != undefined && campaignradiofixed != "") {
    if (!validateNumber(campaignradiofixed) || campaignradiofixed < 0)
      errors["campaignradiofixed"] = {
        message: "Radio Fixed Planned Spend must be a positive number.",
        name: "campaignradiofixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaigntvfixed != undefined && campaigntvfixed != "") {
    if (!validateNumber(campaigntvfixed) || campaigntvfixed < 0)
      errors["campaigntvfixed"] = {
        message: "TV Fixed Planned Spend must be a positive number.",
        name: "campaigntvfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignotherfixed != undefined && campaignotherfixed != "") {
    if (!validateNumber(campaignotherfixed) || campaignotherfixed < 0)
      errors["campaignotherfixed"] = {
        message: "Other Fixed Planned Spend must be a positive number.",
        name: "campaignotherfixed",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (
    campaignunallocatedvariable != undefined &&
    campaignunallocatedvariable != ""
  ) {
    if (
      !validateNumber(campaignunallocatedvariable) ||
      campaignunallocatedvariable < 0
    )
      errors["campaignunallocatedvariable"] = {
        message:
          "Unallocated Variable Planned Spend must be a positive number.",
        name: "campaignunallocatedvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaigndigitalvariable != undefined && campaigndigitalvariable != "") {
    if (!validateNumber(campaigndigitalvariable) || campaigndigitalvariable < 0)
      errors["campaigndigitalvariable"] = {
        message: "Digital Variable Planned Spend must be a positive number.",
        name: "campaigndigitalvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaigntvvariable != undefined && campaigntvvariable != "") {
    if (!validateNumber(campaigntvvariable) || campaigntvvariable < 0)
      errors["campaigntvvariable"] = {
        message: "TV Variable Planned Spend must be a positive number.",
        name: "campaigntvvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignsocialvariable != undefined && campaignsocialvariable != "") {
    if (!validateNumber(campaignsocialvariable) || campaignsocialvariable < 0)
      errors["campaignsocialvariable"] = {
        message: "Social Variable Planned Spend must be a positive number.",
        name: "campaignsocialvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (
    campaignassociatesvariable != undefined &&
    campaignassociatesvariable != ""
  ) {
    if (
      !validateNumber(campaignassociatesvariable) ||
      campaignassociatesvariable < 0
    )
      errors["campaignassociatesvariable"] = {
        message: "Associates Variable Planned Spend must be a positive number.",
        name: "campaignassociatesvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignmailvariable != undefined && campaignmailvariable != "") {
    if (!validateNumber(campaignmailvariable) || campaignmailvariable < 0)
      errors["campaignmailvariable"] = {
        message: "Mail Variable Planned Spend must be a positive number.",
        name: "campaignmailvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignmobilevariable != undefined && campaignmobilevariable != "") {
    if (!validateNumber(campaignmobilevariable) || campaignmobilevariable < 0)
      errors["campaignmobilevariable"] = {
        message: "Mobile Variable Planned Spend must be a positive number.",
        name: "campaignmobilevariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  if (campaignsearchvariable != undefined && campaignsearchvariable != "") {
    if (!validateNumber(campaignsearchvariable) || campaignsearchvariable < 0)
      errors["campaignsearchvariable"] = {
        message: "Search Variable Planned Spend must be a positive number.",
        name: "campaignsearchvariable",
        type: "optional",
        section: REF_SPEND,
      };
  }

  return errors;
};

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: COLUMN_ID.TACSID,
    operators: ["="],
    propertyLabel: "TaCS ID",
    groupValuesLabel: "TaCS ID values",
  },
  {
    key: COLUMN_ID.BUSINESS_UNIT,
    operators: ["="],
    propertyLabel: "Business unit",
    groupValuesLabel: "Business unit values",
  },
  {
    key: COLUMN_ID.BUDGET_CLASS,
    operators: ["="],
    propertyLabel: "Budget class",
    groupValuesLabel: "Budget class values",
  },
  {
    key: COLUMN_ID.CREATED_BY,
    operators: ["="],
    propertyLabel: "Created by",
    groupValuesLabel: "Created by values",
  },
  {
    key: COLUMN_ID.CAMPAIGN_SYSTEM_STATUS,
    operators: ["="],
    propertyLabel: "Status",
    groupValuesLabel: "Status values",
  },
  {
    key: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    operators: ["="],
    propertyLabel: "Marketplace",
    groupValuesLabel: "Marketplace values",
  },
  {
    key: COLUMN_ID.CAMPAIGN_NAME,
    operators: ["=", ":"],
    propertyLabel: "Campaign name",
    groupValuesLabel: "Campaign name values",
  },
  {
    key: COLUMN_ID.CURRENCY,
    operators: ["="],
    propertyLabel: "Currency",
    groupValuesLabel: "Currency values",
  },
  {
    key: COLUMN_ID.START_DATE,
    propertyLabel: "Campaign Start Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: COLUMN_ID.END_DATE,
    propertyLabel: "Campaign End Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
];
