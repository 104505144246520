import { Input, Select } from "@cloudscape-design/components";
import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { CellContext, MediaItem } from "../../data/interfaces/IMediaItems";
import { Attribute } from "../admin/Interface";
import { AuthContext } from "../../context/AuthContext";
import { UNAUTHORIZED_WRITE_ACCESS } from "../../data/constants/common";
import { getMinAndMaxDates } from "../../data/helpers/dates";
import { getData } from "../../client/client";

/**
 * Maps an `Attribute` object to an array of objects with `label` and `value` properties.
 *
 * @param attribute {Attribute | null} - The `Attribute` object to be mapped.
 * @returns {Array<{ label: string; value: string; }>} - An array of objects with `label` and `value` properties, where `label` is the `uidisplay` property of the `AttributeValueItem` and `value` is the `slug` property of the `AttributeValueItem`.
 */
export const getSlugUIDisplayMapped = (attribute: Attribute | null): { label: string; value: string }[] => {
  if (!attribute) {
    return [];
  }

  return attribute.values.map(({ uidisplay, slug }) => ({
    label: uidisplay,
    value: slug,
  }));
};

type Props = {
  item: MediaItem;
  option: CellContext;
}

export const SelectNodeContainer: React.FC<Props> = ({
  item,
  option,
}) => {

  const { getIdToken } = useContext(AuthContext);
  const { currentValue, setValue, currentProperty } = option;
  const computedValue = item[currentProperty as keyof MediaItem];
  const value = currentValue ?? computedValue;

  const { isPending: isLoading, error: serverError, data } = useQuery({
    queryKey: [currentProperty],
    queryFn: () => getData(`/attributes/${currentProperty.toLowerCase()}`, getIdToken),
    staleTime: 1000 * 60 * 15,
    refetchOnMount: false,
  });

  if (isLoading) {
    return (<div data-testid="select-node-container-loading-state">
        Loading...
    </div>);
  } else {
    let options: {label: string; value: string;}[] = [];
    if (!serverError) options = getSlugUIDisplayMapped(data.attribute as Attribute);

    return <SelectNode
      data-testid={`select-node-container-${currentProperty}`}
      options={options}
      currentProperty={currentProperty}
      value={value}
      setValue={setValue}
      hasFetchError={serverError ? true : false}
    />;
  }
};

type SelectNodeProps = {
  options: {label: string; value: string;}[];
  currentProperty: string;
  value: unknown;
  setValue: (value: unknown) => void
  hasFetchError: boolean;
}

export const SelectNode: React.FC<SelectNodeProps> = ({
  options,
  currentProperty,
  value,
  setValue,
  hasFetchError,
}) => {

  return (
    <Select
      data-testid={currentProperty}
      statusType={hasFetchError ? "error" : "finished"}
      placeholder="Options"
      autoFocus={true}
      expandToViewport={true}
      selectedOption={
        options.find(
          (option: any) => option.value === value
        ) ?? null
      }
      onChange={event => {
        setValue(
          event.detail.selectedOption.value ??
            value
        );
      }}
      options={options}
      errorText={`Error fetching ${currentProperty.toUpperCase()}`}
    />
  );
};

/**
 * React functional component that renders an editable input field based on the provided `inputType`.
 *
 * @param props {Props} - The component props.
 * @param props.item {MediaItem} - The `MediaItem` object associated with the input field.
 * @param props.option {Object} - An object containing the following properties:
 * @param props.option.currentValue {any} - The current value of the input field.
 * @param props.option.setValue {(value: any) => void} - A function to update the value of the input field.
 * @param props.option.currentProperty {string} - The ID of the column that the input field corresponds to.
 * @param props.option.inputType {string} - The type of input field to render (e.g., "text", "number", "date").
 * @returns {React.ReactNode} - The rendered input field.
 */
export const InputNode: React.FC<Props> = ({
  item,
  option,
}) => {
  const { currentValue, setValue, currentProperty, inputType } = option;
  const computedValue = item[currentProperty as keyof MediaItem];
  const value = currentValue ?? computedValue;

  const { maxDate, minDate } = getMinAndMaxDates(currentProperty, item.startDate, item.endDate);

  let EDIT_NODE: React.ReactNode;
  switch (inputType) {
  case "date":
    EDIT_NODE = <input
      data-testid={currentProperty}
      placeholder="Enter value"
      type="date"
      autoFocus={true}
      min={minDate}
      max={maxDate}
      onChange={({ target }) => setValue(new Date(String(target.value)))}
    />;
    break;
  case "number":
    EDIT_NODE = <Input
      data-testid={currentProperty}
      placeholder="Enter value"
      type="number"
      inputMode="numeric"
      autoFocus={true}
      value={value}
      onChange={({ detail }) => setValue(Number(detail.value))}
    />;
    break;
  case "text":
    EDIT_NODE = <Input
      data-testid={currentProperty}
      placeholder="Enter value"
      type="text"
      inputMode="text"
      autoFocus={true}
      value={value}
      onChange={({ detail }) => setValue(detail.value)}
    />;
    break;
  default:
    EDIT_NODE = <Input
      data-testid={currentProperty}
      placeholder="Enter value"
      type="text"
      inputMode="text"
      autoFocus={true}
      value={value}
      onChange={({ detail }) => setValue(detail.value)}
    />;
    break;
  }

  return EDIT_NODE;
};

/**
 * Factory function that creates a React component for rendering an editable input field inline.
 *
 * @param columnId {string} - The ID of the column that the input field corresponds to.
 * @param inputType {string} - The type of input field to render (e.g., "text", "number", "date"). Defaults to "text".
 * @returns {React.FC<{ item: MediaItem; }>} - A React component that renders an `InputNode` component with the provided props.
 */
/* eslint-disable react/display-name */
export const EditInputNodeInline = (columnId: string, inputType="text") => (
  item: MediaItem,
  { currentValue, setValue }: CellContext,
) => item.accessLevel !== "rw"
  ? <span
    data-testid="editinputnodeinline-unauthorized"
    style={{color: "red"}}
  >{UNAUTHORIZED_WRITE_ACCESS}</span>
  : (
    <InputNode
      item={item}
      option={{currentProperty: columnId, currentValue, setValue, inputType}}
    />
  );

/**
 * Factory function that creates a React component for rendering an editable select field inline.
 *
 * @param columnId {string} - The ID of the column that the select field corresponds to.
 * @returns {React.FC<{ item: MediaItem; }>} - A React component that renders a `SelectNodeContainer` component with the provided props.
 */
export const EditSelectNodeInline = (columnId: string) => (
  item: MediaItem,
  { currentValue, setValue }: CellContext,
) => item.accessLevel !== "rw"
  ? <span
    data-testid="editselectnodeinline-unauthorized"
    style={{color: "red"}}
  >{UNAUTHORIZED_WRITE_ACCESS}</span>
  : (
    <SelectNodeContainer
      item={item}
      option={{ currentProperty: columnId, currentValue, setValue }}
    />
  );
