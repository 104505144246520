import { TableProps } from "@cloudscape-design/components/table";
import {
  Attribute,
  AttributeDataType,
  BusinessGroup,
  BusinessUnit
} from "./Interface";
import { formatDate } from "../../data/helpers/dates";
import { ENTITY_TYPE } from "../../data/constants/common";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { PropertyFilterProps } from "@cloudscape-design/components";
import { DateForm } from "../../components/table/DateForm";

export const ADMIN_COLUMN_IDS = {
  //Business group
  BUSINESS_GROUP_SLUG: "businessGroupSlug",
  BUSINESS_GROUP_NAME: "businessGroupName",
  BUSINESS_GROUP_ORG: "businessGroupOrg",
  ATTRIBUTE_UI_DISPLAY: "attributeUIDisplay",

  //Business unit
  BUSINESS_UNIT_SLUG: "businessUnitSlug",
  BUSINESS_UNIT_NAME: "businessUnitName",
  BUDGET_CLASS: "budgetClass",
  BUSINESS_UNIT_POC: "businessUnitPOC",
  GROUP_RW: "accessGroupsRW",
  GROUP_RO: "accessGroupsRO",

  //Attributes
  ASSIGNMENT: "assignment",
  DATA_TYPE: "datatype",
  ENTITY: "entity",
  DICTIONARY: "dictionary",
  GOVERNED: "governed",
  ATTRIBUTE_SLUG: "slug",
  UIDISPLAY: "uidisplay",
  ACTIVE_ATTRIBUTE: "active",

  //Metadata
  CREATED_BY: "createdBy",
  CREATION_DATE: "creationDate",
  LAST_UPDATED_BY: "lastUpdatedBy",
  LAST_UPDATED_DATE: "lastUpdatedDate"
};

export const PREFERRED_VISIBLE_COLUMNS_BG = [
  ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
  ADMIN_COLUMN_IDS.BUSINESS_GROUP_NAME,
  ADMIN_COLUMN_IDS.BUSINESS_GROUP_ORG,
  ADMIN_COLUMN_IDS.ATTRIBUTE_UI_DISPLAY,
  ADMIN_COLUMN_IDS.CREATED_BY,
  ADMIN_COLUMN_IDS.CREATION_DATE,
  ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
  ADMIN_COLUMN_IDS.LAST_UPDATED_BY
];

export const PREFERRED_VISIBLE_COLUMNS_BU = [
  ADMIN_COLUMN_IDS.BUSINESS_UNIT_SLUG,
  ADMIN_COLUMN_IDS.BUSINESS_UNIT_NAME,
  ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
  ADMIN_COLUMN_IDS.BUDGET_CLASS,
  ADMIN_COLUMN_IDS.BUSINESS_UNIT_POC,
  ADMIN_COLUMN_IDS.CREATED_BY,
  ADMIN_COLUMN_IDS.CREATION_DATE,
  ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
  ADMIN_COLUMN_IDS.LAST_UPDATED_BY
];

export const PREFERRED_VISIBLE_COLUMNS_ATTRIBUTES = [
  ADMIN_COLUMN_IDS.ASSIGNMENT,
  ADMIN_COLUMN_IDS.DATA_TYPE,
  ADMIN_COLUMN_IDS.DICTIONARY,
  ADMIN_COLUMN_IDS.ENTITY,
  ADMIN_COLUMN_IDS.UIDISPLAY,
  ADMIN_COLUMN_IDS.ATTRIBUTE_SLUG,
  ADMIN_COLUMN_IDS.GOVERNED,
  ADMIN_COLUMN_IDS.CREATED_BY,
  ADMIN_COLUMN_IDS.CREATION_DATE,
  ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
  ADMIN_COLUMN_IDS.LAST_UPDATED_BY
];

export const VISIBLE_CONTENT_OPTION_PREFERENCE_BG = [
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_NAME,
    label: "Business Group name",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
    label: "Business Group slug",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_ORG,
    label: "Organization",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATED_BY,
    label: "Created by",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATION_DATE,
    label: "Creation date",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
    label: "Last updated date",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
    label: "Last Updated by",
    editable: true,
  },
];

export const VISIBLE_CONTENT_OPTION_PREFERENCE_BU = [
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_UNIT_NAME,
    label: "Business Unit name",
    editable: false,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_UNIT_SLUG,
    label: "Business Unit slug",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
    label: "Business Group slug",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.BUDGET_CLASS,
    label: "Budget Class",
    editable: false,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_UNIT_POC,
    label: "Business Unit POC",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATED_BY,
    label: "Created by",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATION_DATE,
    label: "Creation date",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
    label: "Last updated date",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
    label: "Last updated by",
    editable: true,
  },
];
export const VISIBLE_CONTENT_OPTION_PREFERENCE_ATTRIBUTES = [
  {
    id: ADMIN_COLUMN_IDS.UIDISPLAY,
    label: "Attribute name",
    editable: false,
  },
  {
    id: ADMIN_COLUMN_IDS.ATTRIBUTE_SLUG,
    label: "Slug",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.ASSIGNMENT,
    label: "Assignment",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.ENTITY,
    label: "Entity",
    editable: false,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATED_BY,
    label: "Created by",
    editable: false,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATION_DATE,
    label: "Creation date",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.GOVERNED,
    label: "Governed",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
    label: "Last updated date",
    editable: true,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
    label: "Last updated by",
    editable: true,
  },
];

export const BUSINESS_GROUPS_TABLE_COLUMNS: TableProps.ColumnDefinition<BusinessGroup>[] = [
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_NAME,
    header: "Business Group name",
    cell: (e: BusinessGroup) => e.businessGroupName,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_GROUP_NAME,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
    header: "Business Group slug",
    cell: (e: BusinessGroup) => e.businessGroupSlug,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_ORG,
    header: "Organization",
    cell: (e: BusinessGroup) => e.businessGroupOrg,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_GROUP_ORG,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATION_DATE,
    header: "Creation date",
    cell: (e: BusinessGroup) => formatDate(String(e.metadata.creationDate)),
    sortingField: ADMIN_COLUMN_IDS.CREATION_DATE,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATED_BY,
    header: "Created by",
    cell: (e: BusinessGroup) => e.metadata.createdBy,
    sortingField: ADMIN_COLUMN_IDS.CREATED_BY,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
    header: "Last updated by",
    cell: (e: BusinessGroup) => e.metadata.lastUpdatedBy || e.metadata.createdBy,
    sortingField: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
    header: "Last updated date",
    cell: (e: BusinessGroup) => formatDate(String(e.metadata.lastUpdatedDate)) || formatDate(String(e.metadata.creationDate)),
    sortingField: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
  },
];

export const BUSINESS_UNITS_TABLE_COLUMNS: TableProps.ColumnDefinition<BusinessUnit>[] = [
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_UNIT_NAME,
    header: "Business Unit name",
    cell: (e: BusinessUnit) => e.businessUnitName,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_UNIT_NAME,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_UNIT_SLUG,
    header: "Business Unit slug",
    cell: (e: BusinessUnit) => e.businessUnitSlug,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_UNIT_SLUG,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
    header: "Business Group slug",
    cell: (e: BusinessUnit) => e.businessGroupSlug,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
  },
  {
    id: ADMIN_COLUMN_IDS.BUDGET_CLASS,
    header: "Budget Class",
    cell: (e: BusinessUnit) => e.budgetClass,
    sortingField: ADMIN_COLUMN_IDS.BUDGET_CLASS,
  },
  {
    id: ADMIN_COLUMN_IDS.BUSINESS_UNIT_POC,
    header: "Business Unit POC",
    cell: (e: BusinessUnit) => e.businessUnitPOC,
    sortingField: ADMIN_COLUMN_IDS.BUSINESS_UNIT_POC,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATION_DATE,
    header: "Creation date",
    cell: (e: BusinessUnit) => formatDate(String(e.metadata.creationDate)),
    sortingField: ADMIN_COLUMN_IDS.CREATION_DATE,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATED_BY,
    header: "Created by",
    cell: (e: BusinessUnit) => e.metadata.createdBy,
    sortingField: ADMIN_COLUMN_IDS.CREATED_BY,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
    header: "Last updated by",
    cell: (e: BusinessUnit) => e.metadata.lastUpdatedBy || e.metadata.createdBy,
    sortingField: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
    header: "Last updated date",
    cell: (e: BusinessUnit) => formatDate(String(e.metadata.lastUpdatedDate)) || formatDate(String(e.metadata.creationDate)),
    sortingField: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
  },
];

export const BUSINESS_ATTRIBUTES_TABLE_COLUMNS: TableProps.ColumnDefinition<Attribute>[] = [
  {
    id: ADMIN_COLUMN_IDS.UIDISPLAY,
    header: "Attribute name",
    cell: (e: Attribute) => e.uidisplay,
    sortingField: ADMIN_COLUMN_IDS.UIDISPLAY,
  },
  {
    id: ADMIN_COLUMN_IDS.ATTRIBUTE_SLUG,
    header: "Slug",
    cell: (e: Attribute) => e.slug,
    sortingField: ADMIN_COLUMN_IDS.ATTRIBUTE_SLUG,
  },
  {
    id: ADMIN_COLUMN_IDS.ASSIGNMENT,
    header: "Assignment",
    cell: (e: Attribute) => e.assignment,
    sortingField: ADMIN_COLUMN_IDS.ASSIGNMENT,
  },
  {
    id: ADMIN_COLUMN_IDS.ENTITY,
    header: "Entity",
    cell: (e: Attribute) => e.entity,
    sortingField: ADMIN_COLUMN_IDS.ENTITY,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATED_BY,
    header: "Created by",
    cell: (e: Attribute) => e.metadata.createdBy,
    sortingField: ADMIN_COLUMN_IDS.CREATED_BY,
  },
  {
    id: ADMIN_COLUMN_IDS.CREATION_DATE,
    header: "Creation date",
    cell: (e: Attribute) => formatDate(String(e.metadata.creationDate)),
    sortingField: ADMIN_COLUMN_IDS.CREATION_DATE,
  },
  {
    id: ADMIN_COLUMN_IDS.GOVERNED,
    header: "Governed",
    cell: (e: Attribute) => (e.governed ? "Yes" : "No"),
    sortingField: ADMIN_COLUMN_IDS.GOVERNED,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
    header: "Last updated by",
    cell: (e: Attribute) => e.metadata.lastUpdatedBy || e.metadata.createdBy,
    sortingField: ADMIN_COLUMN_IDS.LAST_UPDATED_BY,
  },
  {
    id: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
    header: "Last updated date",
    cell: (e: Attribute) => formatDate(String(e.metadata.lastUpdatedDate)) || formatDate(String(e.metadata.creationDate)),
    sortingField: ADMIN_COLUMN_IDS.LAST_UPDATED_DATE,
  },
];

export const BUSINESS_RULESETS_TABLE_COLUMNS: TableProps.ColumnDefinition<BusinessUnit>[] = [
  //TODO: Add columns for Rulesets
];

export const entityOptions: OptionDefinition[] = [
  { label: "Campaign", value: ENTITY_TYPE.CAMPAIGNS },
  { label: "Media Item", value: ENTITY_TYPE.MEDIA_ITEMS },
  { label: "Flight Plan", value: ENTITY_TYPE.FLIGHT_PLANS },
];

export const attributeDataTypeOptions: OptionDefinition[] = [
  { label: "SELECT", value: AttributeDataType.SELECT },
  { label: "NUMERIC", value: AttributeDataType.NUMERIC, disabled: true },
  { label: "STRING", value: AttributeDataType.STRING, disabled: true },
  { label: "DATE", value: AttributeDataType.DATE, disabled: true },
];

export const assignments: OptionDefinition[] = [
  { label: "CUSTOM", value: "custom" },
  { label: "UNIVERSAL", value: "universal", disabled: true },
];

export const businessGroupNameSlugMap = (businessGroups: BusinessGroup[]) => {
  return businessGroups?.map(businessGroup => {
    return {
      label: `${businessGroup.businessGroupName}: ${businessGroup.businessGroupSlug}`,
      value: businessGroup.businessGroupSlug,
    };
  });
};

export const BG_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
    operators: ["="],
    propertyLabel: "Business Group Slug",
    groupValuesLabel: "Business Group Slug values",
  },
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_GROUP_NAME,
    operators: ["="],
    propertyLabel: "Business Group Name",
    groupValuesLabel: "Business Group Name values",
  },
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_GROUP_ORG,
    operators: ["="],
    propertyLabel: "Business Group Org",
    groupValuesLabel: "Business Group Org values",
  },
  {
    key: ADMIN_COLUMN_IDS.CREATED_BY,
    operators: ["="],
    propertyLabel: "Created By",
    groupValuesLabel: "Created By values",
  },
  {
    key: ADMIN_COLUMN_IDS.CREATION_DATE,
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
    propertyLabel: "Creation Date",
    groupValuesLabel: "Creation Date values",
    defaultOperator: "=",
  }
];

export const BU_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_UNIT_SLUG,
    operators: ["="],
    propertyLabel: "Business Unit Slug",
    groupValuesLabel: "Business Unit Slug values",
  },
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_UNIT_NAME,
    operators: ["="],
    propertyLabel: "Business Unit Name",
    groupValuesLabel: "Business Unit Name values",
  },
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG,
    operators: ["="],
    propertyLabel: "Business Group Slug",
    groupValuesLabel: "Business Group Slug values",
  },
  {
    key: ADMIN_COLUMN_IDS.BUDGET_CLASS,
    operators: ["="],
    propertyLabel: "Budget Class",
    groupValuesLabel: "Budget Class values",
  },
  {
    key: ADMIN_COLUMN_IDS.BUSINESS_UNIT_POC,
    operators: ["="],
    propertyLabel: "Business Unit POC",
    groupValuesLabel: "Business Unit POC values",
  },
  {
    key: ADMIN_COLUMN_IDS.CREATED_BY,
    operators: ["="],
    propertyLabel: "Created By",
    groupValuesLabel: "Created By values",
  },
  {
    key: ADMIN_COLUMN_IDS.CREATION_DATE,
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
    propertyLabel: "Creation Date",
    groupValuesLabel: "Creation Date values",
    defaultOperator: "=",
  }
];

export const ATTRIBUTE_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: ADMIN_COLUMN_IDS.ASSIGNMENT,
    operators: ["="],
    propertyLabel: "Assignment",
    groupValuesLabel: "Assignment values",
  },
  {
    key: ADMIN_COLUMN_IDS.DATA_TYPE,
    operators: ["="],
    propertyLabel: "Data Type",
    groupValuesLabel: "Data Type values",
  },
  {
    key: ADMIN_COLUMN_IDS.DICTIONARY,
    operators: ["="],
    propertyLabel: "Dictionary",
    groupValuesLabel: "Dictionary values",
  },
  {
    key: ADMIN_COLUMN_IDS.ENTITY,
    operators: ["="],
    propertyLabel: "Entity",
    groupValuesLabel: "Entity values",
  },
  {
    key: ADMIN_COLUMN_IDS.UIDISPLAY,
    operators: ["="],
    propertyLabel: "UI Display",
    groupValuesLabel: "UI Display values",
  },
  {
    key: ADMIN_COLUMN_IDS.ATTRIBUTE_SLUG,
    operators: ["="],
    propertyLabel: "Attribute Slug",
    groupValuesLabel: "Attribute Slug values",
  },
  {
    key: ADMIN_COLUMN_IDS.CREATED_BY,
    operators: ["="],
    propertyLabel: "Created By",
    groupValuesLabel: "Created By values",
  },
  {
    key: ADMIN_COLUMN_IDS.CREATION_DATE,
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
    propertyLabel: "Creation Date",
    groupValuesLabel: "Creation Date values",
    defaultOperator: "=",
  }
];
