import React, { useEffect, useState } from "react";
import Calendar from "@cloudscape-design/components/calendar";
import DateInput from "@cloudscape-design/components/date-input";
import FormField from "@cloudscape-design/components/form-field";

interface DateFormProps {
  filter?: string | undefined;
  operator: string;
  value: string | undefined;
  onChange: (newValue: string | null) => void;
}

export function DateForm({value, onChange }: DateFormProps) {
  const [dateValue, setDateValue] = useState<string>(parseValue(value ?? ""));

  const onChangeDate = (dateValue: string) => {
    setDateValue(dateValue);
  };


  useEffect(() => {
    if (!dateValue.trim()) {
      onChange(null);
    } else if (isValidIsoDate(dateValue)) {
      onChange(dateValue);
    }
  }, [dateValue]);

  return (
    <div className="date-form">
      <FormField description="Date" constraintText="Use YYYY/MM/DD format.">
        <DateInput placeholder="YYYY/MM/DD" value={dateValue} onChange={(event: any) => onChangeDate(event.detail.value)} />
      </FormField>

      <Calendar
        value={dateValue}
        locale="en-EN"
        previousMonthAriaLabel="Previous month"
        nextMonthAriaLabel="Next month"
        todayAriaLabel="Today"
        onChange={(event: any) => onChangeDate(event.detail.value)}
      />
    </div>
  );
}

function isValidIsoDate(isoDate: string): boolean {
  return !isNaN(new Date(isoDate).getTime());
}

function parseValue(value: string): string {
  return value.split("T")[0];
}
