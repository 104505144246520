import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { IBusinessGroup } from "../interfaces/IUser";

/**
 * Checks if all the objects in one array are present in another array of objects.
 *
 * @param {Array<Record<string, any>>} arr1 - The first array of objects to compare.
 * @param {Array<Record<string, any>>} arr2 - The second array of objects to compare.
 * @returns {boolean} - `true` if all the objects in `arr2` are present in `arr1`, `false` otherwise.
 */
export const isArrayBSubsetOfArrayA = (
  arr1: Array<Record<string, any>>,
  arr2: Array<Record<string, any>>
): boolean => {

  if (Array.isArray(arr1) && Array.isArray(arr2) === false) {
    return false;
  }

  // Check if arr2 is empty, in which case nothing of arr2 is in arr1
  if (arr2.length === 0) {
    return false;
  }

  // Create a Set of stringified objects from arr1 for efficient lookup
  const set1 = new Set(arr1.map(obj => JSON.stringify(obj)));

  // Check if every object in arr2 is present in set1
  return arr2.every(obj => set1.has(JSON.stringify(obj)));
};

/**
 * Converts the business units of a given business group into an array of OptionDefinition objects.
 *
 * @param {IBusinessGroup | null} selectedBG - The business group object containing
 *   the business units to be converted to options.
 * @returns {OptionDefinition[]} An array of OptionDefinition objects representing
 *   the business units of the given business group.
 */
export const getBusinessUnitsOptions = (
  selectedBG: IBusinessGroup | null,
): OptionDefinition[] => {
  if (!selectedBG || !selectedBG.businessUnits) {
    return [];
  }

  return Object.values(selectedBG.businessUnits).map((businessUnit) => ({
    label: businessUnit.businessUnitName,
    value: businessUnit.businessUnitSlug,
    disabled: businessUnit.isAccessReadOnly,
  }));
};

/**
 * Checks if all selected items in the provided array has read-write ("rw") access level.
 *
 * @param selectedItems - An array of items to check for access level.
 * @returns `true` if all items has "rw" access level, `false` otherwise.
 */
export const hasWriteAccessOnSelectedItems = (selectedItems: any[]) => {
  if (selectedItems.length === 0) return false;

  return selectedItems.every((item) => item.accessLevel === "rw");
};
