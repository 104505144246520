import React, { ReactElement, useEffect, useState } from "react";

import Pagination from "@cloudscape-design/components/pagination";
import Table from "@cloudscape-design/components/table";
import { ICampaign, IFlightPlan } from "../../data/interfaces/ICampaign";

const pageSize = 5;

export interface ArchiveModalTableProps {
  entities: any[];
  entityType: string;
}

const COLUMN_DEFINITIONS = {
  campaign: [
    {
      id: "campaignname",
      header: "Campaign name",
      cell: (item: ICampaign) => item.campaignname,
    },
    {
      id: "tacsid",
      header: "TaCS ID",
      cell: (item: ICampaign) => item.tacsid,
    },
  ],
  "flight-plan": [
    {
      id: "flightplanname",
      header: "Flight plan name",
      cell: (item: IFlightPlan) => item.name,
    },
    {
      id: "flightplanid",
      header: "Flight plan ID",
      cell: (item: IFlightPlan) => item.id,
    },
  ],
};

const ArchiveModalTable = ({
  entities: entities,
  entityType,
}: ArchiveModalTableProps): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [entities]);

  return (
    <Table
      variant="embedded"
      columnDefinitions={
        COLUMN_DEFINITIONS[entityType as keyof typeof COLUMN_DEFINITIONS]
      }
      items={entities.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
      pagination={
        <Pagination
          currentPageIndex={currentPage}
          pagesCount={Math.ceil(entities.length / pageSize)}
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
          onNextPageClick={() => setCurrentPage(currentPage + 1)}
          onPreviousPageClick={() => setCurrentPage(currentPage - 1)}
          onChange={(detail) => {
            setCurrentPage(detail.detail.currentPageIndex);
          }}
        />
      }
    />
  );
};

export default ArchiveModalTable;
