import { getEnvironmentVariables } from "./environment";

const ENV_VARS = getEnvironmentVariables();

export const S3_BULK_UPLOAD_CONFIG = {
  Storage: {
    AWSS3: {
      bucket: ENV_VARS.S3_BULK_UPLOAD_BUCKET_NAME,
      region: ENV_VARS.S3_BUCKET_REGION,
    }
  },
};