import React, { ReactNode } from "react";
import { HelpPanel, Icon } from "@cloudscape-design/components";
import { v4 as uuidv4 } from "uuid";

import { TaCS_WIKI_URL, TutorialHotspot } from "./constants";

export const footer = (
  <div data-testid="tools-panel-info-tab-footer">
    <ul>
      <li>
        <a href={TaCS_WIKI_URL} rel="noreferrer" target="blank">
          TaCS wiki <Icon name="external" />
        </a>
      </li>
    </ul>
  </div>
);

export const HelpPanelContent = () => {
  return new Map<string, ReactNode>([
    [
      TutorialHotspot.manageCampaign,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              Manage Campaigns
            </h2>
          }
          footer={footer}
        >
          <p>
            You can manage your Campaign on this page
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.createCampaignBtn,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              Create new Campaign
            </h2>
          }
        >
          <p>
            Use the <i>Create Campaign</i> button to create a single campaign with a step by step user interface
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.filterCampaignItems,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              Search Campaigns
            </h2>
          }
        >
          <p>
            Search can help you quickly find a specific Campaign
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.paginationAndDisplay,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              View settings
            </h2>
          }
        >
          <p>
            Choose how you want your Campaigns to be displayed on the table 
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.manageFlightPlan,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              Manage Flight Plans
            </h2>
          }
        >
          <p>
            You can manage your Flight Plan on this page
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.createFlightPlan,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              Create new Flight Plan
            </h2>
          }
        >
          <p>
            Use the <i>Create Flight Plan</i> button to create a single Flight Plan
            with step by step user interface
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.searchFlightPlan,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              Search Flight Plan
            </h2>
          }
        >
          <p>
            Search can help you quickly find a specific Flight Plan
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.viewDisplayOptions,
      <div key={uuidv4()}>
        <HelpPanel
          header={
            <h2>
              View settings
            </h2>
          }
        >
          <p>
            Choose how you want your Flight Plans to be displayed on the table
          </p>
        </HelpPanel>
      </div>
    ],
    [
      TutorialHotspot.defaultContent,
      <div key={45}>
        <HelpPanel
          header={
            <h2>
              Learn more about TaCS
            </h2>
          }
        >
          <p>
            <a href={TaCS_WIKI_URL}>
              Visit our TaCS wiki</a>
            Visit our TaCS wiki to learn more about managing Campaigns
          </p>
        </HelpPanel>
      </div>
    ],
  ]);
};
