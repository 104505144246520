import { ADMIN_ENTITY_TYPE, ENTITY_TYPE } from "../data/constants/common";
import { ADMIN_COLUMN_IDS } from "../views/admin/config";
import { COLUMN_ID as CAMPAIGN_COLUMN_ID } from "../views/campaign/config";
import { COLUMN_ID as FP_COLUMN_ID } from "../views/flight-plans/config";
import { MEDIA_ITEMS_COLUMN_IDS as MI_COLUMN_ID } from "../views/media-items/config";

export const dateConfig = {
  [ENTITY_TYPE.CAMPAIGNS]: [CAMPAIGN_COLUMN_ID.START_DATE, CAMPAIGN_COLUMN_ID.END_DATE, CAMPAIGN_COLUMN_ID.CREATION_DATE, CAMPAIGN_COLUMN_ID.LAST_UPDATED_DATE],
  [ENTITY_TYPE.FLIGHT_PLANS]: [FP_COLUMN_ID.START_DATE, FP_COLUMN_ID.END_DATE, FP_COLUMN_ID.CREATION_DATE, FP_COLUMN_ID.LAST_UPDATED_DATE, FP_COLUMN_ID.CAMPAIGN_START_DATE_PLANNED, FP_COLUMN_ID.CAMPAIGN_END_DATE_PLANNED],
  [ENTITY_TYPE.MEDIA_ITEMS]: [MI_COLUMN_ID.MEDIA_START_DATE, MI_COLUMN_ID.MEDIA_END_DATE, MI_COLUMN_ID.CREATION_DATE, MI_COLUMN_ID.LAST_UPDATED_DATE, MI_COLUMN_ID.CAMPAIGN_START_DATE_PLANNED, MI_COLUMN_ID.CAMPAIGN_END_DATE_PLANNED, MI_COLUMN_ID.FLIGHT_PLAN_START_DATE, MI_COLUMN_ID.FLIGHT_PLAN_END_DATE],
  [ADMIN_ENTITY_TYPE.BUSINESS_GROUPS]: [ADMIN_COLUMN_IDS.CREATION_DATE, ADMIN_COLUMN_IDS.LAST_UPDATED_DATE],
  [ADMIN_ENTITY_TYPE.BUSINESS_UNITS]: [ADMIN_COLUMN_IDS.CREATION_DATE, ADMIN_COLUMN_IDS.LAST_UPDATED_DATE],
  [ADMIN_ENTITY_TYPE.ATTRIBUTES]: [ADMIN_COLUMN_IDS.CREATION_DATE, ADMIN_COLUMN_IDS.LAST_UPDATED_DATE]
};

export const numericFieldConfig = {
  [ENTITY_TYPE.CAMPAIGNS]: [],
  [ENTITY_TYPE.FLIGHT_PLANS]: [],
  [ENTITY_TYPE.MEDIA_ITEMS]: [MI_COLUMN_ID.MEDIA_RATE, MI_COLUMN_ID.MEDIA_PLANNED_BUDGET, MI_COLUMN_ID.MEDIA_PLANNED_UNITS, MI_COLUMN_ID.MEDIA_PLANNED_BID_MIN, MI_COLUMN_ID.MEDIA_CREATIVE_LENGTH],
  [ADMIN_ENTITY_TYPE.BUSINESS_GROUPS]: [],
  [ADMIN_ENTITY_TYPE.BUSINESS_UNITS]: [],
  [ADMIN_ENTITY_TYPE.ATTRIBUTES]: []
};

export const booleanFieldConfig = {
  [ENTITY_TYPE.CAMPAIGNS]: [],
  [ENTITY_TYPE.FLIGHT_PLANS]: [],
  [ENTITY_TYPE.MEDIA_ITEMS]: [],
  [ADMIN_ENTITY_TYPE.BUSINESS_GROUPS]: [],
  [ADMIN_ENTITY_TYPE.BUSINESS_UNITS]: [],
  [ADMIN_ENTITY_TYPE.ATTRIBUTES]: [ADMIN_COLUMN_IDS.GOVERNED]
};

export const freeTextConfig = {
  [ENTITY_TYPE.CAMPAIGNS]: [CAMPAIGN_COLUMN_ID.TACSID, CAMPAIGN_COLUMN_ID.CAMPAIGN_NAME, CAMPAIGN_COLUMN_ID.CAMPAIGN_MARKETPLACE, CAMPAIGN_COLUMN_ID.BUSINESS_UNIT],
  [ENTITY_TYPE.FLIGHT_PLANS]: [FP_COLUMN_ID.ASSOCIATED_TACSID, FP_COLUMN_ID.FLIGHT_PLAN_ID, FP_COLUMN_ID.CAMPAIGN_NAME, FP_COLUMN_ID.CAMPAIGN_MARKETPLACE, FP_COLUMN_ID.BUSINESS_UNIT, FP_COLUMN_ID.FLIGHT_PLAN_NAME, FP_COLUMN_ID.NOTES],
  [ENTITY_TYPE.MEDIA_ITEMS]: [MI_COLUMN_ID.TACS_ID, MI_COLUMN_ID.MEDIA_ITEM_ID, MI_COLUMN_ID.PARENT_MEDIA_ITEM_ID, MI_COLUMN_ID.FLIGHT_PLAN, MI_COLUMN_ID.CAMPAIGN_NAME, MI_COLUMN_ID.CAMPAIGN_MARKETPLACE, MI_COLUMN_ID.BUSINESS_UNIT, MI_COLUMN_ID.FLIGHT_PLAN_NAME, MI_COLUMN_ID.MEDIA_ITEM_NAME, MI_COLUMN_ID.MEDIA_SUFFIX],
  [ADMIN_ENTITY_TYPE.BUSINESS_GROUPS]: [ADMIN_COLUMN_IDS.BUSINESS_GROUP_SLUG, ADMIN_COLUMN_IDS.BUSINESS_GROUP_NAME, ADMIN_COLUMN_IDS.BUSINESS_GROUP_ORG, "customAttributesSlugList"],
  [ADMIN_ENTITY_TYPE.BUSINESS_UNITS]: [ADMIN_COLUMN_IDS.BUSINESS_UNIT_SLUG, ADMIN_COLUMN_IDS.BUSINESS_UNIT_NAME, ADMIN_COLUMN_IDS.BUSINESS_UNIT_POC],
  [ADMIN_ENTITY_TYPE.ATTRIBUTES]: [ADMIN_COLUMN_IDS.ASSIGNMENT, ADMIN_COLUMN_IDS.ENTITY, ADMIN_COLUMN_IDS.ATTRIBUTE_SLUG, ADMIN_COLUMN_IDS.UIDISPLAY, ADMIN_COLUMN_IDS.BUDGET_CLASS, "values.slug", "values.uidisplay"]
};

export const fixedSpendFields = [
  "campaignunallocatedfixed", "campaignsocialfixed", "campaignradiofixed",
  "campaigncinemafixed", "campaigndigitalfixed", "campaigninfluencersfixed",
  "campaignoohfixed", "campaignotherfixed", "campaignprintfixed", "campaigntvfixed"
];

export const variableSpendFields = [
  "campaignassociatesvariable", "campaigndigitalvariable", "campaignmailvariable",
  "campaignmobilevariable", "campaignsearchvariable", "campaignsocialvariable",
  "campaigntvvariable", "campaignunallocatedvariable"
];
