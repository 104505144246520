import React, { FC, ReactElement, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import MODE_LOGO from "../../assets/mode-logo-tp.png";
import { AuthContext } from "../../context/AuthContext";
import { SelectedBGLocalStorage } from "../../data/constants/localStorage";

import TopNavigation, { TopNavigationProps } from "@cloudscape-design/components/top-navigation";
import { ButtonDropdownProps } from "@cloudscape-design/components/button-dropdown";
import { BUSINESS_ROUTE_PATHS } from "../../data/constants/common";

const Header: FC<object> = (): ReactElement | null => {
  const {
    userAttributes,
    userBusinessGroupMap,
    setSelectedBG,
    selectedBG,
    logout,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const pathName = window.location.href;
  const userInAdminPage = pathName.includes(BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT)
    || pathName.includes(BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC);

  const setUtilities = (): TopNavigationProps.Utility[] => {
    const utilities = [
      {
        type: "menu-dropdown",
        text: selectedBG?.businessGroupName,
        onItemClick: (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
          const id = event.detail.id;
          const selected = userBusinessGroupMap?.businessGroups[id];
          setSelectedBG(selected);
          localStorage.setItem(
            SelectedBGLocalStorage.SELECTED_BG_NAME,
            JSON.stringify(selected)
          );
          navigate("/campaigns");
        },
        items: userBusinessGroupMap
          ? Object.values(userBusinessGroupMap.businessGroups).map(
            (businessGroup) => ({
              text: businessGroup.businessGroupName,
              id: businessGroup.businessGroupSlug,
            })
          )
          : [],
      },
      {
        type: "menu-dropdown",
        text:
          userAttributes?.givenName + " " + userAttributes?.familyName ===
          " "
            ? "Unknown"
            : userAttributes?.givenName + " " + userAttributes?.familyName,
        description: userAttributes?.email || "Unknown",
        onItemClick: async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
          const id = event.detail.id;
          if (id === "logout") {
            try {
              await logout();
            } catch (err) {
              return "Error logging out";
            }
          }
        },
        items: [{ id: "logout", text: "Logout" }],
      },
    ] as TopNavigationProps.Utility[];

    // Hide Business group dropdown selection in the Header when user is in Admin page
    return userInAdminPage ? [utilities[1]] : utilities;
  };


  return (
    <div className={styles.nav}>
      <TopNavigation
        identity={{
          href: "/",
          logo: { src: MODE_LOGO, alt: "MODE" },
        }}
        utilities={setUtilities()}
        i18nStrings={{
          searchIconAriaLabel: "TaCS",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu",
        }}
        data-testid="header"
      />
    </div>
  );
};

export default Header;
