import { SANDBOX_CONFIG } from "../config/sandboxConfig";

export const ENVIRONMENT = {
  ALPHA: "alpha-tacs.mode.amazon",
  BETA: "beta-tacs.mode.amazon",
  GAMMA: "gamma-tacs.mode.amazon",
  PROD: "tacs.mode.amazon",
  LOCAL: "localhost"
};

// Update this environment variable with the sandbox name
// See src/data/config/sandboxConfig.ts for allowed names
const CURRENT_SANDBOX = "SANDBOX_1";

const ENVIRONMENT_CONFIG = {
  LOCAL: {
    REGION: SANDBOX_CONFIG[CURRENT_SANDBOX].REGION,
    COGNITO_IDENTITY_POOL_ID:
      SANDBOX_CONFIG[CURRENT_SANDBOX].COGNITO_IDENTITY_POOL_ID,
    COGNITO_USER_POOL_ID: SANDBOX_CONFIG[CURRENT_SANDBOX].COGNITO_USER_POOL_ID,
    COGNITO_WEB_CLIENT_ID:
      SANDBOX_CONFIG[CURRENT_SANDBOX].COGNITO_WEB_CLIENT_ID,
    COGNITO_DOMAIN: SANDBOX_CONFIG[CURRENT_SANDBOX].COGNITO_DOMAIN,
    COGNITO_REDIRECT_SIGNIN:
      SANDBOX_CONFIG[CURRENT_SANDBOX].COGNITO_REDIRECT_SIGNIN,
    COGNITO_REDIRECT_SIGNOUT:
      SANDBOX_CONFIG[CURRENT_SANDBOX].COGNITO_REDIRECT_SIGNOUT,
    API_ENDPOINT: SANDBOX_CONFIG[CURRENT_SANDBOX].API_ENDPOINT,
    S3_BULK_UPLOAD_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX].S3_BULK_UPLOAD_BUCKET_NAME,
    S3_BULK_PROCESSING_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX].S3_BULK_PROCESSING_BUCKET_NAME,
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME,
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME,
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME,
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME,
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME,
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME,
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_UPLOAD_USER_BUCKET_NAME,
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
      SANDBOX_CONFIG[CURRENT_SANDBOX]
        .S3_BULK_PROCESSED_USER_BUCKET_NAME,
    S3_BUCKET_REGION: SANDBOX_CONFIG[CURRENT_SANDBOX].S3_BUCKET_REGION,
    PINPOINT_APP_ID: SANDBOX_CONFIG[CURRENT_SANDBOX].PINPOINT_APP_ID,
    RUM_APP_ID: SANDBOX_CONFIG[CURRENT_SANDBOX].RUM_APP_ID,
    SEARCH_SERVICE_ENDPOINT: SANDBOX_CONFIG[CURRENT_SANDBOX].SEARCH_SERVICE_ENDPOINT
  },
  ALPHA: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:59688023-42a5-4d89-8876-442d14c43c50",
    COGNITO_USER_POOL_ID: "us-west-2_qDYyuc22x",
    COGNITO_WEB_CLIENT_ID: "5teeh63rns2lodkqiqvn496m6r",
    COGNITO_DOMAIN: "tacs-oidc-app-alpha.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.ALPHA}`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.ALPHA}`,
    API_ENDPOINT:
      "https://alpha-tacs-api.mode.amazon",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-alpha",
    S3_BULK_PROCESSING_BUCKET_NAME: "mode-tacs-bulk-processed-bucket-alpha",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-alpha",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-alpha",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-alpha",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-alpha",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-alpha",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-alpha",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
      "mode-tacs-bulk-upload-user-bucket-alpha",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
      "mode-tacs-bulk-processed-user-bucket-alpha",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "149327f7af0c4000ba17b99a5f169f7a",
    RUM_APP_ID: "7d07a7d6-eda0-4f43-bd0e-721dea1f0ddb",
    SEARCH_SERVICE_ENDPOINT: "https://alpha-tacs-search-api.mode.amazon/search",
  },
  BETA: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:daa51911-da29-49a2-895a-c6b1df4ca09b",
    COGNITO_USER_POOL_ID: "us-west-2_YB0PrpFYi",
    COGNITO_WEB_CLIENT_ID: "50hpkpi1skimkjipsdujot4p1u",
    COGNITO_DOMAIN: "tacs-oidc-app-beta.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.BETA}`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.BETA}`,
    API_ENDPOINT: "https://beta-tacs-api.mode.amazon",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-beta",
    S3_BULK_PROCESSING_BUCKET_NAME: "mode-tacs-bulk-processed-bucket-beta",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-beta",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-beta",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-beta",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-beta",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-beta",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-beta",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
      "mode-tacs-bulk-upload-user-bucket-beta",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
      "mode-tacs-bulk-processed-user-bucket-beta",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "80b7e3b7f2da479a9f5d74ead8f832cd",
    RUM_APP_ID: "633851f4-d5c0-4028-aad5-393dc605c462",
    SEARCH_SERVICE_ENDPOINT: "https://beta-tacs-search-api.mode.amazon/search",
  },
  GAMMA: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:79bcee01-00ca-4da2-92c8-8d31165d1907",
    COGNITO_USER_POOL_ID: "us-west-2_1z8N3MqCi",
    COGNITO_WEB_CLIENT_ID: "315esg3ecf355ijrpohc0jbqro",
    COGNITO_DOMAIN: "tacs-oidc-app-gamma.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.GAMMA}`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.GAMMA}`,
    API_ENDPOINT:
      "https://gamma-tacs-api.mode.amazon",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-gamma",
    S3_BULK_PROCESSING_BUCKET_NAME: "mode-tacs-bulk-processed-bucket-gamma",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-gamma",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-gamma",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-gamma",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-gamma",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-gamma",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-gamma",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
      "mode-tacs-bulk-upload-user-bucket-gamma",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
      "mode-tacs-bulk-processed-user-bucket-gamma",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "1e45585d68c04e1685753560f7fb2f91",
    RUM_APP_ID: "d032ea38-b198-47bf-8a9a-e5cbca8ff79c",
    SEARCH_SERVICE_ENDPOINT: "https://gamma-tacs-search-api.mode.amazon/search",
  },
  PROD: {
    REGION: "us-west-2",
    COGNITO_IDENTITY_POOL_ID: "us-west-2:729606b8-1099-4ba9-9477-9539d0e17d0f",
    COGNITO_USER_POOL_ID: "us-west-2_SyrITB7qM",
    COGNITO_WEB_CLIENT_ID: "6h6rj1p7qo0p7eikkhvl20flbt",
    COGNITO_DOMAIN: "tacs-oidc-app-prod.auth.us-west-2.amazoncognito.com",
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.PROD}`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.PROD}`,
    API_ENDPOINT: "https://tacs-api.mode.amazon",
    S3_BULK_UPLOAD_BUCKET_NAME: "mode-tacs-bulk-upload-bucket-prod",
    S3_BULK_PROCESSING_BUCKET_NAME: "mode-tacs-bulk-processed-bucket-prod",
    S3_BULK_UPLOAD_FLIGHT_PLAN_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-flight-plan-bucket-prod",
    S3_BULK_PROCESSED_FLIGHT_PLAN_BUCKET_NAME:
      "mode-tacs-bulk-processed-flight-plan-bucket-prod",
    S3_BULK_UPLOAD_MEDIA_ITEM_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-bucket-prod",
    S3_BULK_PROCESSED_MEDIA_ITEM_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-bucket-prod",
    S3_BULK_UPLOAD_MEDIA_ID_REQUEST_BUCKET_NAME:
      "mode-tacs-bulk-upload-media-item-id-bucket-prod",
    S3_BULK_PROCESSED_MEDIA_ID_BUCKET_NAME:
      "mode-tacs-bulk-processed-media-item-id-bucket-prod",
    S3_BULK_UPLOAD_USER_BUCKET_NAME:
      "mode-tacs-bulk-upload-user-bucket-prod",
    S3_BULK_PROCESSED_USER_BUCKET_NAME:
      "mode-tacs-bulk-processed-user-bucket-prod",
    S3_BUCKET_REGION: "us-west-2",
    PINPOINT_APP_ID: "9703946e46204cb6b26f5d152a832e54",
    RUM_APP_ID: "6c049340-09cd-4368-8a4a-cc6894ab4c0a",
    SEARCH_SERVICE_ENDPOINT: "https://tacs-search-api.mode.amazon/search",
  },
};

export const getEnvironment = () => {
  const { hostname } = window.location;

  switch (hostname) {
  case ENVIRONMENT.ALPHA:
    return ENVIRONMENT.ALPHA;
  case ENVIRONMENT.BETA:
    return ENVIRONMENT.BETA;
  case ENVIRONMENT.GAMMA:
    return ENVIRONMENT.GAMMA;
  case ENVIRONMENT.PROD:
    return ENVIRONMENT.PROD;
  default:
    return ENVIRONMENT.LOCAL;
  }
};

export const getEnvironmentVariables = () => {
  const { hostname } = window.location;

  switch (hostname) {
  case ENVIRONMENT.ALPHA:
    return ENVIRONMENT_CONFIG.ALPHA;
  case ENVIRONMENT.BETA:
    return ENVIRONMENT_CONFIG.BETA;
  case ENVIRONMENT.GAMMA:
    return ENVIRONMENT_CONFIG.GAMMA;
  case ENVIRONMENT.PROD:
    return ENVIRONMENT_CONFIG.PROD;
  default:
    return ENVIRONMENT_CONFIG.LOCAL;
  }
};

export const isProd = getEnvironment() === ENVIRONMENT.PROD;

export const ApprovedBGsForV2 = ["MODE_DUMMY", "XCM_US"];
export const ApprovedBGsForV3 = [
  "MODE_DUMMY",
  "DNS_Marketing_GRP",
  "XCM_AU",
  "XCM_BR",
  "XCM_CA",
  "XCM_EU",
  "XCM_IN",
  "XCM_JP",
  "XCM_MENA",
  "XCM_MX",
  "XCM_SG",
  "XCM_US",
];
