import { Analytics } from "aws-amplify";

export const updatePinpointEndpoint = async (email: string, isAdmin: boolean) => {
  // We need the endpoint to be updated to have the username associated with all events
  // User related metrics, e.g. DAU, MAU, require this.
  try {
    await Analytics.updateEndpoint({
      userId: email,
      userAttributes: {
        isAdmin: [ isAdmin ], // we can now filter on whether user is admin in usage metrics
      },
      optOut: "ALL" // opt out as users do not need to receive any notifications from us
    });
  } catch (e) {
    console.log("Error when update endpoint for Pinpoint: ", e);
  }
};
