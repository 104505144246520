import React, {
  FC,
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import {
  SpaceBetween,
  Header,
  Form,
  Button,
  Box,
  ExpandableSection,
  Select,
  Popover,
  ContentLayout,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { FixedPlannedSpendInputs, VariablePlannedSpendInputs } from "../../data/constants/campaign/SpendInputs";
import {
  MARKETPLACE_OPTIONS,
  CAMPAIGN_MEASUREMENT_MODELS,
  CURRENCY_OPTIONS,
  validateForm,
  getLanguageCode,
} from "./config";
import { getEnvironmentVariables } from "../../data/constants/environment";
import {
  DATE_FORMAT,
  RESOURCE_CAMPAIGN,
  SELECTED_BU,
  VARIABLE_SPEND_SUFFIX,
  FIXED_SPEND_SUFFIX,
  SCROLL_BEHAVIOR,
  SCROLL_BLOCK,
  REF_BUSINESS_UNIT,
  REF_BASIC_DETAILS,
  REF_CUSTOM_ATTRIBUTES,
  REF_SPEND,
} from "../../data/constants/common";
import { NO_ACCESS_TO_BU, DEFAULT_MESSAGE } from "../../data/constants/errorMessages";
import { returnErrorMessage } from "../../data/helpers/returnErrorMessages";
import { validateNumber } from "../../data/helpers/validators";
import ScreenLoader from "../../components/common/ScreenLoader";
import TextInput from "../../components/form/inputs/TextInput";
import DateInput from "../../components/form/inputs/DateInput";
import DropdownInput from "../../components/form/inputs/DropdownInput";
import MODECurrencyInput from "../../components/form/inputs/MODECurrencyInput";
import MultiSelectInput from "../../components/form/inputs/MultiSelectInput";
import styles from "./Form.module.css";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";
import { IBusinessUnit } from "../../data/interfaces/IUser";
import { AuthContext } from "../../context/AuthContext";
import { Attribute } from "../admin/Interface";
import { getBusinessUnitsOptions } from "../../data/helpers/functions";

const CampaignForm: FC<object> = (): ReactElement | null => {
  const { businessunitSlug, action, tacsid } = useParams<{
    businessunitSlug?: string;
    action?: string;
    tacsid?: string;
  }>();
  const businessUnitRef = useRef<any>(null);
  const basicDetailsRef = useRef<any>(null);
  const customAttributesRef = useRef<any>(null);
  const spendRef = useRef<any>(null);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const {
    getIdToken,
    selectedBG,
    customCampaignAttributes: customAttributes,
    isAllowedAccessToBU,
    userBusinessGroupMap,
    updateCurrentSelectedBG,
  } = useContext(AuthContext);

  const [inputValues, setInputValues] = useState<any>({});
  const [selectedCustomAttributes, setSelectedCustomAttributes] = useState<any>(
    {}
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [totalFixedPlannedSpend, setTotalFixedPlannedSpend] = useState<number>(0);
  const [totalVariablePlannedSpend, setTotalVariablePlannedSpend] = useState<number>(0);
  const [currency, setCurrency] = useState<OptionDefinition>({
    label: "USD",
    value: "USD",
  });
  const [selectedBU, setSelectedBU] = useState<IBusinessUnit | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [isUnallocatedRequired, setIsUnallocatedRequired] =
    useState<boolean>(true);

  const navigate = useNavigate();

  const onCancel = () => {
    navigate(-1);
  };

  const handleInputChange = (value: any, name: string) => {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleCustomAttributeChange = (value: any, name: string) => {
    setSelectedCustomAttributes({
      ...selectedCustomAttributes,
      [name]: value,
    });
  };

  const handleScrollOnError = (errors: any) => {
    const refs = {
      [REF_BUSINESS_UNIT]: businessUnitRef,
      [REF_BASIC_DETAILS]: basicDetailsRef,
      [REF_CUSTOM_ATTRIBUTES]: customAttributesRef,
      [REF_SPEND]: spendRef,
    };
    const scrollToRef = errors[Object.keys(errors)[0]].section as keyof typeof refs;
    refs[scrollToRef].current?.scrollIntoView({ behavior: SCROLL_BEHAVIOR, block: SCROLL_BLOCK });
  };

  const handleBusinessUnitChange = (object: any) => {
    setSelectedBU(selectedBG?.businessUnits[object.value] as IBusinessUnit);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (businessunitSlug != null) {
        setSelectedBU(
          selectedBG?.businessUnits[businessunitSlug] as IBusinessUnit
        );
      } else {
        setIsLoading(false);
        return;
      }

      const url = `${
        getEnvironmentVariables().API_ENDPOINT
      }/campaigns/${businessunitSlug}/${tacsid}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        method: "GET",
      });
      const responseData = await response.json();

      if (!response.ok) {
        handleErrorResponse(response.status, responseData.message);
        return;
      }

      handleSuccessResponse(responseData.campaign);
    };

    const handleSuccessResponse = (data: any) => {
      if ("campaignstartdateplanned" in data) {
        data.campaignstartdateplanned = DateTime.fromSeconds(
          data.campaignstartdateplanned
        )
          .toUTC()
          .toISODate();
      }

      if ("campaignenddateplanned" in data) {
        data.campaignenddateplanned = DateTime.fromSeconds(
          data.campaignenddateplanned
        )
          .toUTC()
          .toISODate();
      }

      data.campaignmarketplace = MARKETPLACE_OPTIONS.find(
        (option) => option.value === data.campaignmarketplace
      );

      data.campaignmeasurement = (data.campaignmeasurement || [])
        .map((measurement: any) =>
          CAMPAIGN_MEASUREMENT_MODELS.find(
            (option) => option.value === measurement
          )
        )
        .filter(Boolean);

      const editedCustomAttributes: { [key: string]: any } = {};
      if (data.customattributes && customAttributes != null) {
        customAttributes.attributes.forEach((bgCustomAttrib: any) => {
          bgCustomAttrib.values.forEach((value: any) => {
            Object.keys(data.customattributes).forEach(
              (customAttributeKey: string) => {
                if (data.customattributes[customAttributeKey] === value.slug) {
                  editedCustomAttributes[customAttributeKey] = {
                    label: value.uidisplay,
                    value: data.customattributes[customAttributeKey],
                  };
                }
              }
            );
          });
        });
      }

      setSelectedCustomAttributes(editedCustomAttributes);
      setCurrency(
        CURRENCY_OPTIONS.find((option) => option.value === data.currency) ||
          CURRENCY_OPTIONS[0]
      );
      setInputValues(data);
      setTotalFixedPlannedSpend(data.spendTotals.fixedSpend);
      setTotalVariablePlannedSpend(data.spendTotals.variableSpend);
      setIsLoading(false);
    };

    const handleErrorResponse = (statusCode: number, errorMessage: string) => {
      setIsLoading(false);
      setHasError(true);
      const messageError = returnErrorMessage(statusCode, businessunitSlug, RESOURCE_CAMPAIGN);
      addMessage({
        type: "error",
        content: messageError.message,
        dismissible: true,
      },
      messageError,
      errorMessage);
    };

    if (customAttributes == null) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    if (action === "edit" && customAttributes != null) {
      if (isAllowedAccessToBU(businessunitSlug, userBusinessGroupMap)) {
        updateCurrentSelectedBG(businessunitSlug, userBusinessGroupMap);
        fetchData();
      } else {
        setIsLoading(false);
        setHasError(true);
        addMessage(
          {
            type: "error",
            content: NO_ACCESS_TO_BU(businessunitSlug).message,
            dismissible: true,
          },
          NO_ACCESS_TO_BU(businessunitSlug)
        );
      }
    }
    setIsLoading(false);
  }, [customAttributes]);

  const handleSum = () => {
    const values: string[] = Object.keys(inputValues);
    let temporaryFixedPlannedTotalSpend = 0;
    let temporaryVariablePlannedTotalSpend = 0;
    let foundOtherSpends = false;
    values.forEach((value: string) => {
      if (value.includes(FIXED_SPEND_SUFFIX) && validateNumber(inputValues[value])) {
        temporaryFixedPlannedTotalSpend += Number(inputValues[value]);
      } else if (value.includes(VARIABLE_SPEND_SUFFIX) && validateNumber(inputValues[value])) {
        temporaryVariablePlannedTotalSpend += Number(inputValues[value]);
      }
    });

    if (temporaryFixedPlannedTotalSpend > 0 || temporaryVariablePlannedTotalSpend > 0) {
      foundOtherSpends = true;
    }

    setIsUnallocatedRequired(!foundOtherSpends);
    setTotalFixedPlannedSpend(temporaryFixedPlannedTotalSpend);
    setTotalVariablePlannedSpend(temporaryVariablePlannedTotalSpend);
  };

  const onSubmit = async () => {
    setDisable(true);
    const errors = validateForm(
      inputValues,
      selectedCustomAttributes,
      customAttributes,
      selectedBU,
    );
    let redirectId: string = tacsid || "";

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setDisable(false);
      handleScrollOnError(errors);
      return;
    }

    const url = `${getEnvironmentVariables().API_ENDPOINT}/campaigns/${
      selectedBU?.businessUnitSlug
    }${action == "edit" ? "/" + tacsid : ""}`;

    const startDateDT = DateTime.fromISO(inputValues.campaignstartdateplanned);
    const endDateDT = DateTime.fromISO(inputValues.campaignenddateplanned);

    const finalCustomAttributes: any = {};
    Object.keys(selectedCustomAttributes).forEach((key) => {
      finalCustomAttributes[key] = selectedCustomAttributes[key].value;
    });
    const bodyParams = JSON.stringify({
      campaign: {
        businessunitSlug: selectedBU?.businessUnitSlug,
        budgetclass: selectedBU?.budgetClass,
        campaignmarketplace: inputValues.campaignmarketplace.value,
        campaignname: inputValues.campaignname,
        campaignstartdateplanned: startDateDT.toUTC().toUnixInteger(),
        campaignenddateplanned: endDateDT.toUTC().toUnixInteger(),
        currency: currency.value,
        campaignunallocatedfixed:
          Number(inputValues.campaignunallocatedfixed) || 0,
        campaigninfluencersfixed:
          Number(inputValues.campaigninfluencersfixed) || 0,
        campaigncinemafixed: Number(inputValues.campaigncinemafixed) || 0,
        campaigndigitalfixed: Number(inputValues.campaigndigitalfixed) || 0,
        campaignsocialfixed: Number(inputValues.campaignsocialfixed) || 0,
        campaignoohfixed: Number(inputValues.campaignoohfixed) || 0,
        campaignprintfixed: Number(inputValues.campaignprintfixed) || 0,
        campaignradiofixed: Number(inputValues.campaignradiofixed) || 0,
        campaigntvfixed: Number(inputValues.campaigntvfixed) || 0,
        campaignotherfixed: Number(inputValues.campaignotherfixed) || 0,
        campaignunallocatedvariable: Number(inputValues.campaignunallocatedvariable) || 0,
        campaigndigitalvariable: Number(inputValues.campaigndigitalvariable) || 0,
        campaigntvvariable: Number(inputValues.campaigntvvariable) || 0,
        campaignsocialvariable: Number(inputValues.campaignsocialvariable) || 0,
        campaignassociatesvariable: Number(inputValues.campaignassociatesvariable) || 0,
        campaignmailvariable: Number(inputValues.campaignmailvariable) || 0,
        campaignmobilevariable: Number(inputValues.campaignmobilevariable) || 0,
        campaignsearchvariable: Number(inputValues.campaignsearchvariable) || 0,
        ...("edit" === action && {
          tacsid,
          metadata: inputValues.metadata,
          campaignsystemstatus: inputValues.campaignsystemstatus,
        }),
        campaignmeasurement:
          inputValues.campaignmeasurement?.map((obj: any) => obj.value) || [],
        customattributes:
          Object.keys(finalCustomAttributes).length > 0
            ? finalCustomAttributes
            : null,
      },
    });

    try {
      const response = await fetch(url, {
        method: action == "edit" ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        body: bodyParams,
      });

      if (!response.ok) {
        const data = await response.json();
        setDisable(false);
        setIsLoading(false);
        setHasError(true);
        const messageError = returnErrorMessage(response.status, businessunitSlug, RESOURCE_CAMPAIGN);
        addMessage({
          type: "error",
          content: messageError.message,
          dismissible: true,
        },
        messageError,
        data.message);
        return;
      }

      if (action != "edit") {
        const data = await response.json();
        redirectId = data.tacsId;
      }

      navigate(`/campaigns/${selectedBU?.businessUnitSlug}/${redirectId}`);
    } catch (error: any) {
      setHasError(true);
      setDisable(false);
      addMessage({
        type: "error",
        content: DEFAULT_MESSAGE.message,
        dismissible: true,
      },
      error.message);
    }
  };

  return (
    <div data-testid="campaign-view">
      {isLoading ? (
        <div>
          <ScreenLoader />
        </div>
      ) : (
        <div>
          {hasError ? (
            <div>
              <></>
            </div>
          ) : (
            <SpaceBetween size="m" data-testid="campaign-form">
              <Box></Box>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      data-testid="cancel-btn"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={onSubmit}
                      data-testid="submit-btn"
                      disabled={disable}
                    >
                      {action == "edit" ? "Save" : "Create Campaign"}
                    </Button>
                  </SpaceBetween>
                }
                errorIconAriaLabel="Error"
              >
                <ContentLayout
                  header={
                    <Header>
                      <div
                        className={`${styles.flex} ${styles.scrollOffset}`}
                        ref={businessUnitRef}
                      >
                        <span className={styles.headerComponent}>
                          {action == "edit" ? "Update" : "Create"} Campaign in{" "}
                        </span>
                        {action == "edit" ? (
                          inputValues.businessunitSlug
                        ) : (
                          <DropdownInput
                            label="Business Unit"
                            error={errors}
                            setErrors={setErrors}
                            placeholder="Choose Business Unit"
                            isRequired={true}
                            name={SELECTED_BU}
                            onInputChange={handleBusinessUnitChange}
                            inputValue={
                              selectedBU
                                ? {
                                  label: selectedBU?.businessUnitName,
                                  value: selectedBU?.businessUnitSlug,
                                }
                                : null
                            }
                            options={getBusinessUnitsOptions(selectedBG)}
                            disabled={action == "edit" ? true : false}
                            testid="buSelector"
                            infoDescription="The Business Unit where this campaign will be created."
                            expandToViewport={true}
                            showCSS={false}
                          />
                        )}
                      </div>
                    </Header>
                  }
                >
                  <SpaceBetween size="l">
                    <ExpandableSection
                      headerText="Basic Details"
                      variant="container"
                      defaultExpanded
                      data-testid="basic-details-panel"
                    >
                      <div
                        className={`${styles.flex} ${styles.scrollOffset}`}
                        ref={basicDetailsRef}
                      >
                        <TextInput
                          label="Campaign Name"
                          error={errors}
                          setErrors={setErrors}
                          placeholder="Campaign Name"
                          isRequired={true}
                          name="campaignname"
                          onInputChange={handleInputChange}
                          inputValue={inputValues.campaignname}
                          isDisabled={false}
                          testid="name-field"
                          infoDescription="The friendly name of a campaign."
                        />
                        <DropdownInput
                          label="Marketplace"
                          error={errors}
                          setErrors={setErrors}
                          placeholder="Choose Marketplace"
                          isRequired={true}
                          name="campaignmarketplace"
                          onInputChange={handleInputChange}
                          inputValue={inputValues.campaignmarketplace}
                          options={MARKETPLACE_OPTIONS}
                          disabled={action == "edit" ? true : false}
                          testid="marketplace-field"
                          infoDescription="The Country where campaign impressions will be delivered."
                        />
                        <DateInput
                          label="Start Date"
                          error={errors}
                          setErrors={setErrors}
                          placeholder={DATE_FORMAT}
                          isRequired={true}
                          name="campaignstartdateplanned"
                          onInputChange={handleInputChange}
                          inputValue={inputValues.campaignstartdateplanned}
                          testid="startdate-field"
                          infoDescription="The initial planned start date of the campaign"
                        />
                        <DateInput
                          label="End Date"
                          error={errors}
                          setErrors={setErrors}
                          placeholder={DATE_FORMAT}
                          isRequired={true}
                          name="campaignenddateplanned"
                          onInputChange={handleInputChange}
                          inputValue={inputValues.campaignenddateplanned}
                          testid="enddate-field"
                          infoDescription="The initial planned end date of the campaign"
                        />
                      </div>
                    </ExpandableSection>
                    <ExpandableSection
                      headerText="Additional Requirements"
                      variant="container"
                      defaultExpanded
                      data-testid="additional-requirements-panel"
                    >
                      <div
                        className={`${styles.customAttrib} ${styles.scrollOffset}`}
                        ref={customAttributesRef}
                      >
                        {customAttributes &&
                        customAttributes?.attributes.length > 0 ? (
                            customAttributes?.attributes.map(
                              (attribute: Attribute, index: number) => (
                                <DropdownInput
                                  key={index}
                                  label={attribute.uidisplay}
                                  error={errors}
                                  setErrors={setErrors}
                                  placeholder={`Choose ${attribute.uidisplay}`}
                                  isRequired={true}
                                  name={attribute.slug}
                                  onInputChange={handleCustomAttributeChange}
                                  inputValue={
                                    selectedCustomAttributes[`${attribute.slug}`]
                                  }
                                  options={attribute.values.filter((attr) => attr.active)
                                    .map((value) => ({
                                      label: value.uidisplay,
                                      value: value.slug,
                                    }))
                                  }
                                  disabled={false}
                                  testid={`${attribute.slug}-field`}
                                  infoDescription={attribute.dictionary}
                                />
                              )
                            )
                          ) : (
                            <>
                              No custom attributes are available for this Business
                              Group
                            </>
                          )}
                      </div>
                    </ExpandableSection>
                    <ExpandableSection
                      headerText={"Spend Breakdown"}
                      variant="container"
                      defaultExpanded
                      data-testid="budget-and-spend-panel"
                    >
                      <SpaceBetween size="xl">
                        <div
                          className={`${styles.flex} ${styles.scrollOffset}`}
                          ref={spendRef}
                        >
                          <span>
                            <span className={styles.totalSpendText}>
                              Budget Class:{" "}
                            </span>
                            {selectedBU?.budgetClass}
                            <Popover
                              header="Budget Class"
                              content="A 4 character string which represents a unique funding source from the general ledger. Provided by MODE Operations at the time of BU creation."
                              triggerType="custom"
                            >
                              <Button
                                iconName="status-info"
                                variant="inline-icon"
                                data-testid={name + "InfoButton"}
                              />
                            </Popover>
                          </span>

                          <div style={{ minWidth: "100px" }}>
                            <Select
                              selectedOption={currency}
                              onChange={(event: any) =>
                                setCurrency(event.detail.selectedOption)
                              }
                              options={CURRENCY_OPTIONS}
                              selectedAriaLabel="Selected"
                              data-testid="currency-field"
                            />
                          </div>
                        </div>
                        <ExpandableSection
                          headerText="Fixed Spend"
                          variant="container"
                          defaultExpanded
                          data-testid="fixed-planned-spend-panel"
                        >
                          <div className={styles.itemizedSpendParent}>
                            <MODECurrencyInput
                              name="campaignunallocatedfixed"
                              error={errors}
                              setErrors={setErrors}
                              onBlurEvent={handleSum}
                              isRequired={isUnallocatedRequired}
                              isDisabled={false}
                              label="Unallocated Fixed Planned Spend"
                              inputValue={inputValues.campaignunallocatedfixed}
                              currency={currency.value || "USD"}
                              onInputChange={handleInputChange}
                              testid="campaignunallocatedfixed"
                              infoDescription="Initial fixed planned spend that has yet to be allocated to a channel. This is only required if other fixed spends are not filled out."
                            />
                            <div className={styles.budgetSpendMessage}>
                              <span className={styles.itemizedSpendMessage}>
                                If you don&apos;t know your itemized spending,
                                enter the full amount in &quot;Total Unallocated Fixed
                                Planned Spend&quot;
                              </span>
                            </div>
                          </div>
                          <div className={styles.spendFlex}>
                            { FixedPlannedSpendInputs.map((item: any, idx: any) => (
                              <MODECurrencyInput
                                key={idx}
                                name={item.name}
                                error={errors}
                                setErrors={setErrors}
                                onBlurEvent={handleSum}
                                isRequired={item.isRequired}
                                isDisabled={item.isDisabled}
                                label={item.label}
                                inputValue={inputValues[item.name]}
                                currency={currency.value || "USD"}
                                onInputChange={handleInputChange}
                                testid={item.testid}
                                infoDescription={item.infoDescription}
                              />
                            ))}
                          </div>
                          <Box textAlign="right">
                            <span className={styles.totalSpend}>
                              Total Spend:&nbsp;
                              <span data-testid="fixed-planned-spend-total">
                                {new Intl.NumberFormat(
                                  getLanguageCode(currency?.label || "USD"),
                                  {
                                    style: "currency",
                                    currency: currency.label,
                                    currencyDisplay: "symbol",
                                  }
                                ).format(totalFixedPlannedSpend)}
                              </span>
                              <span style={{ paddingLeft: "5px" }}>
                                {currency.label}
                              </span>
                            </span>
                          </Box>
                        </ExpandableSection>
                        <ExpandableSection
                          headerText="Variable Spend"
                          variant="container"
                          defaultExpanded
                          data-testid="variable-planned-spend-panel"
                        >
                          <div className={styles.itemizedSpendParent}>
                            <MODECurrencyInput
                              name="campaignunallocatedvariable"
                              error={errors}
                              setErrors={setErrors}
                              onBlurEvent={handleSum}
                              isRequired={false}
                              isDisabled={false}
                              label="Unallocated Variable Planned Spend"
                              inputValue={inputValues.campaignunallocatedvariable}
                              currency={currency.value || "USD"}
                              onInputChange={handleInputChange}
                              testid="campaignunallocatedvariable"
                              infoDescription="Initial variable planned spend that has yet to be allocated to a channel. This is only required if other variable spends are not filled out."
                            />
                            <div className={styles.budgetSpendMessage}>
                              <span className={styles.itemizedSpendMessage}>
                                If you don&apos;t know your itemized spending,
                                enter the full amount in &quot;Total Unallocated Variable
                                Planned Spend&quot;
                              </span>
                            </div>
                          </div>
                          <div className={styles.spendFlex}>
                            { VariablePlannedSpendInputs.map((item, idx) => (
                              <MODECurrencyInput
                                key={idx}
                                name={item.name}
                                error={errors}
                                setErrors={setErrors}
                                onBlurEvent={handleSum}
                                isRequired={item.isRequired}
                                isDisabled={item.isDisabled}
                                label={item.label}
                                inputValue={inputValues[item.name]}
                                currency={currency.value || "USD"}
                                onInputChange={handleInputChange}
                                testid={item.testid}
                                infoDescription={item.infoDescription}
                              />
                            ))}
                          </div>
                          <Box textAlign="right">
                            <span className={styles.totalSpend}>
                              Total Spend:&nbsp;
                              <span data-testid="variable-planned-spend-total">
                                {new Intl.NumberFormat(
                                  getLanguageCode(currency?.label || "USD"),
                                  {
                                    style: "currency",
                                    currency: currency.label,
                                    currencyDisplay: "symbol",
                                  }
                                ).format(totalVariablePlannedSpend)}
                              </span>
                              <span style={{ paddingLeft: "5px" }}>
                                {currency.label}
                              </span>
                            </span>
                          </Box>
                        </ExpandableSection>
                      </SpaceBetween>
                    </ExpandableSection>
                    <ExpandableSection
                      headerText="Measurement"
                      variant="container"
                      defaultExpanded
                      data-testid="measurement-panel"
                    >
                      <div className={styles.measurement}>
                        <MultiSelectInput
                          label="Campaign Measurement Models"
                          error={errors}
                          setErrors={setErrors}
                          placeholder="Choose options"
                          isRequired={false}
                          name="campaignmeasurement"
                          onInputChange={handleInputChange}
                          inputValue={inputValues.campaignmeasurement}
                          options={CAMPAIGN_MEASUREMENT_MODELS}
                          testid="measurement-field"
                          infoDescription="A multi-select input to identify the measurement models that will be used for the Campaign. This also sets requirements for third party tags."
                        />
                      </div>
                    </ExpandableSection>
                  </SpaceBetween>
                </ContentLayout>
              </Form>
            </SpaceBetween>
          )}
        </div>
      )}
    </div>
  );
};

export default CampaignForm;
