import { ReactNode } from "react";
import {
  CollectionPreferencesProps,
  NonCancelableCustomEvent,
  PropertyFilterProps,
  TableProps,
} from "@cloudscape-design/components";
import { ENTITY_TYPE, PAGE_SIZE } from "../constants/common";
import { ITableSettings } from "../interfaces/ITable";
import { IBusinessGroup } from "../interfaces/IUser";
import { MediaItem } from "../interfaces/IMediaItems";
import { Attribute } from "../../views/admin/Interface";
import { ICampaign } from "../interfaces/ICampaign";

export const getTablePreferencesFromLocalStorage = (
  businessProp: string,
  preferedTableSettings: string[]
): ITableSettings => {
  const preference: string | null = localStorage.getItem(businessProp);
  const currrentSettings: ITableSettings = preference
    ? JSON.parse(preference)
    : {};

  let businessTableSettings: ITableSettings = {
    pageSize: PAGE_SIZE,
    wraplines: false,
    visibleContent: preferedTableSettings,
  };

  if (currrentSettings.pageSize) {
    businessTableSettings = currrentSettings;
  }

  return businessTableSettings;
};

export type HandleUserSavedTablePreferences = {
  businessProperty: string;
  event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>;
  setTablePreferences: (arg0: CollectionPreferencesProps.Preferences) => void;
  setCurrentPage: (arg0: number) => void;
};

export const handleUserSavedTablePreferences = ({
  businessProperty,
  setCurrentPage,
  setTablePreferences,
  event,
}: HandleUserSavedTablePreferences): void => {
  const { detail } = event;
  setTablePreferences({
    ...detail,
    wrapLines: false,
  });
  setCurrentPage(1);

  localStorage.setItem(
    businessProperty,
    JSON.stringify({
      ...detail,
      wrapLines: false,
    })
  );
};


export interface HandleSavedSearchFiltersParams {
  entityType: string;
  businessGroup?: string; 
  query: PropertyFilterProps.Query;
  isAdmin?: boolean;
}

export const handleSavedSearchFilters = ({
  entityType,
  businessGroup,
  query,
  isAdmin = false,
}: HandleSavedSearchFiltersParams): void => {
  
  const storageKey = `${entityType}SearchFilter`;
  
  try {
    const storedFilters = localStorage.getItem(storageKey);
    let parsedFilters = storedFilters ? JSON.parse(storedFilters) : {};

    if (isAdmin) {
      parsedFilters = query;
    } else {
      if (!businessGroup) {
        console.warn("businessGroup is not provided. Search filters will not be saved.");
        return;
      }
      parsedFilters[businessGroup] = query;
    }

    localStorage.setItem(storageKey, JSON.stringify(parsedFilters));
  } catch (error) {
    console.error("Failed to save search filters to localStorage:", error);
  }
};

/**
 * Retrieves a list of business unit slugs from a given `IBusinessGroup` object.
 *
 * @param selectedBG {IBusinessGroup | null} - The `IBusinessGroup` object from which to extract the business unit slugs.
 * @returns {Array<{ buSlug: string | undefined; }>} - An array of objects, where each object has a `buSlug` property that represents the business unit slug.
 */
export const getBusinessUnitSlugsListFromBusinessGroup = (
  selectedBG: IBusinessGroup | null
): { buSlug: string | undefined }[] => {
  if (!selectedBG) {
    return [];
  }

  return Object.values(selectedBG.businessUnits).map(
    ({ businessUnitSlug }) => ({
      buSlug: businessUnitSlug,
    })
  );
};

export const BULKJOB_HEADERS = {
  [ENTITY_TYPE.CAMPAIGNS]: {
    tacsid: "",
    campaignname: "",
    campaignmarketplace: "",
    campaignstartdateplanned: "",
    campaignenddateplanned: "",
    campaignmeasurement: "",
    businessunitSlug: "",
    currency: "",
    campaignunallocatedfixed: "",
    campaignunallocatedvariable: "",
    campaigncinemafixed: "",
    campaigndigitalfixed: "",
    campaigndigitalvariable: "",
    campaignoohfixed: "",
    campaignprintfixed: "",
    campaignradiofixed: "",
    campaigntvfixed: "",
    campaigntvvariable: "",
    campaignsocialfixed: "",
    campaignsocialvariable: "",
    campaignassociatesvariable: "",
    campaigninfluencersfixed: "",
    campaignmailvariable: "",
    campaignmobilevariable: "",
    campaignotherfixed: "",
    campaignsearchvariable: "",
  },
  [ENTITY_TYPE.FLIGHT_PLANS]: {
    flightplanid: "",
    flightplantacsid: "",
    flightplanname: "",
    flightplanstartdate: "",
    flightplanenddate: "",
    flightplannotes: "",
    flightplan3pid: "",
  },
  [ENTITY_TYPE.MEDIA_ITEMS]: {
    mediaflightplanid: "",
    mediaitemid: "",
    mediasupplierid: "",
    mediaadserverid: "",
    mediabuyingentityid: "",
    mediaitemtype: "",
    mediasuffix: "",
    mediasubchannel: "",
    mediabuyingentity: "",
    mediasupplier: "",
    mediasitetype: "",
    mediasitename: "",
    mediaplacementcategory: "",
    mediaplacement: "",
    mediaplacementtype: "",
    mediaplacementformat: "",
    mediaplacementlanguage: "",
    mediacostmethod: "",
    mediarate: "",
    mediaplannedbudget: "",
    mediaplannedunits: "",
    mediaplannedbidmin: "",
    mediaplannedstartdate: "",
    mediaplannedenddate: "",
    mediaadenvironment: "",
    mediaadserver: "",
    mediaadservingmethod: "",
    mediabillingsource: "",
    mediatargeteddevice: "",
    mediatargetingtactic: "",
    mediatargetedaudience: "",
    mediatargetingdatasource: "",
    mediacreativenotes: "",
    mediacreativetype: "",
    mediacreativedimensions: "",
    mediacreativelength: "",
    mediadam: "",
    mediadamid: "",
    mediavideoskippable: "",
    mediaitem3ppkg: "",
    mediaitemname: "",
  },
  [ENTITY_TYPE.MEDIA_ID_REQUEST]: {
    flightplanid: "",
    mediaitemtype: "",
    count: "",
  },
  REQUEST_MEDIA_ID_RESPONSE: {
    flightPlanId: "",
    mediaItemId: "",
    mediaItemType: "",
    parentMediaItemId: "",
    tacsId: "",
  },
  [ENTITY_TYPE.USERS]: {
    email: "",
    givenName: "",
    familyName: "",
    isActive: "",
    groupsToAdd: "",
    groupsToRemove: "",
    resetPassword: "",
  },
  FLIGHT_PLAN_HEADER_MAPPER: {
    id: "",
    tacsId: "",
    name: "",
    startDate: "",
    endDate: "",
    flightplannotes: "",
    flightplan3pid: "",
  },
  MEDIA_ITEM_HEADER_MAPPER: {
    flightPlanId: "",
    id: "",
    mediaSupplierId: "",
    mediaAdServerId: "",
    mediaBuyingEntityId: "",
    mediaItemType: "",
    mediaSuffix: "",
    mediaSubChannel: "",
    mediaBuyingEntity: "",
    mediaSupplier: "",
    mediaSiteType: "",
    mediaSiteName: "",
    mediaPlacementCategory: "",
    mediaPlacement: "",
    mediaPlacementType: "",
    mediaPlacementFormat: "",
    mediaPlacementLanguage: "",
    mediaCostMethod: "",
    mediaRate: "",
    mediaPlannedBudget: "",
    mediaPlannedUnits: "",
    mediaPlannedBidMin: "",
    startDate: "",
    endDate: "",
    mediaAdEnvironment: "",
    mediaAdServer: "",
    mediaAdServingMethod: "",
    mediaBillingSource: "",
    mediaTargetedDevice: "",
    mediaTargetingTactic: "",
    mediaTargetedAudience: "",
    mediaTargetingDatasource: "",
    mediaCreativeNotes: "",
    mediaCreativeType: "",
    mediaCreativeDimensions: "",
    mediaCreativeLength: "",
    mediaDam: "",
    mediaDamId: "",
    mediaVideoSkippable: "",
    mediaitem3ppkg: "",
    mediaItemName: "",
  },
};

/**
 * Updates the table columns, visible content preferences, and filtering properties based on the provided custom attributes.
 *
 * @param customAttributes - An object containing the custom attributes.
 * @param columnsUpdate - An array of column definitions for the table.
 * @param visiblePreferencesUpdate - An array of visible content preferences.
 * @param filteringPropertiesUpdate - An array of filtering properties.
 * @param setTableColumns - A function to set the updated table columns.
 * @param setVisibleContent - A function to set the updated visible content preferences.
 * @param setFilteringProperties - A function to set the updated filtering properties.
 */
export const updateTableColumnsWithCustomAttributes = (
  customAttributes: any,
  columnsUpdate: TableProps.ColumnDefinition<any>[],
  visiblePreferencesUpdate: { id: string; label: string; editable: boolean }[],
  filteringPropertiesUpdate: PropertyFilterProps.FilteringProperty[],
  setTableColumns: (
    value: React.SetStateAction<TableProps.ColumnDefinition<any>[]>
  ) => void,
  setVisibleContent: (
    value: React.SetStateAction<
      {
        id: string;
        label: string;
        editable?: boolean;
      }[]
    >
  ) => void,
  setFilteringProperties: (
    value: React.SetStateAction<PropertyFilterProps.FilteringProperty[]>
  ) => void
): void => {
  customAttributes?.attributes.map((attribute: Attribute) => {
    const newColumn: TableProps.ColumnDefinition<any> = {
      id: attribute?.slug,
      header: attribute?.uidisplay,
      cell: (e: MediaItem | ICampaign) =>
        e?.[
          `customattributes.${attribute?.slug}_label` as keyof (
            | MediaItem
            | ICampaign
          )
        ] as ReactNode,
      sortingField: `customattributes.${attribute?.slug}_label`,
      minWidth: 176,
    };
    const newVisiblePreferencesContent = {
      id: attribute?.slug,
      label: attribute?.uidisplay,
      editable: true,
    };

    const newFilteringProperty: PropertyFilterProps.FilteringProperty = {
      key: `customattributes.${attribute?.slug}_label`,
      operators: ["="],
      propertyLabel: `${attribute?.uidisplay}`,
      groupValuesLabel: `${attribute?.uidisplay} values`,
      group: "attributes",
    };

    if (!columnsUpdate.find((column) => column.id === newColumn.id)) {
      columnsUpdate.push(newColumn);
      visiblePreferencesUpdate.push(newVisiblePreferencesContent);
      filteringPropertiesUpdate.push(newFilteringProperty);
    }

    setTableColumns(columnsUpdate);
    setVisibleContent(visiblePreferencesUpdate);
    setFilteringProperties(filteringPropertiesUpdate);
  });
};
