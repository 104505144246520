import { useState, useEffect } from "react";
import { getEnvironmentVariables } from "../data/constants/environment";
import { ResponseDataType } from "../views/admin/Interface";
import { REQUEST_METHOD } from "../data/constants/common";
import { transformOpensearchResponse } from "../opensearch/opensearchQueryUtils";

export type ReturnType = {
    data: any;
    isLoading: boolean;
    serverError: Error | null;
    refresh: () => void;
};
type UseFetchParams = {
    body: BodyInit | null | undefined;
    getTokenFn: () => string;
    currentPage: number,
    pageSize: number
};


const BASE_API_ENDPOINT = `${getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT}`;

/** 
 * useFetchBusinessData hook utilizes TaCS's base api endpoint with the given
 * urlPath and method to fetch BusinessData
 * 
 * @param UseFetchParams
 */
export const useFetchOpensearchData = (
  {
    body,
    getTokenFn,
    currentPage,
    pageSize
  }: UseFetchParams
): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<ResponseDataType>(
    () => { return {} as ResponseDataType; }
  );
  const [serverError, setServerError] = useState<Error | null>(null);
  const [shouldRefetch, refetch] = useState({});
  const refresh = () => refetch({});

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response: Response = await fetch(BASE_API_ENDPOINT, {
          headers: {
            "Content-Type": "application/json",
            Authorization: await getTokenFn(),
          },
          method: REQUEST_METHOD.POST,
          body
        });
        const data = await response.json();
        const transformedData = transformOpensearchResponse(data, currentPage, pageSize);


        setData(transformedData);
        setIsLoading(false);
      } catch (error) {
        const err = error as Error;
        setServerError(err);
      }
    };

    fetchData();
  }, [body, shouldRefetch]);

  return { isLoading, data, serverError, refresh };
};
