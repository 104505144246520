import { DateTime } from "luxon";
import { SelectProps } from "@cloudscape-design/components";

const withEmojis = /\p{Extended_Pictographic}/gu;

export const validateDate = (date: string | null | undefined) => {
  if (date === null || date === undefined) return false;
  return DateTime.fromISO(date).isValid;
};

export const validateDateStart = (date: string) => {
  const dateMinimum = "2020/12/31 12:00:00 AM";
  return Date.parse(date) > Date.parse(dateMinimum);
};

export const validateNumber = (str: string | null | undefined): boolean => {
  if (!str) return false;

  const strInt = Number(str);
  if (isNaN(strInt)) return false;

  return true;
};

export const validateStringBetween = (
  str: string | null | undefined,
  min: number,
  max: number
): boolean => {
  if (str === null || str === undefined || str === "") return false;
  if (withEmojis.test(str)) return false;

  return str.length >= min && str.length <= max;
};

export const validateString = (str: string | null | undefined): boolean => {
  if (str === null || str === undefined || str === "") return false;
  if (withEmojis.test(str)) return false;

  return str.length > 0;
};

export const validateSelect = (
  selected: SelectProps.Option | undefined | null
): boolean => !!selected;
