import React, { FC, ReactElement, useContext, useState } from "react";
import {
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import {
  API_CALL_NAME,
  REQUEST_ID_SELECTOR,
} from "../../data/constants/common";
import TaCSIDSelector from "../../components/form/inputs/TaCSIDSelector";
import { getEnvironmentVariables } from "../../data/constants/environment";
import { AuthContext } from "../../context/AuthContext";
import { formatDate, getEpochTime } from "../../data/helpers/dates";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";

interface ICreateFlightPlanProp {
  businessUnitSlug: string;
  tacsId: string;
  name: string;
  startDate: string;
  endDate: string;
  flightplannotes?: string;
}

const FlightPlansForm: FC<object> = (): ReactElement | null => {
  const { state } = useLocation();
  const { getIdToken } = useContext(AuthContext);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const navigate = useNavigate();
  const [selector, setSelector] = useState(REQUEST_ID_SELECTOR.WITH_TACS_ID);
  const [campaignName, setCampaignName] = useState("");
  const [flightPlan, setFlightPlan] = useState<ICreateFlightPlanProp>(
    state
      ? {
        ...state,
        startDate: formatDate(state.startDate),
        endDate: formatDate(state.endDate),
      }
      : {
        businessUnitSlug: "",
        tacsId: "",
        name: "",
        startDate: "",
        endDate: "",
        flightplannotes: "",
      }
  );

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [invalidField, setInvalidField] = useState({
    businessUnitSlug: false,
    tacsId: false,
    name: false,
    startDate: false,
    endDate: false,
    flightplannotes: false,
  });
  const isEdit = state ? true : false;

  const validateForm = () => {
    let isValidated = true;
    //individual input error status
    Object.entries(flightPlan).forEach((obj) => {
      if (!obj[1] && obj[0] !== "flightplannotes") {
        setInvalidField((prevState) => ({
          ...prevState,
          [obj[0]]: true,
        }));
        isValidated = false;
      }
    });

    //form error status
    if (
      !flightPlan.businessUnitSlug ||
      !flightPlan.tacsId ||
      !flightPlan.name ||
      !flightPlan.startDate ||
      !flightPlan.endDate
    ) {
      isValidated = false;
    }

    if (!isValidated) {
      addMessage({
        type: "error",
        content: "Please fill out all required fields!",
        dismissible: true,
      });
      return false;
    }

    if (
      getEpochTime(flightPlan.startDate) >= getEpochTime(flightPlan.endDate)
    ) {
      setInvalidField((prevState) => ({
        ...prevState,
        startDate: true,
        endDate: true,
      }));
      addMessage({
        type: "error",
        content: "Start date is later than end date",
        dismissible: true,
      });
      return false;
    }
    return true;
  };

  const handleOnSuccessMessage = () => {
    setIsSubmitLoading(false);
    addMessage({
      type: "success",
      content: `Flight Plan ${isEdit ? "updated" : "created"} successfully!`,
      dismissible: true,
    });
  };

  const handleOnErrorMessage = () => {
    setIsSubmitLoading(false);
    addMessage({
      type: "error",
      content: `Flight Plan ${isEdit ? "update" : "create"} failed!`,
      dismissible: true,
    });
  };

  const createFlightPlan = async () => {
    const url = `${getEnvironmentVariables().API_ENDPOINT}/${
      API_CALL_NAME.FLIGHT_PLANS
    }/${flightPlan.businessUnitSlug}/${isEdit ? state.id : ""}`;
    try {
      const response = await fetch(url, {
        method: isEdit ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        body: JSON.stringify({
          ...flightPlan,
          startDate: getEpochTime(flightPlan.startDate),
          endDate: getEpochTime(flightPlan.endDate),
        }),
      });
      if (response.ok) {
        navigate("/flight-plans");
        handleOnSuccessMessage();
      } else {
        handleOnErrorMessage();
      }
    } catch (error) {
      handleOnErrorMessage();
    }
  };

  const handleOnClickSubmit = () => {
    if (!validateForm()) {
      return;
    }
    setIsSubmitLoading(true);
    createFlightPlan();
  };

  const handleOnClickCancel = () => {
    navigate(-1);
  };

  return (
    <ContentLayout
      header={
        <Header description="">
          {isEdit ? "Edit Flight Plan" : "Create a Flight Plan"}
        </Header>
      }
    >
      <form onSubmit={(event) => event.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="cancel-button"
                variant="link"
                onClick={() => handleOnClickCancel()}
              >
                Cancel
              </Button>
              <Button
                data-testid="submit-button"
                loading={isSubmitLoading}
                variant="primary"
                onClick={() => handleOnClickSubmit()}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="xl">
            <Container>
              <SpaceBetween size="l">
                <TaCSIDSelector
                  isEdit={isEdit}
                  selector={selector}
                  tacsID={flightPlan.tacsId}
                  campaignName={campaignName}
                  businessUnit={flightPlan.businessUnitSlug}
                  setSelector={setSelector}
                  setCampaignName={setCampaignName}
                  setTacsID={(tacsId) => {
                    setFlightPlan((prevState) => {
                      return {
                        ...prevState,
                        tacsId: tacsId,
                      };
                    });
                    setInvalidField({ ...invalidField, tacsId: false });
                  }}
                  setBusinessUnit={(businessUnitSlug) => {
                    setFlightPlan((prevState) => {
                      return {
                        ...prevState,
                        businessUnitSlug: businessUnitSlug,
                      };
                    });
                    setInvalidField({
                      ...invalidField,
                      businessUnitSlug: false,
                    });
                  }}
                  invalidField={invalidField}
                  setInvalidField={setInvalidField}
                />
                <FormField
                  label="Flight Plan name"
                  description="Choose a descriptive name for your Flight Plan to help you organize groups of Media Items"
                  errorText={
                    invalidField.name &&
                    "Please enter a name for the flight plan"
                  }
                  constraintText={
                    <>Character count: {flightPlan.name.length}/255</>
                  }
                >
                  <Input
                    data-testid="flight-plan-input"
                    value={flightPlan.name}
                    onChange={({ detail }) => {
                      setFlightPlan({ ...flightPlan, name: detail.value });
                      detail.value.length > 255
                        ? setInvalidField({ ...invalidField, name: true })
                        : setInvalidField({ ...invalidField, name: false });
                    }}
                    invalid={invalidField.name}
                  />
                </FormField>
                <ColumnLayout disableGutters columns={2}>
                  <FormField
                    label="Flight Plan start date"
                    errorText={
                      invalidField.startDate && "Please select the start date"
                    }
                  >
                    <DatePicker
                      data-testid="start-date-picker"
                      onChange={({ detail }) => {
                        setFlightPlan({
                          ...flightPlan,
                          startDate: detail.value,
                        });
                        setInvalidField({
                          ...invalidField,
                          startDate: false,
                          endDate: false,
                        });
                      }}
                      value={flightPlan.startDate}
                      previousMonthAriaLabel="Previous month"
                      nextMonthAriaLabel="Next month"
                      todayAriaLabel="Today"
                      placeholder="YYYY-MM-DD"
                      invalid={invalidField.startDate}
                      expandToViewport
                    />
                  </FormField>
                  <FormField
                    label="Flight Plan end date"
                    errorText={
                      invalidField.endDate && "Please select the end date"
                    }
                  >
                    <DatePicker
                      data-testid="end-date-picker"
                      onChange={({ detail }) => {
                        setFlightPlan({ ...flightPlan, endDate: detail.value });
                        setInvalidField({
                          ...invalidField,
                          startDate: false,
                          endDate: false,
                        });
                      }}
                      value={flightPlan.endDate}
                      previousMonthAriaLabel="Previous month"
                      nextMonthAriaLabel="Next month"
                      todayAriaLabel="Today"
                      placeholder="YYYY-MM-DD"
                      invalid={invalidField.endDate}
                      expandToViewport
                    />
                  </FormField>
                </ColumnLayout>
                <FormField
                  label="Flight Plan notes"
                  constraintText={
                    <>Character count:{
                      flightPlan.flightplannotes ? flightPlan.flightplannotes.length : 0
                    }/255
                    </>
                  }
                  errorText={
                    invalidField.flightplannotes &&
                    "Please restrict notes character count to 255"
                  }
                >
                  <Textarea
                    data-testid="notes-textarea"
                    onChange={({ detail }) => {
                      setFlightPlan({ ...flightPlan, flightplannotes: detail.value });
                      detail.value.length > 255
                        ? setInvalidField({ ...invalidField, flightplannotes: true })
                        : setInvalidField({ ...invalidField, flightplannotes: false });
                    }}
                    value={flightPlan.flightplannotes || ""}
                    placeholder="Freeform area to enter notes about the Flight Plan which anyone will be able to see - these can be changed later"
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};

export default FlightPlansForm;
