// Originates from Cloudscape UI page: https://cloudscape.design/components/key-value-pairs/

import React from "react";
import { Box } from "@cloudscape-design/components";
import { IKeyValueProps } from "../../data/interfaces/ICampaign";

const ValueWithLabel = ({ label, children }: IKeyValueProps) => {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );
};

export default ValueWithLabel;
