import React, { FC, ReactElement } from "react";
import { Routes, Route, RouteProps, Navigate } from "react-router-dom";
import CampaignDashboard from "../../views/campaign/CampaignDashboard";
import CampaignForm from "../../views/campaign/CampaignForm";
import CampaignView from "../../views/campaign/CampaignView";
import BulkDashboard from "../../views/bulk/BulkDashboard";
import RequestIDsView from "../../views/media-items/RequestIDsView";
import FlightPlansDashboard from "../../views/flight-plans/FlightPlansDashboard";
import FlightPlansForm from "../../views/flight-plans/FlightPlansForm";
import { BUSINESS_ROUTE_PATHS } from "./common";
import { BusinessUnitsDashboard } from "../../views/admin/business-management/BusinessUnitsDashboard";
import { BusinessGroupsDashboard } from "../../views/admin/business-management/BusinessGroupsDashboard";
import { ManageAttributesDashboard } from "../../views/admin/attribute-management/BusinessLogicDashboard";
import { CreateAttributeContainer } from "../../views/admin/attribute-management/AttributesForm";
import { CreateBusinessUnitContainer } from "../../views/admin/business-management/BusinessUnitsForm";
import { CreateBusinessGroupContainer } from "../../views/admin/business-management/BusinessGroupsForm";
import { MediaItemsDashboard } from "../../views/media-items/MediaItemsDashboard";

const routes = [
  /** HOME */
  {
    path: "/",
    element: <Navigate replace to="/campaigns" />,
    exact: true,
  },
  /** CAMPAIGN */
  {
    path: "/campaigns",
    element: <CampaignDashboard />,
    exact: true,
  },
  {
    path: "/campaigns/create",
    element: <CampaignForm />,
    exact: true,
  },
  {
    path: "/campaigns/:businessunitSlug/:tacsid",
    element: <CampaignView />,
    exact: true,
  },
  {
    path: "/campaigns/:businessunitSlug/:tacsid/:action",
    element: <CampaignForm />,
    exact: true,
  },
  /** INVESTMENT OWNER */
  {
    path: "/investmentowner",
    exact: true,
  },
  {
    path: "/investmentowner/:ownerId",
    exact: true,
  },
  /** BULK UPLOAD */
  {
    path: "/bulk",
    element: <BulkDashboard />,
    exact: true,
  },
  /** MEDIA ITEMS */
  {
    path: BUSINESS_ROUTE_PATHS.MEDIA_ITEMS,
    element: <MediaItemsDashboard />,
    exact: true,
  },
  {
    path: "/media-items/request-ids",
    element: <RequestIDsView />,
    exact: true,
  },
  {
    path: "flight-plans",
    element: <FlightPlansDashboard />,
    exact: true,
  },
  {
    path: "flight-plans/create",
    element: <FlightPlansForm />,
    exact: true,
  },
  {
    path: "flight-plans/edit",
    element: <FlightPlansForm />,
    exact: true,
  },
  /** ADMINISTRATION ITEMS */
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS}`,
    element: <BusinessGroupsDashboard />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS}${BUSINESS_ROUTE_PATHS.CREATE}`,
    element: <CreateBusinessGroupContainer />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS}${BUSINESS_ROUTE_PATHS.EDIT}`,
    element: <CreateBusinessGroupContainer />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_UNITS}`,
    element: <BusinessUnitsDashboard />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_UNITS}${BUSINESS_ROUTE_PATHS.CREATE}`,
    element: <CreateBusinessUnitContainer />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_UNITS}${BUSINESS_ROUTE_PATHS.EDIT}`,
    element: <CreateBusinessUnitContainer />,
    exact: true,
  },
  {
    path: BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC,
    element: <ManageAttributesDashboard />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC}${BUSINESS_ROUTE_PATHS.CREATE}`,
    element: <CreateAttributeContainer />,
    exact: true,
  },
  {
    path: `${BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC}${BUSINESS_ROUTE_PATHS.EDIT}`,
    element: <CreateAttributeContainer />,
    exact: true,
  },
];

const AppRoutes: FC = (): ReactElement => {
  return (
    <Routes>
      {routes.map((routeProps: RouteProps, index: number) => (
        <Route {...routeProps} key={index} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
