import React, { FC, ReactElement, useEffect } from "react";
import { FormField, Input } from "@cloudscape-design/components";

export interface IDynamicIDInputProps {
  packageCount: number;
  idCount: string[];
  setIdCount: (value: string[]) => void;
  invalidCount: boolean[];
  setInvalidCount: (value: boolean[]) => void;
}

const DynamicIDInput: FC<IDynamicIDInputProps> = (
  props: IDynamicIDInputProps
): ReactElement | null => {
  const {
    packageCount: count,
    idCount,
    setIdCount,
    invalidCount,
    setInvalidCount,
  } = props;

  useEffect(() => {
    setIdCount(new Array(count).fill("1"));
    setInvalidCount(new Array(count).fill(false));
  }, [count]);

  const handleOnChangeNumericInput = (value: string, index: number) => {
    const intValue = parseInt(value);
    if ((isNaN(intValue) && value !== "") || intValue < 1 || intValue > 500) {
      return;
    }
    handleIDCountChange(value !== "" ? intValue.toString() : "", index);
  };

  const handleIDCountChange = (value: string, index: number) => {
    const newIdCount = idCount.map((c, i) => {
      if (i === index) {
        return value;
      } else {
        return c;
      }
    });
    const newInvalidCount = invalidCount.map((_, i) => {
      if (i === index && value === "") {
        return true;
      } else {
        return false;
      }
    });
    setIdCount(newIdCount);
    setInvalidCount(newInvalidCount);
  };

  return (
    <>
      {Array.from(Array(count)).map((_, index) => (
        <FormField
          label={`Number of children required for Package ${index + 1}`}
          description=""
          key={index}
          errorText={
            invalidCount[index] && "Please enter at least 1 ID"
          }
        >
          <Input
            invalid={invalidCount[index]}
            data-testid={`dynamic-id-input-${index + 1}`}
            onChange={({ detail }) =>
              handleOnChangeNumericInput(detail.value, index)
            }
            value={idCount[index]}
            inputMode="numeric"
          />
        </FormField>
      ))}
    </>
  );
};

export default DynamicIDInput;
