import React from "react";
import { Input, PropertyFilterProps } from "@cloudscape-design/components";
import { IFlightPlan } from "../../data/interfaces/ICampaign";
import { formatDate } from "../../data/helpers/dates";
import { DateForm } from "../../components/table/DateForm";
import { Link } from "react-router-dom";
import { UNAUTHORIZED_WRITE_ACCESS } from "../../data/constants/common";

export const COLUMN_ID = {
  ASSOCIATED_TACSID: "tacsId",
  BUSINESS_UNIT_SLUG: "businessunitSlug",
  BUSINESS_UNIT: "businessUnitName",
  CAMPAIGN_END_DATE_PLANNED: "campaignenddateplanned",
  CAMPAIGN_MARKETPLACE: "campaignmarketplace",
  CAMPAIGN_NAME: "campaignname",
  CAMPAIGN_START_DATE_PLANNED: "campaignstartdateplanned",
  END_DATE: "endDate",
  FLIGHT_PLAN_ID: "id",
  FLIGHT_PLAN_NAME: "name",
  NOTES: "flightplannotes",
  START_DATE: "startDate",
  FLIGHT_PLAN_3P_ID: "flightplan3pid",
  CREATED_BY: "createdBy",
  CREATION_DATE: "creationDate",
  LAST_UPDATED_BY: "lastUpdatedBy",
  LAST_UPDATED_DATE: "lastUpdatedDate",
  MEDIA_ITEMS: "mediaitems",
};

export const RAW_COLUMNS = [
  {
    id: COLUMN_ID.FLIGHT_PLAN_ID,
    header: "Flight Plan ID",
    cell: (e: IFlightPlan) => e.id,
    sortingField: COLUMN_ID.FLIGHT_PLAN_ID,
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.FLIGHT_PLAN_NAME,
    header: "Flight Plan name",
    cell: (e: IFlightPlan) => e.name,
    sortingField: COLUMN_ID.FLIGHT_PLAN_NAME,
    editConfig: {
      editingCell: (
        e: any,
        {
          currentValue,
          setValue,
        }: { currentValue: string; setValue: (value: string) => void }
      ) => {
        return e.accessLevel !== "rw"
          ? <span style={{color: "red"}}>{UNAUTHORIZED_WRITE_ACCESS}</span>
          : (
            <Input
              autoFocus={true}
              value={currentValue ?? e.name}
              onChange={(event) => setValue(event.detail.value)}
            />
          );
      },
    },
    width: "240px",
    maxWidth: "500px",
  },
  {
    id: COLUMN_ID.ASSOCIATED_TACSID,
    header: "Associated TaCS ID",
    cell: (e: IFlightPlan) => (
      <Link to={`/campaigns/${e.businessUnitSlug}/${e.tacsId}`}>
        {e.tacsId}
      </Link>
    ),
    sortingField: COLUMN_ID.ASSOCIATED_TACSID,
  },
  {
    id: COLUMN_ID.MEDIA_ITEMS,
    header: "Media Items",
    cell: (e: IFlightPlan) => {
      const propertyKey = "flightPlanId";
      const value = e.id;

      const url = new URL(`${window.location.origin}/media-items`);
      const params = new URLSearchParams(url.search);
      params.append(propertyKey, value);

      return (
        <Link
          to={url.pathname + "?" + params.toString()}
        >
          View Media Items
        </Link>
      );
    },
    sortingField: COLUMN_ID.FLIGHT_PLAN_ID,
  },
  {
    id: COLUMN_ID.CAMPAIGN_NAME,
    header: "Campaign Name",
    cell: (e: IFlightPlan) => e.campaignname,
    minWidth: "130px",
    sortingField: COLUMN_ID.CAMPAIGN_NAME,
  },
  {
    id: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    header: "Marketplace",
    cell: (e: IFlightPlan) => e.campaignmarketplace,
    minWidth: "130px",
  },

  {
    id: COLUMN_ID.START_DATE,
    header: "Start date",
    cell: (e: IFlightPlan) => formatDate(e.startDate),
    sortingField: COLUMN_ID.START_DATE,
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.END_DATE,
    header: "End date",
    cell: (e: IFlightPlan) => formatDate(e.endDate),
    sortingField: COLUMN_ID.END_DATE,
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.CAMPAIGN_START_DATE_PLANNED,
    header: "Campaign Start Date",
    cell: (e: IFlightPlan) => formatDate(e.campaignstartdateplanned),
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.CAMPAIGN_END_DATE_PLANNED,
    header: "Campaign End Date",
    cell: (e: IFlightPlan) => formatDate(e.campaignenddateplanned),
    minWidth: "130px",
  },
  {
    id: COLUMN_ID.CREATION_DATE,
    header: "Creation date",
    cell: (e: IFlightPlan) => formatDate(e.metadata?.creationDate),
    sortingField: COLUMN_ID.CREATION_DATE,
    minWidth: "155px",
  },
  {
    id: COLUMN_ID.CREATED_BY,
    header: "Created by",
    cell: (e: IFlightPlan) => e.metadata.createdBy,
    sortingField: COLUMN_ID.CREATED_BY,
    minWidth: "135px",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_DATE,
    header: "Last updated date",
    cell: (e: IFlightPlan) =>
      e.metadata?.lastUpdatedDate
        ? formatDate(e.metadata?.lastUpdatedDate)
        : formatDate(e.metadata?.creationDate),
    sortingField: COLUMN_ID.LAST_UPDATED_DATE,
    minWidth: "175px",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_BY,
    header: "Last updated by",
    cell: (e: IFlightPlan) => e.metadata.lastUpdatedBy || e.metadata.createdBy,
    sortingField: COLUMN_ID.LAST_UPDATED_BY,
    minWidth: "175px",
  },
  {
    id: COLUMN_ID.BUSINESS_UNIT,
    header: "Business unit",
    cell: (e: IFlightPlan) => e.businessUnitName,
    sortingField: COLUMN_ID.BUSINESS_UNIT,
    minWidth: "160px",
  },
  {
    id: COLUMN_ID.NOTES,
    header: "Notes",
    cell: (e: IFlightPlan) => e.flightplannotes,
    minWidth: "130px",
    maxWidth: "420px",
  },
  {
    id: COLUMN_ID.FLIGHT_PLAN_3P_ID,
    header: "Third Party ID",
    cell: (e: IFlightPlan) => e.flightplan3pid,
    minWidth: "130px",
    maxWidth: "420px",
  },
];

export const TABLE_VISIBLE_COLUMNS = [
  COLUMN_ID.FLIGHT_PLAN_ID,
  COLUMN_ID.FLIGHT_PLAN_NAME,
  COLUMN_ID.ASSOCIATED_TACSID,
  COLUMN_ID.MEDIA_ITEMS,
  COLUMN_ID.CAMPAIGN_NAME,
  COLUMN_ID.CAMPAIGN_MARKETPLACE,
  COLUMN_ID.START_DATE,
  COLUMN_ID.END_DATE,
  COLUMN_ID.FLIGHT_PLAN_3P_ID,
  COLUMN_ID.CAMPAIGN_START_DATE_PLANNED,
  COLUMN_ID.CAMPAIGN_END_DATE_PLANNED,
  COLUMN_ID.NOTES,
  COLUMN_ID.LAST_UPDATED_DATE,
  COLUMN_ID.CREATION_DATE,
  COLUMN_ID.CREATED_BY,
  COLUMN_ID.LAST_UPDATED_BY,
  COLUMN_ID.BUSINESS_UNIT,
];

export const PREFERENCES_VISIBLE_COLUMNS = [
  COLUMN_ID.FLIGHT_PLAN_NAME,
  COLUMN_ID.ASSOCIATED_TACSID,
  COLUMN_ID.MEDIA_ITEMS,
  COLUMN_ID.START_DATE,
  COLUMN_ID.END_DATE,
  COLUMN_ID.CREATED_BY,
  COLUMN_ID.CREATION_DATE,
  COLUMN_ID.LAST_UPDATED_BY,
  COLUMN_ID.LAST_UPDATED_DATE,
];

export const PREFERENCES_VISIBLE_CONTENT = [
  {
    id: COLUMN_ID.FLIGHT_PLAN_ID,
    label: "Flight Plan ID",
  },
  {
    id: COLUMN_ID.FLIGHT_PLAN_NAME,
    label: "Flight Plan name",
  },
  {
    id: COLUMN_ID.ASSOCIATED_TACSID,
    label: "Associated TaCS ID",
  },
  {
    id: COLUMN_ID.MEDIA_ITEMS,
    label: "Media Items",
  },
  {
    id: COLUMN_ID.CAMPAIGN_NAME,
    label: "Associated Campaign Name",
  },
  {
    id: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    label: "Marketplace",
  },
  {
    id: COLUMN_ID.START_DATE,
    label: "Start date",
  },
  {
    id: COLUMN_ID.END_DATE,
    label: "End date",
  },

  {
    id: COLUMN_ID.CAMPAIGN_START_DATE_PLANNED,
    label: "Campaign Start Date",
  },
  {
    id: COLUMN_ID.CAMPAIGN_END_DATE_PLANNED,
    label: "Campaing End Date",
  },
  {
    id: COLUMN_ID.CREATION_DATE,
    label: "Created on",
  },
  {
    id: COLUMN_ID.CREATED_BY,
    label: "Created by",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_DATE,
    label: "Last updated date",
  },
  {
    id: COLUMN_ID.LAST_UPDATED_BY,
    label: "Last updated by",
  },
  {
    id: COLUMN_ID.BUSINESS_UNIT,
    label: "Business unit",
  },
  {
    id: COLUMN_ID.NOTES,
    label: "Notes",
  },
  {
    id: COLUMN_ID.FLIGHT_PLAN_3P_ID,
    label: "Third Party ID",
  },
];

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: COLUMN_ID.FLIGHT_PLAN_ID,
    operators: ["="],
    propertyLabel: "Flight Plan ID",
    groupValuesLabel: "Flight Plan ID values",
  },
  {
    key: COLUMN_ID.ASSOCIATED_TACSID,
    operators: ["="],
    propertyLabel: "Associated TaCS ID",
    groupValuesLabel: "Associated TaCS ID values",
  },
  {
    key: COLUMN_ID.BUSINESS_UNIT,
    operators: ["="],
    propertyLabel: "Business unit",
    groupValuesLabel: "Business unit values",
  },
  {
    key: COLUMN_ID.CREATED_BY,
    operators: ["="],
    propertyLabel: "Created by",
    groupValuesLabel: "Created by values",
  },
  {
    key: COLUMN_ID.FLIGHT_PLAN_NAME,
    operators: ["=", ":"],
    propertyLabel: "Flight Plan name",
    groupValuesLabel: "Flight Plan name values",
  },
  {
    key: COLUMN_ID.CAMPAIGN_NAME,
    operators: ["=", ":"],
    propertyLabel: "Campaign name",
    groupValuesLabel: "Campaign name values",
  },
  {
    key: COLUMN_ID.CAMPAIGN_MARKETPLACE,
    operators: ["="],
    propertyLabel: "Marketplace",
    groupValuesLabel: "Marketplace values",
  },
  {
    key: COLUMN_ID.START_DATE,
    propertyLabel: "Flight Plan Start Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: COLUMN_ID.END_DATE,
    propertyLabel: "Flight Plan End Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: COLUMN_ID.CAMPAIGN_START_DATE_PLANNED,
    propertyLabel: "Campaign Start Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: COLUMN_ID.CAMPAIGN_END_DATE_PLANNED,
    propertyLabel: "Campaign End Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
];
