import React from "react";
import { Auth } from "aws-amplify";
import { createContext, useLayoutEffect, useState } from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  IBusinessGroupsObject,
  IBusinessGroup,
} from "../data/interfaces/IUser";
import { getEnvironmentVariables } from "../data/constants/environment";
import { SelectedBGLocalStorage } from "../data/constants/localStorage";
import { updatePinpointEndpoint } from "../configurePinpoint";
import styles from "./Context.module.css";
import ScreenLoader from "../components/common/ScreenLoader";
import { ENTITY_TYPE } from "../data/constants/common";

export type UserAttributes = {
  givenName: string;
  familyName: string;
  email: string;
  isAdmin: boolean;
};

export type AuthContextType = {
  userAttributes: UserAttributes;
  userBusinessGroupMap: IBusinessGroupsObject | null;
  getIdToken: any;
  setSelectedBG: any;
  selectedBG: IBusinessGroup | null;
  customCampaignAttributes: any;
  customFlighPlanAttributes: any;
  customMediaItemAttributes: any;
  hasReadOnlyAccess: any;
  logout: any;
  isAllowedAccessToBU: any;
  findBusinessGroup: any;
  updateCurrentSelectedBG: any;
  activeHref: string;
  isExternal: boolean;
  setActiveHref: React.Dispatch<React.SetStateAction<string>>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthContextProvider = (props: any) => {
  const [sessionTokens, setSessionTokens] = useState<CognitoUserSession>();
  const [givenName, setGivenName] = useState<string>("");
  const [familyName, setFamilyName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userBusinessGroupMap, setUserBusinessGroupMap] =
    useState<IBusinessGroupsObject | null>(null);
  const [selectedBG, setSelectedBG] = useState<IBusinessGroup | null>(null);
  const [customCampaignAttributes, setCustomCampaignAttributes] =
    useState<any>(null);
  const [customFlighPlanAttributes, setCustomFlightPlanAttributes] =
    useState<any>(null);
  const [customMediaItemAttributes, setCustomMediaItemAttributes] =
    useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [activeHref, setActiveHref] = useState("/campaigns");


  useLayoutEffect(() => {
    const getAuth = async () => {
      await Auth.currentSession()
        .then((session) => {
          const adminAttributeName = "custom:ADMIN_POSIX";
          // this attribute will be missing in payload if user is not admin
          // "custom:ADMIN_POSIX": "mode-engr"
          const isAdmin = adminAttributeName in session.getIdToken().payload;
          setSessionTokens(session);
          setIsAdmin(isAdmin);
          setGivenName(session.getIdToken().payload["given_name"]);
          setFamilyName(session.getIdToken().payload["family_name"]);
          setEmail(session.getIdToken().payload["email"]);

          getUserProfile();

        })
        .catch(() => {
          Auth.federatedSignIn();
        });
    };

    getAuth();
  }, []);

  const getUserProfile = async () => {

    const url = `${getEnvironmentVariables().API_ENDPOINT}/user/profile`;

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await getIdToken(),
      }
    });

    const data = await response.json();
    setIsExternal(data?.isExternal);
    setUserBusinessGroupMap(data);

    const storedSelectedBG = localStorage.getItem(
      SelectedBGLocalStorage.SELECTED_BG_NAME
    );

    if (
      storedSelectedBG === "null" ||
      storedSelectedBG === null ||
      storedSelectedBG === "undefined"
    ) {
      const defaultBG =
        data?.businessGroups[Object.keys(data?.businessGroups)[0]];
      setSelectedBG(defaultBG);


      localStorage.setItem(
        SelectedBGLocalStorage.SELECTED_BG_NAME,
        JSON.stringify(defaultBG)
      );
    } else {
      setSelectedBG(JSON.parse(storedSelectedBG) as IBusinessGroup);
    }

    setLoading(false);
  };

  const getCustomAttributes = async () => {
    const results = await Promise.all(
      Object.values(ENTITY_TYPE).map(async (entity) => {
        if (entity === ENTITY_TYPE.MEDIA_ID_REQUEST) {
          return {};
        }

        const url = `${getEnvironmentVariables().API_ENDPOINT}/businessgroup/${
          selectedBG?.businessGroupSlug
        }/attributes?entity=${entity}`;

        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: await getIdToken(),
          },
          method: "GET",
        });

        return { [entity]: await response.json() };
      })
    );

    const combinedAttributes = results.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    return combinedAttributes;
  };

  useLayoutEffect(() => {
    const setCustomAttributes = async () => {
      const customAttributes = await getCustomAttributes();
      setCustomCampaignAttributes(customAttributes[ENTITY_TYPE.CAMPAIGNS]);
      setCustomFlightPlanAttributes(customAttributes[ENTITY_TYPE.FLIGHT_PLANS]);
      setCustomMediaItemAttributes(customAttributes[ENTITY_TYPE.MEDIA_ITEMS]);
    };

    if (selectedBG) {
      setCustomAttributes();
    }

    setActiveHref(window.location.pathname);

  }, [selectedBG]);

  const getIdToken = async (): Promise<string> => {
    let idToken = "";

    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        idToken = user.signInUserSession.idToken.jwtToken;
      });
    } catch (ex) {
      // This had to be added because Amplify doesn't catch the exception, this means
      // that the user is not logged in, there is other code that redirects to login page.
    }

    return idToken;
  };

  const logout = () => {
    localStorage.clear();
    Auth.signOut();
  };

  const hasReadOnlyAccess = (currentBUSlug: string) => {
    if (selectedBG && currentBUSlug) {
      return selectedBG?.businessUnits[currentBUSlug].isAccessReadOnly;
    }

    return false;
  };

  // Checks if user has access to the business unit based on their BG->BU map
  const isAllowedAccessToBU = (
    businessUnitSlug: string,
    userProfileMap: any
  ) => {
    const businessGroups = userProfileMap?.businessGroups;

    for (const businessGroupKey in businessGroups) {
      const businessGroup = businessGroups[businessGroupKey];
      const businessUnits = businessGroup.businessUnits;

      if (
        Object.prototype.hasOwnProperty.call(businessUnits, businessUnitSlug)
      ) {
        return true;
      }
    }

    return false;
  };

  // Given a business unit, do reverse look up to find the associated business group
  const findBusinessGroup = (businessUnitSlug: string, userProfileMap: any) => {
    const businessGroups = userProfileMap?.businessGroups;

    for (const businessGroupKey in businessGroups) {
      const businessGroup = businessGroups[businessGroupKey];
      const businessUnits = businessGroup.businessUnits;

      if (
        Object.prototype.hasOwnProperty.call(businessUnits, businessUnitSlug)
      ) {
        return businessGroup.businessGroupSlug;
      }
    }

    return null;
  };

  const updateCurrentSelectedBG = (
    businessUnitSlug: string,
    userProfileMap: any
  ) => {
    const currentBG = findBusinessGroup(businessUnitSlug, userProfileMap);
    if (currentBG != selectedBG?.businessGroupSlug) {
      const selected = userProfileMap?.businessGroups[currentBG];

      setSelectedBG(selected || null);
      localStorage.setItem(
        SelectedBGLocalStorage.SELECTED_BG_NAME,
        JSON.stringify(selected)
      );
    }
  };

  const contextValue = {
    userAttributes: {
      givenName: givenName,
      familyName: familyName,
      email: email,
      isAdmin: isAdmin,
    },
    userBusinessGroupMap: userBusinessGroupMap,
    getIdToken: getIdToken,
    setSelectedBG: setSelectedBG,
    selectedBG: selectedBG,
    customCampaignAttributes: customCampaignAttributes,
    customFlighPlanAttributes: customFlighPlanAttributes,
    customMediaItemAttributes: customMediaItemAttributes,
    hasReadOnlyAccess: hasReadOnlyAccess,
    logout: logout,
    isAllowedAccessToBU: isAllowedAccessToBU,
    findBusinessGroup: findBusinessGroup,
    updateCurrentSelectedBG: updateCurrentSelectedBG,
    isExternal: isExternal,
    activeHref: activeHref,
    setActiveHref,
  };

  updatePinpointEndpoint(email, isAdmin);

  return (
    <AuthContext.Provider value={contextValue}>
      {loading === true ? (
        <div>
          <ScreenLoader />
        </div>
      ) : (
        <div>
          {userBusinessGroupMap &&
          Object.keys(userBusinessGroupMap?.businessGroups).length > 0 ? (
              sessionTokens ? (
                props.children
              ) : null
            ) : (
              <div className={styles.noAuth}>
                <h1>
                You don&apos;t have access to TaCS tool, please submit a ticket
                if you would like access:{" "}
                  <a href="https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/FAQs/Access/">
                  https://w.amazon.com/bin/view/MODE/Data_Technology/Products/TaCS/FAQs/Access/
                  </a>
                </h1>
              </div>
            )}
        </div>
      )}
    </AuthContext.Provider>
  );
};
