export const FixedPlannedSpendInputs = [
  {
    name: "campaigninfluencersfixed",
    label: "Influencers Fixed Planned Spend",
    spendChannel: "Influencers",
    testid: "campaigninfluencersfixed",
    infoDescription: "Initial fixed planned spend for the Influencers channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaigncinemafixed",
    label: "Cinema Fixed Planned Spend",
    spendChannel: "Cinema",
    testid: "campaigncinemafixed",
    infoDescription: "Initial fixed planned spend for the Cinema channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaigndigitalfixed",
    label: "Digital Fixed Planned Spend",
    spendChannel: "Digital",
    testid: "campaigndigitalfixed",
    infoDescription: "Initial fixed planned spend for the Digital channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignsocialfixed",
    label: "Social Fixed Planned Spend",
    spendChannel: "Social",
    testid: "campaignsocialfixed",
    infoDescription: "Initial fixed planned spend for the Social channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignoohfixed",
    label: "OOH Fixed Planned Spend",
    spendChannel: "OOH",
    testid: "campaignoohfixed",
    infoDescription: "Initial fixed planned spend for the OOH channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignprintfixed",
    label: "Print Fixed Planned Spend",
    spendChannel: "Print",
    testid: "campaignprintfixed",
    infoDescription: "Initial fixed planned spend for the Print channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignradiofixed",
    label: "Radio Fixed Planned Spend",
    spendChannel: "Radio",
    testid: "campaignradiofixed",
    infoDescription: "Initial fixed planned spend for the Radio channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaigntvfixed",
    label: "TV Fixed Planned Spend",
    spendChannel: "TV",
    testid: "campaigntvfixed",
    infoDescription: "Initial fixed planned spend for the TV channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignotherfixed",
    label: "Other Fixed Planned Spend",
    spendChannel: "Other",
    testid: "campaignotherfixed",
    infoDescription: "Initial fixed planned spend for the 'Other Media' channel.",
    isRequired: false,
    isDisabled: false,
  },
];

export const VariablePlannedSpendInputs = [
  {
    name: "campaigndigitalvariable",
    label: "Digital Variable Planned Spend",
    spendChannel: "Digital",
    testid: "campaigndigitalvariable",
    infoDescription: "Initial variable planned spend for the Digital channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaigntvvariable",
    label: "TV Variable Planned Spend",
    spendChannel: "TV",
    testid: "campaigntvvariable",
    infoDescription: "Initial variable planned spend for the TV channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignsocialvariable",
    label: "Social Variable Planned Spend",
    spendChannel: "Social",
    testid: "campaignsocialvariable",
    infoDescription: "Initial variable planned spend for the Social channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignassociatesvariable",
    label: "Associates Variable Planned Spend",
    spendChannel: "Associates",
    testid: "campaignassociatesvariable",
    infoDescription: "Initial varaible planned spend for the Associates channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignmailvariable",
    label: "Mail Variable Planned Spend",
    spendChannel: "Mail",
    testid: "campaignmailvariable",
    infoDescription: "Initial variable planned spend for the Mail channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignmobilevariable",
    label: "Mobile Variable Planned Spend",
    spendChannel: "Mobile",
    testid: "campaignmobilevariable",
    infoDescription: "Initial variable planned spend for the Mobile channel.",
    isRequired: false,
    isDisabled: false,
  },
  {
    name: "campaignsearchvariable",
    label: "Search Variable Planned Spend",
    spendChannel: "Search",
    testid: "campaignsearchvariable",
    infoDescription: "Initial variable planned spend for the Search channel.",
    isRequired: false,
    isDisabled: false,
  },
];
