import React from "react";
import { Box } from "@cloudscape-design/components";

const NewFeatureBox = ({ featureLaunchDate }: { featureLaunchDate: Date }) => {
  const diff = Math.floor(new Date().getTime() - featureLaunchDate.getTime());
  const day = 1000 * 60 * 60 * 24;

  return Math.floor(diff / day) < 30 ? (
    <Box
      data-testid="new-feature-box"
      variant="span"
      color="text-status-info"
      fontSize="body-s"
      fontWeight="bold"
    >
      New
    </Box>
  ) : (
    <></>
  );
};

export default NewFeatureBox;
