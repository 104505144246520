import React, { FC, ReactElement, useContext } from "react";

import {
  Box,
  CollectionPreferences,
  CollectionPreferencesProps,
  NonCancelableCustomEvent,
  PropertyFilterProps,
  Table,
  TableProps,
} from "@cloudscape-design/components";

import { AuthContext } from "../../context/AuthContext";
import { IPaginated } from "../../data/interfaces/ICampaign";
import CloudscapeTablePropertyFilter from "./CloudscapeTablePropertyFilter";
import CloudscapeTablePagination from "./Pagination";

export interface IBulkJobsCloudscapeTableProps
  extends Omit<
    TableProps,
    | "items"
    | "loading"
    | "selectedItems"
    | "filter"
    | "pagination"
    | "visibleColumns"
    | "wrapLines"
    | "preferences"
    | "onSortingChange"
    | "selectionType"
  > {
  currentPage: number;
  filteringProperties: PropertyFilterProps.FilteringProperty[];
  isLoading: boolean;
  items: IPaginated;
  preferences: CollectionPreferencesProps.Preferences;
  resourceName: string;
  resourceNamePlural: string;
  searchQuery: PropertyFilterProps.Query;
  selectionType?: TableProps.SelectionType | undefined;
  setCurrentPage: (page: number) => void;
  setPreferences: (preferences: CollectionPreferencesProps.Preferences) => void;
  setSearchQuery: (query: PropertyFilterProps.Query) => void;
  visibleContentOptions: CollectionPreferencesProps.VisibleContentOption[];
  tabs: JSX.Element;
}

const BulkJobCloudscapeTable: FC<IBulkJobsCloudscapeTableProps> = (
  props: IBulkJobsCloudscapeTableProps
): ReactElement | null => {
  const {
    columnDefinitions,
    currentPage,
    filteringProperties,
    isLoading,
    items,
    preferences,
    resourceName,
    searchQuery,
    setCurrentPage,
    setPreferences,
    setSearchQuery,
    visibleContentOptions,
    tabs,
    ...tableProps
  } = props;
  const { selectedBG } = useContext(AuthContext);

  const handleSearchChange = (query: PropertyFilterProps.Query) => {
    setSearchQuery({
      ...searchQuery,
      tokens: [...query.tokens],
    });
    setCurrentPage(1);
  };

  const handleSavePreferences = (
    event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>
  ): void => {
    const { detail } = event;
    setPreferences(detail);
    setCurrentPage(1);

    const currentPreferences: string | null = window.localStorage.getItem(
      `${resourceName}Table`
    );

    let parsedPreferences =
      currentPreferences !== null && currentPreferences !== undefined
        ? JSON.parse(currentPreferences)
        : {};

    const currBusinessGroup: any = selectedBG?.businessGroupSlug;

    if (Object.keys(parsedPreferences).length === 0) {
      parsedPreferences = {
        [currBusinessGroup]: detail,
      };
    }
    
    if (currBusinessGroup) {
      parsedPreferences[currBusinessGroup] = detail;
    }

    localStorage.setItem(
      `${resourceName}Table`,
      JSON.stringify(parsedPreferences)
    );
  };

  return (
    <div>
      <Table
        data-testid={`${resourceName}-bulk-job-cloudscape-table`}
        variant="full-page"
        sortingDisabled
        {...tableProps}
        filter={
          <>
            <CloudscapeTablePropertyFilter
              handleSearchChange={handleSearchChange}
              items={items}
              searchQuery={searchQuery}
              filteringProperties={filteringProperties}
            />
            {tabs}
          </>
        }
        items={items?.items}
        loading={isLoading}
        pagination={
          <CloudscapeTablePagination
            items={items}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        }
        preferences={
          <CollectionPreferences
            title="Display Preferences"
            data-testid="collection-preferences"
            confirmLabel="Apply and Save Settings"
            cancelLabel="Cancel"
            onConfirm={handleSavePreferences}
            preferences={preferences}
            pageSizePreference={{
              title: "Items per page",
              options: [
                { value: 5, label: "5" },
                { value: 10, label: "10" },
                { value: 25, label: "25" },
                { value: 50, label: "50" },
              ],
            }}
            visibleContentPreference={{
              title: "Select visible columns",
              options: [
                {
                  label: "",
                  options: visibleContentOptions,
                },
              ],
            }}
          />
        }
        visibleColumns={preferences.visibleContent}
        wrapLines={preferences.wrapLines}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No jobs</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No jobs to display.
            </Box>
          </Box>
        }
        columnDefinitions={columnDefinitions}
      />
    </div>
  );
};

export default BulkJobCloudscapeTable;
