import { getEnvironmentVariables } from "../data/constants/environment";
import { Attribute } from "../views/admin/Interface";
import { API_CALL_NAME, CUSTOM_ATTRIBUTE_BULK_PREFIX, ENTITY_TYPE, REQUEST_METHOD } from "../data/constants/common";
import { buildBasicSearchQuery } from "../opensearch/opensearchQueryUtils";
import { BULKJOB_HEADERS } from "../data/config/commonDashboardConfig";
import { IBusinessGroup } from "../data/interfaces/IUser";
import { IPropertyFilterProps } from "../components/table/CloudscapeTablePropertyFilter";
import { generateTemplateDownloadCSVName } from "../data/helpers/utils";


const BASE_API_ENDPOINT = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;


export const getRequestBodyForBulkExport = (
  entityType: string,
  customAttributes: any,
  searchFilter: IPropertyFilterProps,
  bgSlug?: string
) => {

  const {
    searchQuery,
    items: { page: { totalElements, number } },
  } = searchFilter;

  const getQueryForEntity = (entityType: string) => {
    const searchEntityQuery = buildBasicSearchQuery(
      searchQuery,
      totalElements,
      number,
      entityType,
      bgSlug,
    );

    return searchEntityQuery;
  };
  const requestBody = {
    csvHeaders: [] as string[],
    index: "",
    query: {}
  };

  switch (entityType) {
  case API_CALL_NAME.CAMPAIGNS:
    requestBody.index = API_CALL_NAME.CAMPAIGNS;
    requestBody.csvHeaders = Object.keys(BULKJOB_HEADERS[ENTITY_TYPE.CAMPAIGNS]);
    customAttributes?.attributes.map((attribute: Attribute) => {
      requestBody.csvHeaders.push(CUSTOM_ATTRIBUTE_BULK_PREFIX + attribute?.slug);
    });
    requestBody.query = getQueryForEntity(ENTITY_TYPE.CAMPAIGNS);
    break;
  case API_CALL_NAME.FLIGHT_PLANS:
    requestBody.index = API_CALL_NAME.FLIGHT_PLANS;
    requestBody.csvHeaders = Object.keys(BULKJOB_HEADERS[ENTITY_TYPE.FLIGHT_PLANS]);
    requestBody.query = getQueryForEntity(ENTITY_TYPE.FLIGHT_PLANS);
    break;
  case API_CALL_NAME.MEDIA_ITEMS:
    requestBody.index = API_CALL_NAME.MEDIA_ITEMS;
    requestBody.csvHeaders = Object.keys(BULKJOB_HEADERS[ENTITY_TYPE.MEDIA_ITEMS]);
    customAttributes?.attributes.map((attribute: Attribute) => {
      requestBody.csvHeaders.push(CUSTOM_ATTRIBUTE_BULK_PREFIX + attribute?.slug);
    });
    requestBody.query = getQueryForEntity(ENTITY_TYPE.MEDIA_ITEMS);
    break;
  default:
    break;
  }

  return requestBody;
};


export const bulkDownloadAllItems = (
  getTokenFn: () => string,
  allItemsSelected: boolean,
  index: string,
  customAttributes: any,
  searchFilter: IPropertyFilterProps,
  selectedBG: IBusinessGroup | null,
) => {

  if (allItemsSelected === false) {
    return;
  }
  const bgSlug = selectedBG?.businessGroupSlug;
  const fileExportName = generateTemplateDownloadCSVName(
    index,
    selectedBG?.businessGroupName || "",
    true,
  );

  const requestBody = getRequestBodyForBulkExport(
    index,
    customAttributes,
    searchFilter,
    bgSlug,
  );

  (async () => {
    try {

      const bulkExportResponse = await fetch(`${BASE_API_ENDPOINT}/export`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getTokenFn(),
        },
        method: REQUEST_METHOD.POST,
        body: JSON.stringify(requestBody),
      });
      const data = await bulkExportResponse.text();

      const encodedCSV = encodeURIComponent(data);
      const encodedUri = `data:text/csv;charset=utf-8,${encodedCSV}`;

      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${fileExportName}.csv`);
      document.body.appendChild(link);

      link.click();
    } catch (error) {
      console.log("Error occurred performing a bulk export ", error);
    }
  })();
};
