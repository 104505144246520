import { useState, useEffect } from "react";
import { getEnvironmentVariables } from "../data/constants/environment";
import { ResponseDataType } from "../views/admin/Interface";
import { FlashbarProps } from "@cloudscape-design/components";
import { NavigateFunction } from "react-router-dom";

export type ReturnType = {
  data: any;
  isLoading: boolean;
  serverError: Error | null;
  refresh: () => void;
};
type UseFetchParams = {
  urlPath: string;
  method: string;
  getTokenFn: () => string;
};
export interface PostParam {
  urlParam: string;
  getIdToken: () => string;
  newBusinessData: any;
  addMessage: (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string | undefined
  ) => void;
  navigate: NavigateFunction;
  navigateTo: string;
  successMessage: string;
  errorMessage: string;
  setPosting: React.Dispatch<React.SetStateAction<boolean>>;
  method: string;
}

const BASE_API_ENDPOINT = `${getEnvironmentVariables().API_ENDPOINT}`;

export const getData = async (urlParam: string, authTokenFn: () => string) => {
  const response = await fetch(
    `${BASE_API_ENDPOINT}${urlParam}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: await authTokenFn(),
      },
      method: "GET",
    }
  );
  return await response.json();
};

/** 
 * useFetchBusinessData hook utilizes TaCS's base api endpoint with the given
 * urlPath and method to fetch BusinessData
 * 
 * @param UseFetchParams
 */
export const useFetchBusinessData = (
  {
    urlPath,
    method,
    getTokenFn,
  }: UseFetchParams
): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<ResponseDataType>(
    () => { return {} as ResponseDataType; }
  );
  const [serverError, setServerError] = useState<Error | null>(null);
  const [shouldRefetch, refetch] = useState({});
  const refresh = () => refetch({});

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response: Response = await fetch(`${BASE_API_ENDPOINT}${urlPath}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: await getTokenFn(),
          },
          method,
        });
        const data = await response.json();
        setData(data);
        setIsLoading(false);
      } catch (error) {
        const err = error as Error;
        setServerError(err);
      }
    };

    fetchData();
  }, [urlPath, shouldRefetch]);

  return { isLoading, data, serverError, refresh };
};

/**
 * Posts businessData. e.g businessUnit, businessGroup
 * @param param0 PostParam
 * @returns response data
 */
export const postNewBusinessData = async ({
  method,
  newBusinessData,
  getIdToken,
  addMessage,
  navigate,
  urlParam,
  errorMessage,
  successMessage,
  navigateTo,
  setPosting
}: PostParam) => {

  const result = await fetch(`${BASE_API_ENDPOINT}/${urlParam}`, {
    method: method,
    headers: {
      "Authorization": await getIdToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newBusinessData),
  });

  if (result.ok) {
    setPosting(false);
    addMessage({
      type: "success",
      content: successMessage,
      dismissible: true,
    });
    navigate(navigateTo);
  } else {
    const data = await result.json();
    setPosting(false);
    addMessage({
      type: "error",
      content: `${errorMessage} ${data["message"]}`,
      dismissible: true,
    });
  }
};
