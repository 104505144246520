import React, { useContext, useEffect, useState } from "react";
import {
  ButtonProps,
  CollectionPreferencesProps,
  NonCancelableCustomEvent,
  PropertyFilterProps,
  TableProps,
} from "@cloudscape-design/components";
import TaCSTableCloudscape from "../../../components/table/TaCSTableCloudscape";
import {
  ADMIN_COLUMN_IDS,
  ATTRIBUTE_FILTERING_PROPERTIES,
  BUSINESS_ATTRIBUTES_TABLE_COLUMNS,
  PREFERRED_VISIBLE_COLUMNS_ATTRIBUTES,
  VISIBLE_CONTENT_OPTION_PREFERENCE_ATTRIBUTES,
} from "../config";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ENTITY_TYPE,
  BUSINESS_ROUTE_PATHS,
  ITEM_UNIQUE_ID,
} from "../../../data/constants/common";
import { getTablePreferencesFromLocalStorage, handleSavedSearchFilters } from "../../../data/config/commonDashboardConfig";
import { Attribute } from "../Interface";
import { IBusinessGroup } from "../../../data/interfaces/IUser";
import { RepositoryContext } from "../../../context/RepositoryContext";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import { getSavedSearchFiltersLocalStorage } from "../../../components/table/TableUtils";

type Props = {
  getIdToken: () => string;
  selectedBG: IBusinessGroup | null;
};

export const ManageAttributes: React.FC<Props> = ({
  getIdToken,
  selectedBG,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<Attribute[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const [tablePreferences, setTablePreferences] =
    useState<CollectionPreferencesProps.Preferences>(() => {
      return getTablePreferencesFromLocalStorage(
        "attributes",
        PREFERRED_VISIBLE_COLUMNS_ATTRIBUTES
      );
    });
  const navigate = useNavigate();
  const { adminRepo } = useContext(RepositoryContext);

  const [sortingDescending, setSortingDescending] = useState<
    boolean | undefined
  >(true);
  const [sortingColumn, setSortingColumn] =
    useState<TableProps.SortingColumn<any>>({sortingField: ADMIN_COLUMN_IDS.CREATION_DATE});
  const [searchQuery, setSearchQuery] = useState<PropertyFilterProps.Query>(
    getSavedSearchFiltersLocalStorage(ADMIN_ENTITY_TYPE.ATTRIBUTES, true)
  );

  const [filteringStatusType, setFilteringStatusType] =
    useState<DropdownStatusProps.StatusType>("finished");
  const [filteringOptions, setFilteringOptions] = useState<
    PropertyFilterProps.FilteringOption[]
  >([]);

  const { isLoading, data, serverError, refresh } = adminRepo.searchAttributes(
    searchQuery,
    tablePreferences.pageSize as number,
    currentPage,
    sortingColumn?.sortingField,
    sortingDescending
  );

  useEffect(() => {
    setTableData(data);
    setLoadingTableData(false);
  }, [isLoading, currentPage, tablePreferences]);

  useEffect(() => {
    handleSavedSearchFilters({
      query: searchQuery,
      entityType: ADMIN_ENTITY_TYPE.ATTRIBUTES,
      isAdmin: true
    });
  }, [searchQuery]);

  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    navigate(BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC + BUSINESS_ROUTE_PATHS.CREATE);
  };

  const handleAttributeTableActions = (): void => {
    navigate(BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC + BUSINESS_ROUTE_PATHS.EDIT, {
      state: {
        ...selectedItems[0],
      },
    });
  };

  const handleSearchChange = (query: PropertyFilterProps.Query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleSearchOnLoadItems = async (
    event: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>
  ) => {
    const { filteringProperty } = event.detail;

    if (!filteringProperty) return;

    setFilteringStatusType("loading");
    setFilteringOptions([]);

    await fetchSuggestions(event.detail);
  };

  const fetchSuggestions = async (
    detail: PropertyFilterProps.LoadItemsDetail
  ) => {
    if (detail.filteringProperty) {
      try {
        const extractedList = await adminRepo.fetchAttributeSuggestions(
          detail,
          searchQuery
        );
        setFilteringStatusType("finished");

        if (extractedList) setFilteringOptions(extractedList);
      } catch (e: any) {
        console.error("Failed to fetch suggestions", e);
      }
    }
  };

  const handleSortingChange = (detail: TableProps.SortingState<any>) => {
    setSortingColumn(detail.sortingColumn);
    setSortingDescending(detail.isDescending);
    refresh();
  };

  return (
    <TaCSTableCloudscape
      tableHeading="Attributes"
      filteringPlaceholder="Search for attributes"
      businessProperty="attributes"
      primaryActionLabel="Create attribute"
      isLoading={loadingTableData}
      data={tableData}
      serverError={serverError}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      tablePreferences={tablePreferences}
      setTablePreferences={setTablePreferences}
      columnDefinitions={BUSINESS_ATTRIBUTES_TABLE_COLUMNS}
      items={tableData?.items}
      visibleContentPreferenceOptions={
        VISIBLE_CONTENT_OPTION_PREFERENCE_ATTRIBUTES
      }
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      handleTablePrimaryAction={handleOnCreate}
      handleTableActions={handleAttributeTableActions}
      refetch={refresh}
      itemId={ITEM_UNIQUE_ID.ATTRIBUTE}
      idToken={getIdToken}
      customAttributes={null}
      selectedBG={selectedBG}
      searchFilterProps={{
        filteringProperties: ATTRIBUTE_FILTERING_PROPERTIES,
        searchQuery: searchQuery,
        handleSearchChange: handleSearchChange,
        filteringStatusType: filteringStatusType,
        filteringOptions: filteringOptions,
        onLoadItems: handleSearchOnLoadItems,
        items: { items: [], page: data.page },
      }}
      sortingColumn={sortingColumn}
      sortingDescending={sortingDescending}
      onSortingChange={({ detail }) => {
        handleSortingChange(detail);
      }}
    />
  );
};

export const ManageAttributesDashboard = () => {
  const { getIdToken, selectedBG } = useContext(AuthContext);

  return <ManageAttributes getIdToken={getIdToken} selectedBG={selectedBG} />;
};
