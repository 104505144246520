import React, { useEffect } from "react";
import { Page } from "../../views/admin/Interface";
import { Button } from "@cloudscape-design/components";


/**
 * The `Props` type defines the properties required by the `SelectAllHandler` component.
 */
export type Props = {
  page: Page;
  allPageItemsSelected: boolean;
  allItemsSelected: boolean;
  setAllItemsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItems: (arg0: any[]) => void;
};

/**
 * The `SelectAllHandler` component is responsible for rendering a UI element that 
 * allows the user to select or deselect all items in the current page or across all pages.
 *
 * @param {Props} props - The properties required by the `SelectAllHandler` component.
 * @returns {JSX.Element} - The rendered `SelectAllHandler` component.
 */
export const SelectAllHandler: React.FC<Props> = ({
  page,
  allPageItemsSelected,
  allItemsSelected,
  setAllItemsSelected,
  setSelectedItems,
}) => {

  useEffect(() => {
    setSelectedItems([]);
    setAllItemsSelected(allPageItemsSelected && page?.totalPages === 1);
  }, [page?.totalElements]);

  return (
    <>
      {allPageItemsSelected &&
        (<p style={{color: "whitesmoke" }}>
          <i data-testid={"all-page-item-selected"}>
            {
              allItemsSelected
                ? <span>All {page.totalElements} items are selected</span>
                : <span>All {page.numberOfElements} items on this page selected</span>
            }
          </i>
          <span>
            {allItemsSelected ?
              <Button
                data-testid={"clear-selection-btn"}
                variant="link"
                onClick={() => {
                  setSelectedItems([]);
                  setAllItemsSelected(false);
                }}
              >
                Clear selection
              </Button>
              :
              <Button
                data-testid={"select-all-btn"}
                variant="link"
                onClick={() => {
                  setAllItemsSelected(true);
                }}
              >
                Select all {page.totalElements} items
              </Button>
            }
          </span>
        </p>)
      }
    </>
  );
};
