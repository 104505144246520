import { ENTITY_TYPE } from "../constants/common";
import {
  ApprovedBGsForV3,
  ENVIRONMENT,
  getEnvironment,
} from "../constants/environment";
import { IBusinessGroup } from "../interfaces/IUser";

export const allowFeature = (
  selectedBG: IBusinessGroup | null,
  allowProd = false
) => {
  const isProd = getEnvironment() === ENVIRONMENT.PROD;

  const allowedBG =
    selectedBG && ApprovedBGsForV3.includes(selectedBG.businessGroupName)
      ? true
      : false;

  return (allowProd || !isProd) && allowedBG;
};

export const isAuthorizedActionForExternalUser = (
  entityType: string,
  isExternal: boolean
) => {
  if (isExternal && entityType === ENTITY_TYPE.CAMPAIGNS) {
    return true;
  }
};
