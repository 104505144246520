import React from "react";
import { Link } from "react-router-dom";
import { CollectionPreferencesProps, PropertyFilterProps, TableProps } from "@cloudscape-design/components";
import { MediaItem } from "../../data/interfaces/IMediaItems";
import { EditInputNodeInline, EditSelectNodeInline } from "./EditableCellNodes";
import { formatDate } from "../../data/helpers/dates";
import { DateForm } from "../../components/table/DateForm";

export const MEDIA_ITEMS_COLUMN_IDS = {
  MEDIA_ITEM_ID: "id",
  MEDIA_ITEM_NAME: "mediaItemName",
  TACS_ID: "tacsId",
  PARENT_MEDIA_ITEM_ID: "parentMediaItemId",
  PACKAGE_THIRD_PARTY_ID: "mediaitem3ppkg",
  CAMPAIGN: "campaign",
  FLIGHT_PLAN: "flightPlanId",
  MEDIA_SUB_CHANNEL: "mediaSubChannel",
  MEDIA_START_DATE: "startDate",
  MEDIA_END_DATE: "endDate",
  MEDIA_SUPPLIER: "mediaSupplier",
  MEDIA_SUPPLIER_ID: "mediaSupplierId",
  MEDIA_TYPE: "mediaItemType",
  MEDIA_PLACEMENT: "mediaPlacement",
  MEDIA_PLACEMENT_CATEGORY: "mediaPlacementCategory",
  MEDIA_PLACMENT_TYPE: "mediaPlacementType",
  MEDIA_PLACMENT_FORMAT: "mediaPlacementFormat",
  MEDIA_PLACMENT_LANGUAGE: "mediaPlacementLanguage",
  MEDIA_RATE_TYPE: "mediarateType",
  MEDIA_RATE: "mediaRate",
  MEDIA_PLANNED_UNITS: "mediaPlannedUnits",
  MEDIA_PLANNED_BUDGET: "mediaPlannedBudget",
  MEDIA_AD_SERVING_METHOD: "mediaAdServingMethod",
  MEDIA_BILLING_SOURCE: "mediaBillingSource",
  MEDIA_SUFFIX: "mediaSuffix",
  MEDIA_TARGETED_DEVICE: "mediaTargetedDevice",
  MEDIA_CREATIVE_NOTES: "mediaCreativeNotes",
  MEDIA_CREATIVE_TYPE: "mediaCreativeType",
  MEDIA_CREATIVE_DIMENSIONS: "mediaCreativeDimensions",
  MEDIA_CREATIVE_LENGTH: "mediaCreativeLength",
  MEDIA_DAM: "mediaDam",
  MEDIA_DAM_ID: "mediaDamId",
  MEDIA_BUYING_ENTITY: "mediaBuyingEntity",
  MEDIA_BUYING_ENTITY_ID: "mediaBuyingEntityId",
  MEDIA_ITEM_STATUS: "mediaItemStatus",
  MEDIA_SITE_TYPE: "mediaSiteType",
  MEDIA_SITE_NAME: "mediaSiteName",
  MEDIA_COST_METHOD: "mediaCostMethod",
  MEDIA_PLANNED_BID_MIN: "mediaPlannedBidMin",
  MEDIA_AD_ENVIRONMENT: "mediaAdEnvironment",
  MEDIA_AD_SERVER: "mediaAdServer",
  MEDIA_AD_SERVER_ID: "mediaAdServerId",
  MEDIA_TARGETING_TACTIC: "mediaTargetingTactic",
  MEDIA_TARGETED_AUDIENCE: "mediaTargetedAudience",
  MEDIA_TARGETING_DATA_SOURCE: "mediaTargetingDatasource",
  MEDIA_VIDEO_SKIPPABLE: "mediaVideoSkippable",
  MEDIA_PV_PHASE: "pvPhase",
  MEDIA_PV_LIFECYCLE: "pvLifeCycle",
  CREATED_BY: "createdBy",
  CREATION_DATE: "creationDate",
  LAST_UPDATED_BY: "lastUpdatedBy",
  LAST_UPDATED_DATE: "lastUpdatedDate",
  FLIGHT_PLAN_NAME: "flightplanname",
  CAMPAIGN_END_DATE_PLANNED: "campaignenddateplanned",
  CAMPAIGN_MARKETPLACE: "campaignmarketplace",
  CAMPAIGN_NAME: "campaignname",
  CAMPAIGN_START_DATE_PLANNED: "campaignstartdateplanned",
  FLIGHT_PLAN_START_DATE: "flightplanstartDate",
  FLIGHT_PLAN_END_DATE: "flightplanendDate",
  BUSINESS_UNIT: "businessUnitName",
};

export const MEDIA_ITEMS_COLUMN_LABELS = {
  MEDIA_ITEM_ID: "Media Item ID",
  MEDIA_ITEM_NAME: "Media Item Name",
  MEDIA_ITEM_STATUS: "Status",
  TACS_ID: "TaCS ID",
  PARENT_MEDIA_ITEM_ID: "Parent ID",
  PACKAGE_THIRD_PARTY_ID: "Package Third Party ID",
  FLIGHT_PLAN: "Flight Plan ID",
  MEDIA_SUB_CHANNEL: "Sub Channel",
  MEDIA_START_DATE: "Start Date",
  MEDIA_END_DATE: "End Date",
  MEDIA_SUPPLIER: "Supplier",
  MEDIA_SUPPLIER_ID: "Supplier ID",
  MEDIA_TYPE: "Media Item Type",
  MEDIA_PLACEMENT: "Placement",
  MEDIA_PLACEMENT_CATEGORY: "Placement Category",
  MEDIA_PLACMENT_TYPE: "Placement Type",
  MEDIA_PLACMENT_FORMAT: "Placement Format",
  MEDIA_PLACMENT_LANGUAGE: "Placement Language",
  MEDIA_RATE: "Rate",
  MEDIA_RATE_TYPE: "Rate Type",
  MEDIA_PLANNED_UNITS: "Planned Units",
  MEDIA_PLANNED_BUDGET: "Planned Budget",
  MEDIA_AD_SERVING_METHOD: "Ad Serving Method",
  MEDIA_BILLING_SOURCE: "Billing Source",
  MEDIA_SUFFIX: "Suffix",
  MEDIA_TARGETED_DEVICE: "Targeted Device",
  MEDIA_CREATIVE_NOTES: "Creative Notes",
  MEDIA_CREATIVE_TYPE: "Creative Type",
  MEDIA_CREATIVE_DIMENSIONS: "Creative Dimensions",
  MEDIA_CREATIVE_LENGTH: "Creative Length",
  MEDIA_DAM: "DAM",
  MEDIA_DAM_ID: "DAM ID",
  MEDIA_BUYING_ENTITY: "Buying Entity",
  MEDIA_BUYING_ENTITY_ID: "Buying Entity ID",
  MEDIA_SITE_TYPE: "Site Type",
  MEDIA_SITE_NAME: "Site Name",
  MEDIA_COST_METHOD: "Cost Method",
  MEDIA_PLANNED_BID_MIN: "Planned Bid",
  MEDIA_AD_ENVIRONMENT: "Ad Environment",
  MEDIA_AD_SERVER: "Ad Server",
  MEDIA_AD_SERVER_ID: "Ad Server ID",
  MEDIA_TARGETING_TACTIC: "Targeting Tactic",
  MEDIA_TARGETED_AUDIENCE: "Targeted Audience",
  MEDIA_TARGETING_DATA_SOURCE: "Targeting Data Source",
  MEDIA_VIDEO_SKIPPABLE: "Video Skippable",
  CREATED_BY: "Created By",
  CREATION_DATE: "Creation Date",
  LAST_UPDATED_BY: "Last Updated By",
  LAST_UPDATED_DATE: "Last Updated Date",
};

export const MEDIA_ITEM_PREFERRED_VISIBLE_COLUMNS = [
  MEDIA_ITEMS_COLUMN_IDS.TACS_ID,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_ID,
  MEDIA_ITEMS_COLUMN_IDS.PARENT_MEDIA_ITEM_ID,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_TYPE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_STATUS,
  MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUB_CHANNEL,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_START_DATE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_END_DATE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER_ID,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT_CATEGORY,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_TYPE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_FORMAT,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_LANGUAGE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE_TYPE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_UNITS,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BUDGET,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVING_METHOD,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_BILLING_SOURCE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUFFIX,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_DEVICE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_NOTES,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_TYPE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_DIMENSIONS,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_LENGTH,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM_ID,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY_ID,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_TYPE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_NAME,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_COST_METHOD,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BID_MIN,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_ENVIRONMENT,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER_ID,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_TACTIC,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_AUDIENCE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_DATA_SOURCE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_VIDEO_SKIPPABLE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PV_PHASE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_PV_LIFECYCLE,
  MEDIA_ITEMS_COLUMN_IDS.CREATED_BY,
  MEDIA_ITEMS_COLUMN_IDS.CREATION_DATE,
  MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_BY,
  MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_DATE,
  MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_NAME,
  MEDIA_ITEMS_COLUMN_IDS.PACKAGE_THIRD_PARTY_ID,
  MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_MARKETPLACE,
  MEDIA_ITEMS_COLUMN_IDS.BUSINESS_UNIT,
  MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_END_DATE_PLANNED,
  MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_NAME,
  MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_START_DATE_PLANNED,
  MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_START_DATE,
  MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_END_DATE,
  MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_NAME,
];

export const MEDIA_ITEM_VISIBLE_CONTENT_OPTION: CollectionPreferencesProps.VisibleContentOption[] = [
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_ID,
    editable: false,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.PARENT_MEDIA_ITEM_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.PARENT_MEDIA_ITEM_ID,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.TACS_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.TACS_ID,
    editable: false,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TYPE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TYPE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN,
    label: MEDIA_ITEMS_COLUMN_LABELS.FLIGHT_PLAN,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_STATUS,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_STATUS,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUB_CHANNEL,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUB_CHANNEL,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_START_DATE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_START_DATE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_END_DATE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_END_DATE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER_ID,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACEMENT,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT_CATEGORY,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACEMENT_CATEGORY,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_TYPE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_TYPE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_FORMAT,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_FORMAT,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_LANGUAGE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_LANGUAGE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_RATE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE_TYPE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_RATE_TYPE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_UNITS,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_UNITS,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BUDGET,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_BUDGET,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVING_METHOD,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVING_METHOD,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BILLING_SOURCE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BILLING_SOURCE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUFFIX,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUFFIX,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_DEVICE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_DEVICE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_NOTES,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_NOTES,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_TYPE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_TYPE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_DIMENSIONS,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_DIMENSIONS,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_LENGTH,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_LENGTH,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM_ID,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY_ID,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_TYPE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_TYPE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_NAME,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_NAME,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_COST_METHOD,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_COST_METHOD,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BID_MIN,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_BID_MIN,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_ENVIRONMENT,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_ENVIRONMENT,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER_ID,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_TACTIC,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETING_TACTIC,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_AUDIENCE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_AUDIENCE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_DATA_SOURCE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETING_DATA_SOURCE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_VIDEO_SKIPPABLE,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_VIDEO_SKIPPABLE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.CREATED_BY,
    label: MEDIA_ITEMS_COLUMN_LABELS.CREATED_BY,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.CREATION_DATE,
    label: MEDIA_ITEMS_COLUMN_LABELS.CREATION_DATE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_BY,
    label: MEDIA_ITEMS_COLUMN_LABELS.LAST_UPDATED_BY,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_DATE,
    label: MEDIA_ITEMS_COLUMN_LABELS.LAST_UPDATED_DATE,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_NAME,
    label: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_NAME,
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_NAME,
    label: "Campaign Name",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_NAME,
    label: "Flight Plan Name",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_MARKETPLACE,
    label: "Marketplace",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_START_DATE,
    label: "Flight plan Start date",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_END_DATE,
    label: "Flight plan End date",
    editable: true,
  },

  {
    id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_START_DATE_PLANNED,
    label: "Campaign Start Date",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_END_DATE_PLANNED,
    label: "Campaing End Date",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.BUSINESS_UNIT,
    label: "Business Unit",
    editable: true,
  },
  {
    id: MEDIA_ITEMS_COLUMN_IDS.PACKAGE_THIRD_PARTY_ID,
    label: MEDIA_ITEMS_COLUMN_LABELS.PACKAGE_THIRD_PARTY_ID,
    editable: true,
  },
];

export const MEDIA_ITEM_TABLE_COLUMNS: TableProps.ColumnDefinition<MediaItem>[] =
  [
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_ID,
      cell: (e: MediaItem) => e.id,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.PARENT_MEDIA_ITEM_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.PARENT_MEDIA_ITEM_ID,
      cell: (e: MediaItem) => e.parentMediaItemId,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.PARENT_MEDIA_ITEM_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.TACS_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.TACS_ID,
      cell: (e: MediaItem) => (
        <Link to={`/campaigns/${e.businessUnitSlug}/${e.tacsId}`}>
          {e.tacsId}
        </Link>
      ),
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.TACS_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN,
      header: MEDIA_ITEMS_COLUMN_LABELS.FLIGHT_PLAN,
      cell: (e: MediaItem) => e.flightPlanId,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_NAME,
      header: "Flight Plan Name",
      cell: (e: MediaItem) => e.flightplanname,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_NAME,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TYPE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TYPE,
      cell: (e: MediaItem) => e.mediaItemTypeLabel,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TYPE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_STATUS,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_STATUS,
      cell: (e: MediaItem) => e.mediaItemStatus,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_STATUS,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUB_CHANNEL,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUB_CHANNEL,
      cell: (e: MediaItem) => e.mediaSubChannelLabel,
      minWidth: 200,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUB_CHANNEL
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUB_CHANNEL,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Sub Channel Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUB_CHANNEL,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_START_DATE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_START_DATE,
      cell: (e: MediaItem) => formatDate(String(e.startDate)),
      minWidth: 200,
      editConfig: {
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_START_DATE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Start Date Error",
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_START_DATE,
          "date"
        ),
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_START_DATE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_END_DATE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_END_DATE,
      cell: (e: MediaItem) => formatDate(String(e.endDate)),
      minWidth: 200,
      editConfig: {
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_END_DATE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "End Date Error",
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_END_DATE,
          "date"
        ),
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_END_DATE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER,
      cell: (e: MediaItem) => e.mediaSupplierLabel,
      minWidth: 200,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Supplier Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER_ID,
      cell: (e: MediaItem) => e.mediaSupplierId,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER_ID
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER_ID,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Supplier ID Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_TYPE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_TYPE,
      cell: (e: MediaItem) => e.mediaSiteTypeLabel,
      minWidth: 200,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_TYPE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_TYPE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Site Type Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_TYPE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_NAME,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_NAME,
      cell: (e: MediaItem) => e.mediaSiteName,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_NAME
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_NAME,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Site Name Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_NAME,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACEMENT,
      cell: (e: MediaItem) => e.mediaPlacement,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACEMENT,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Placement Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT_CATEGORY,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACEMENT_CATEGORY,
      cell: (e: MediaItem) => e.mediaPlacementCategory,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT_CATEGORY
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACEMENT_CATEGORY,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Placement Category Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACEMENT_CATEGORY,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_TYPE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_TYPE,
      cell: (e: MediaItem) => e.mediaPlacementTypeLabel,
      minWidth: 200,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_TYPE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_TYPE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Placement Type Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_TYPE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_FORMAT,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_FORMAT,
      cell: (e: MediaItem) => e.mediaPlacementFormat,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_FORMAT,
          "text"
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_FORMAT,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Placement Format Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_FORMAT,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_LANGUAGE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_LANGUAGE,
      cell: (e: MediaItem) => e.mediaPlacementLanguageLabel,
      minWidth: 220,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_LANGUAGE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_LANGUAGE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Placement Language Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_LANGUAGE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_COST_METHOD,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_COST_METHOD,
      cell: (e: MediaItem) => e.mediaCostMethodLabel,
      minWidth: 176,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_COST_METHOD
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_COST_METHOD,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Cost Method Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_COST_METHOD,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_RATE,
      cell: (e: MediaItem) => e.mediaRate,
      minWidth: 176,
      editConfig: {
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_RATE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Rate Error",
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE,
          "number"
        ),
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_RATE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_UNITS,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_UNITS,
      cell: (e: MediaItem) => e.mediaPlannedUnits,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_UNITS,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BID_MIN,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_BID_MIN,
      cell: (e: MediaItem) => e.mediaPlannedBidMin,
      minWidth: 176,
      editConfig: {
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_BID_MIN,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Planned Bid Error",
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BID_MIN,
          "number"
        ),
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BID_MIN,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BUDGET,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_BUDGET,
      cell: (e: MediaItem) => e.mediaPlannedBudget,
      minWidth: 176,
      editConfig: {
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLANNED_BUDGET,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Planned Budget Error",
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BUDGET,
          "number"
        ),
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLANNED_BUDGET,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER,
      cell: (e: MediaItem) => e.mediaAdServer,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Ad Server Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER_ID,
      cell: (e: MediaItem) => e.mediaAdServerId,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER_ID
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER_ID,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Ad Server ID Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_ENVIRONMENT,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_ENVIRONMENT,
      cell: (e: MediaItem) => e.mediaAdEnvironment,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_ENVIRONMENT
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_ENVIRONMENT,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Ad Environment Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_ENVIRONMENT,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVING_METHOD,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVING_METHOD,
      cell: (e: MediaItem) => e.mediaAdServingMethod,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVING_METHOD
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVING_METHOD,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Ad Serving Method Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVING_METHOD,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BILLING_SOURCE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BILLING_SOURCE,
      cell: (e: MediaItem) => e.mediaBillingSource,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_BILLING_SOURCE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BILLING_SOURCE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Billing Source Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BILLING_SOURCE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUFFIX,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUFFIX,
      cell: (e: MediaItem) => e.mediaSuffix,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUFFIX),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUFFIX,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Suffix Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUFFIX,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_DEVICE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_DEVICE,
      cell: (e: MediaItem) => e.mediaTargetedDeviceLabel,
      minWidth: 220,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_DEVICE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_DEVICE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Targeted Device Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_DEVICE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_TACTIC,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETING_TACTIC,
      cell: (e: MediaItem) => e.mediaTargetingTactic,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_TACTIC
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETING_TACTIC,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Targeting Tactic Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_TACTIC,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_AUDIENCE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_AUDIENCE,
      cell: (e: MediaItem) => e.mediaTargetedAudience,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_AUDIENCE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_AUDIENCE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Targeted Audience Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_AUDIENCE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_DATA_SOURCE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETING_DATA_SOURCE,
      cell: (e: MediaItem) => e.mediaTargetingDatasource,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_DATA_SOURCE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETING_DATA_SOURCE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Targeting Data Source Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETING_DATA_SOURCE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_NOTES,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_NOTES,
      cell: (e: MediaItem) => e.mediaCreativeNotes,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_NOTES
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_NOTES,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Creative Notes Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_NOTES,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_TYPE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_TYPE,
      cell: (e: MediaItem) => e.mediaCreativeTypeLabel,
      minWidth: 176,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_TYPE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_TYPE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Creative Type Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_TYPE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_DIMENSIONS,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_DIMENSIONS,
      cell: (e: MediaItem) => e.mediaCreativeDimensionsLabel,
      minWidth: 176,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_DIMENSIONS
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_DIMENSIONS,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Creative Dimensions Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_DIMENSIONS,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_LENGTH,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_LENGTH,
      cell: (e: MediaItem) => e.mediaCreativeLength,
      minWidth: 176,
      editConfig: {
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_LENGTH,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Creative Length Error",
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_LENGTH,
          "number"
        ),
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_LENGTH,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM,
      cell: (e: MediaItem) => e.mediaDam,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "DAM Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM_ID,
      cell: (e: MediaItem) => e.mediaDamId,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM_ID),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM_ID,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "DAM ID Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_VIDEO_SKIPPABLE,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_VIDEO_SKIPPABLE,
      cell: (e: MediaItem) => e.mediaVideoSkippable,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_VIDEO_SKIPPABLE
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_VIDEO_SKIPPABLE,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Video Skippable Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_VIDEO_SKIPPABLE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY,
      cell: (e: MediaItem) => e.mediaBuyingEntityLabel,
      minWidth: 200,
      editConfig: {
        editingCell: EditSelectNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Buying Entity Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY_ID,
      cell: (e: MediaItem) => e.mediaBuyingEntityId,
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY_ID
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY_ID,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Buying Entity ID Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.PACKAGE_THIRD_PARTY_ID,
      header: MEDIA_ITEMS_COLUMN_LABELS.PACKAGE_THIRD_PARTY_ID,
      cell: (e: MediaItem) => e.mediaitem3ppkg || "", // Making this optional as older Media items do not have `mediaitem3ppkg`.
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.PACKAGE_THIRD_PARTY_ID
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.PACKAGE_THIRD_PARTY_ID,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Package Third Party ID Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.PACKAGE_THIRD_PARTY_ID,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_NAME,
      header: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_NAME,
      cell: (e: MediaItem) => e.mediaItemName || "", // Making this optional as older Media items do not have `mediaItemName`.
      minWidth: 176,
      editConfig: {
        editingCell: EditInputNodeInline(
          MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_NAME
        ),
        ariaLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_NAME,
        editIconAriaLabel: "editable",
        errorIconAriaLabel: "Media Item Name Error",
      },
      sortingField: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_NAME,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_NAME,
      header: "Campaign Name",
      cell: (e: MediaItem) => e.campaignname,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_NAME,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.BUSINESS_UNIT,
      header: "Business Unit",
      cell: (e: MediaItem) => e.businessUnitName,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.BUSINESS_UNIT,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_MARKETPLACE,
      header: "Marketplace",
      cell: (e: MediaItem) => e.campaignmarketplace,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_MARKETPLACE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_START_DATE,
      header: "Flight plan start date",
      cell: (e: MediaItem) => formatDate(e.flightplanstartDate),
      sortingField: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_START_DATE,
      minWidth: 176,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_END_DATE,
      header: "Flight plan end date",
      cell: (e: MediaItem) => formatDate(e.flightplanendDate),
      sortingField: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_END_DATE,
      minWidth: 176,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_START_DATE_PLANNED,
      header: "Campaign Start Date",
      cell: (e: MediaItem) => formatDate(e.campaignstartdateplanned),
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_START_DATE_PLANNED,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_END_DATE_PLANNED,
      header: "Campaign End Date",
      cell: (e: MediaItem) => formatDate(e.campaignenddateplanned),
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_END_DATE_PLANNED,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.CREATED_BY,
      header: MEDIA_ITEMS_COLUMN_LABELS.CREATED_BY,
      cell: (e: MediaItem) => e.metadata.createdBy,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.CREATED_BY,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.CREATION_DATE,
      header: MEDIA_ITEMS_COLUMN_LABELS.CREATION_DATE,
      cell: (e: MediaItem) => formatDate(String(e.metadata.creationDate)),
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.CREATION_DATE,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_BY,
      header: MEDIA_ITEMS_COLUMN_LABELS.LAST_UPDATED_BY,
      cell: (e: MediaItem) => e.metadata.lastUpdatedBy || e.metadata.createdBy,
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_BY,
    },
    {
      id: MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_DATE,
      header: MEDIA_ITEMS_COLUMN_LABELS.LAST_UPDATED_DATE,
      cell: (e: MediaItem) =>
        e.metadata.lastUpdatedBy
          ? formatDate(String(e.metadata.lastUpdatedDate))
          : formatDate(String(e.metadata.creationDate)),
      minWidth: 176,
      sortingField: MEDIA_ITEMS_COLUMN_IDS.LAST_UPDATED_DATE,
    },
  ];

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_ID,
    operators: ["="],
    propertyLabel: "Media ID",
    groupValuesLabel: "Media ID values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.TACS_ID,
    operators: ["="],
    propertyLabel: "TaCS ID",
    groupValuesLabel: "TaCS ID values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN,
    operators: ["="],
    propertyLabel: "Flight Plan ID",
    groupValuesLabel: "Flight Plan ID values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.PARENT_MEDIA_ITEM_ID,
    operators: ["="],
    propertyLabel: "Parent Media ID",
    groupValuesLabel: "Parent Media ID values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.BUSINESS_UNIT,
    operators: ["="],
    propertyLabel: "Business unit",
    groupValuesLabel: "Business unit values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.CREATED_BY,
    operators: ["="],
    propertyLabel: "Created by",
    groupValuesLabel: "Created by values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_NAME,
    operators: ["=", ":"],
    propertyLabel: "Flight Plan name",
    groupValuesLabel: "Flight Plan name values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_NAME,
    operators: ["=", ":"],
    propertyLabel: "Campaign name",
    groupValuesLabel: "Campaign name values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_MARKETPLACE,
    operators: ["="],
    propertyLabel: "Marketplace",
    groupValuesLabel: "Marketplace values",
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_ITEM_NAME,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_NAME,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_ITEM_NAME,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUPPLIER_ID,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER_ID,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUPPLIER_ID,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_AD_SERVER_ID,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER_ID,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_AD_SERVER_ID,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_BUYING_ENTITY_ID,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY_ID,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_BUYING_ENTITY_ID,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_TYPE}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TYPE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TYPE,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_SUB_CHANNEL}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUB_CHANNEL,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SUB_CHANNEL,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_SITE_TYPE}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_TYPE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_SITE_TYPE,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_TYPE}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_TYPE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_TYPE,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_FORMAT,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_FORMAT,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_FORMAT,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_PLACMENT_LANGUAGE}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_LANGUAGE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_PLACMENT_LANGUAGE,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_COST_METHOD}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_COST_METHOD,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_COST_METHOD,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_START_DATE,
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_START_DATE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_START_DATE,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_END_DATE,
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_END_DATE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_END_DATE,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_TARGETED_DEVICE}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_DEVICE,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_TARGETED_DEVICE,
  },
  {
    key: `${MEDIA_ITEMS_COLUMN_IDS.MEDIA_CREATIVE_DIMENSIONS}Label`,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_DIMENSIONS,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_CREATIVE_DIMENSIONS,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.MEDIA_DAM_ID,
    operators: ["="],
    propertyLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM_ID,
    groupValuesLabel: MEDIA_ITEMS_COLUMN_LABELS.MEDIA_DAM_ID,
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_START_DATE,
    propertyLabel: "Flight Plan Start Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.FLIGHT_PLAN_END_DATE,
    propertyLabel: "Flight Plan End Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_START_DATE_PLANNED,
    propertyLabel: "Campaign Start Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
  {
    key: MEDIA_ITEMS_COLUMN_IDS.CAMPAIGN_END_DATE_PLANNED,
    propertyLabel: "Campaign End Date",
    groupValuesLabel: "",
    operators: [
      {
        operator: "=",
        form: DateForm,
        match: "date",
      },
      {
        operator: "<=",
        form: DateForm,
        match: "date",
      },
      {
        operator: ">=",
        form: DateForm,
        match: "date",
      },
    ],
  },
];
