import { ButtonProps, CollectionPreferencesProps, TableProps } from "@cloudscape-design/components";
import { IPropertyFilterProps } from "../../components/table/CloudscapeTablePropertyFilter";
import { MediaItem } from "../../data/interfaces/IMediaItems";
import { ITEM_UNIQUE_ID } from "../../data/constants/common";
import { IBusinessGroup } from "../../data/interfaces/IUser";

export interface BusinessGroup {
  businessGroupSlug: string;
  businessGroupName: string;
  businessGroupOrg: string;
  businessGroupAdmin?: string;
  attributeUIDisplay?: string;
  customAttributesSlugList: string[];
  metadata: MODEMetadata;
}

export interface BusinessUnit {
  businessUnitSlug: string;
  businessUnitName: string;
  budgetClass: string;
  businessGroupSlug: string;
  businessUnitPOC: string;
  isAccessReadOnly?: boolean;
  groupRW?: string;
  groupRO?: string;
  metadata: MODEMetadata;
}
export interface CreateNewBusinessUnit extends BusinessUnit {
  isAccessReadOnly?: boolean;
  accessGroupsRW?: string[];
  accessGroupsRO?: string[];
}

export type AdminTableItems = BusinessGroup | BusinessUnit | Attribute;

export interface Attribute {
  assignment: string;
  datatype: string;
  entity: string;
  values: AttributeValueItem[];
  governed: boolean;
  dictionary: string;
  slug: string;
  uidisplay: string;
  metadata: MODEMetadata;
}

export type AttributeValueItem = {
  active: boolean;
  metadata?: MODEMetadata
  order?: number;
  slug: string;
  uidisplay: string;
  dictionary?: string;
};

export enum AttributeDataType {
  NUMERIC = "numeric",
  STRING = "string",
  DATE = "date",
  MULTI_SELECT = "multi_select",
  SELECT = "select",
}

export enum AttributeAssignment {
  UNIVERSAL = "universal",
  CUSTOM = "custom",
}

export interface MODEMetadata {
  createdBy: string;
  creationDate: number;
  lastUpdatedBy?: string;
  lastUpdatedDate?: number;
}

export type Page = {
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
};

export type BusinessGroupsResponseData = {
  businessGroups: BusinessGroup[];
  page: Page;
}
export type BusinessUnitsResponseData = {
  businessUnits: BusinessUnit[];
  page: Page;
}
export type BusinessAttributesResponseData = {
  attributes: Attribute[];
  page: Page;
}
export type OpensearchResponseData = {
  items: any[];
  page: Page;
}

export type VisibleContentPreference = {
  id: string;
  editable: boolean;
  label: string;
}

export type BusinessProperty = "businessGroups" | "businessUnits" | "BusinessLogic" | "attributes" | "mediaItems";

export enum BUSINESS_PROPERTY {
  BUSINESS_GROUP = "Business Group",
  BUSINESS_UNIT = "Business Unit",
  BUSINESS_LOGIC = "Business Logic",
  ATTRIBUTE = "Attribute",
  MEDIA_ITEM = "Media Item",
}
export type AdminTableData = BusinessGroup | BusinessUnit;
export type ResponseDataType =
  BusinessGroupsResponseData | BusinessUnitsResponseData | BusinessAttributesResponseData | OpensearchResponseData;

export interface AdminTableProps extends TableProps {
  tableHeading: string;
  primaryActionLabel: string;
  filteringPlaceholder: string;
  businessProperty: BusinessProperty;
  isLoading: boolean;
  data: ResponseDataType;
  serverError: Error | null;
  currentPage: number;
  setCurrentPage: (arg0: number) => void;
  tablePreferences: CollectionPreferencesProps.Preferences;
  setTablePreferences: (arg0: CollectionPreferencesProps.Preferences) => void;
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  items: AdminTableItems[];
  visibleContentPreferenceOptions: VisibleContentPreference[];
  selectedItems: any[];
  setSelectedItems: (arg0: any[]) => void;
  handleTablePrimaryAction: (event: CustomEvent<ButtonProps.ClickDetail>) => void;
  handleTableActions: () => void;
  refetch: () => void;
  handleInlineUpdate?: (item: MediaItem, col: TableProps.ColumnDefinition<MediaItem>, value: unknown) => void;
  /** 
   * `itemId` - This `MUST` be a unique identifier of an entity or business.
   * e.g `id` in Flight Plans and Media Items or businessUnitSlug and 
   * businessGroupSlug in Business Units and Business Groups respectively
  */
  itemId: ITEM_UNIQUE_ID;
  searchFilterProps?: IPropertyFilterProps;
  idToken: () => string;
  customAttributes: any;
  selectedBG: IBusinessGroup | null;
}
