import React, { useCallback, useEffect, useState } from "react";
import { AnnotationContext, TutorialPanelProps } from "@cloudscape-design/components";
import { useTutorialContext } from "./store";
import { TutorialType, getHotspotByPage, overlayI18nStrings } from "./Tutorials";
import { CAMPAIGN_STEPS, TUTORIALS_COMPLETED, TutorialHotspot } from "./constants";
import { PAGE, getPageName } from "../../data/constants/common";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

export const TaCSTutorial: React.FC<Props> = ({ children }) => {
  const { state: { tutorials }, actions: { setHelpPanelTopic, setToolsOpen } } = useTutorialContext();
  const [currentTutorial, setCurrentTutorial] = useState<TutorialPanelProps.Tutorial | null>(null);
  const [startTutorial, setStartTutorial] = useState<boolean>();
  const { pathname } = useLocation();
 
  // Use users' local storage to track/determine whether to start the tutorial when page loads
  useEffect(() => {
    const hasUserCompletedTutorial = localStorage.getItem(TUTORIALS_COMPLETED);
    if (hasUserCompletedTutorial === "true") {
      setStartTutorial(false);
    } else {
      localStorage.setItem(TUTORIALS_COMPLETED, JSON.stringify(false));
      setToolsOpen(true);
      setStartTutorial(true);
    }
  }, []);

  useEffect(() => {
    if (startTutorial) {
      setCurrentTutorial(tutorials[TutorialType.MANAGE_CAMPAIGN]);
    } else {
      setCurrentTutorial(null);
    }
  }, [startTutorial]);

  const toggleTutorialCompleted = useCallback(
    (completed: boolean) => {
      if (!currentTutorial) {
        return completed;
      }
      const newTutorial = {
        ...currentTutorial,
        completed,
      };
      setCurrentTutorial(newTutorial);
    },
    [currentTutorial]
  );
  const pageName = getPageName(pathname);
  const defaultStep = pageName === PAGE.CAMPAIGNS ? 0 : CAMPAIGN_STEPS;
  return (
    <div data-testid="tutorial-annotation-context">
      <AnnotationContext
        currentTutorial={currentTutorial}
        onStepChange={({ detail }) => setHelpPanelTopic(Object.values(TutorialHotspot)[detail.step + defaultStep])}
        onStartTutorial={() => {
          toggleTutorialCompleted(false);
          setHelpPanelTopic(getHotspotByPage(pageName).manageEntity);

          setStartTutorial(true);
        }}
        onExitTutorial={() => {
          setStartTutorial(false);
        }}
        onFinish={() => {
          if (!currentTutorial) {
            return;
          }
          toggleTutorialCompleted(true);

          //Update local storage after user completes tutorial
          localStorage.setItem(TUTORIALS_COMPLETED, JSON.stringify(true));
        }}
        i18nStrings={overlayI18nStrings}
      >
        {children}
      </AnnotationContext>
    </div>
  );
};
