import React, { useEffect, useState } from "react";
import {
  Table,
  CollectionPreferences,
  Header,
  SpaceBetween,
  ButtonDropdown,
  Button,
  Box,
} from "@cloudscape-design/components";

import { AdminTableProps } from "../../views/admin/Interface";
import CloudscapeTablePagination from "./Pagination";
import { handleUserSavedTablePreferences } from "../../data/config/commonDashboardConfig";
import { MI_BUSINESS_PROP } from "../../views/media-items/MediaItemsDashboard";
import CloudscapeTablePropertyFilter from "./CloudscapeTablePropertyFilter";
import { isArrayBSubsetOfArrayA } from "../../data/helpers/functions";
import { SelectAllHandler } from "./SelectAllHandler";
import { bulkDownloadAllItems } from "../../client/bulkExportClient";
import { API_CALL_NAME } from "../../data/constants/common";

const TaCSTableCloudscape: React.FC<AdminTableProps> = ({
  tableHeading,
  primaryActionLabel,
  businessProperty,
  isLoading,
  data,
  currentPage,
  setCurrentPage,
  tablePreferences,
  setTablePreferences,
  columnDefinitions,
  items,
  visibleContentPreferenceOptions,
  selectedItems,
  setSelectedItems,
  handleTablePrimaryAction,
  handleTableActions,
  refetch,
  handleInlineUpdate,
  itemId,
  searchFilterProps,
  sortingColumn,
  sortingDescending,
  onSortingChange,
  idToken,
  customAttributes,
  selectedBG,
}) => {

  const [allPageItemsSelected, setAllPageItemsSelected] = useState<boolean>(isArrayBSubsetOfArrayA(selectedItems, items));
  const [allItemsSelected, setAllItemsSelected] = useState<boolean>(false);
  const page = data?.page;
  
  useEffect(() => {
    setSelectedItems(selectedItems);
    setAllPageItemsSelected(isArrayBSubsetOfArrayA(selectedItems, items));
  }, [selectedItems]);

  return (
    <Table
      data-testid="admin-table"
      variant="full-page"
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
      ariaLabels={{
        selectionGroupLabel: "Items selection",
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${
            selectedItems.length === 1 ? "item" : "items"
          } selected`,
      }}
      trackBy={(item) => item[itemId]}
      loading={isLoading}
      selectionType="multi"
      loadingText={isLoading ? `Loading ${tableHeading}`: "No resources to display"}
      header={
        <Header
          data-testid="admin-table-header"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="refresh"
                iconName="refresh"
                data-testid="admin-table-refresh-btn"
                onClick={() => refetch()}
              />
              <ButtonDropdown
                data-testid="admin-table-action-btn"
                disabled={selectedItems.length === 0}
                items={
                  businessProperty === MI_BUSINESS_PROP
                    ? [
                      {
                        text: "Archive",
                        id: "archive",
                        disabled: allItemsSelected || !selectedItems.length,
                        description: `Edit ${businessProperty}`,
                      },
                    ]
                    : [
                      {
                        text: "Edit",
                        id: "edit",
                        disabled: selectedItems.length !== 1,
                        description: `Edit ${businessProperty}`,
                      },
                    ]
                }
                onItemClick={handleTableActions}
              >
                Actions
              </ButtonDropdown>
              <Button
                data-testid={`create-new-${businessProperty}`}
                variant="primary"
                disabled={businessProperty === "mediaItems" && !selectedItems.length}
                onClick={(event) => {
                  if ((searchFilterProps && allItemsSelected) && businessProperty === MI_BUSINESS_PROP) {
                    bulkDownloadAllItems(
                      idToken, 
                      true, 
                      API_CALL_NAME.MEDIA_ITEMS, 
                      customAttributes, 
                      searchFilterProps,
                      selectedBG,
                    );
                  } else {
                    handleTablePrimaryAction(event);
                  }
                  setSelectedItems([]);
                }}
              >
                {primaryActionLabel}
              </Button>
            </SpaceBetween>
          }
        >
          {tableHeading}
        </Header>
      }
      pagination={
        <CloudscapeTablePagination
          items={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      }
      preferences={
        <CollectionPreferences
          title="Display preferences"
          data-testid="admin-collection-preferences"
          confirmLabel="Apply and save settings"
          cancelLabel="Cancel"
          onConfirm={(event) =>
            handleUserSavedTablePreferences({
              businessProperty,
              event,
              setCurrentPage,
              setTablePreferences,
            })
          }
          preferences={tablePreferences}
          pageSizePreference={{
            title: "Items per page",
            options: [
              { value: 5, label: "5" },
              { value: 10, label: "10" },
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
            ],
          }}
          visibleContentPreference={{
            title: "Column preferences",
            options: [
              {
                label: "Customize the columns visibility.",
                options: visibleContentPreferenceOptions,
              },
            ],
          }}
        />
      }
      visibleColumns={tablePreferences.visibleContent}
      wrapLines={tablePreferences.wrapLines}
      columnDefinitions={columnDefinitions}
      items={isLoading ? [] : items}
      submitEdit={handleInlineUpdate}
      resizableColumns
      empty={isLoading &&
        <Box textAlign="center" color="inherit">
          <b>No {tableHeading}</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No {tableHeading} to display.
          </Box>
        </Box>
      }
      filter={
        searchFilterProps && (
          <>
            <CloudscapeTablePropertyFilter {...searchFilterProps} />
            {page?.totalPages > 1 &&
              <SelectAllHandler
                page={page}
                setSelectedItems={setSelectedItems}
                setAllItemsSelected={setAllItemsSelected}
                allPageItemsSelected={allPageItemsSelected}
                allItemsSelected={allItemsSelected}
              />
            }
          </>
        )
      }
      sortingColumn={sortingColumn}
      sortingDescending={sortingDescending}
      onSortingChange={onSortingChange}
    />
  );
};

export default TaCSTableCloudscape;
