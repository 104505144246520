import React from "react";
import { Box, Button, PieChart } from "@cloudscape-design/components";
import { getLanguageCode } from "../../views/campaign/config";

const SpendPieChartCard = ({ data, total, currency }: any) => {
  return (
    <PieChart
      data={data}
      i18nStrings={{
        detailsValue: "Value",
        detailsPercentage: "Percentage",
        filterLabel: "Filter displayed data",
        filterPlaceholder: "Filter data",
        filterSelectedAriaLabel: "selected",
        detailPopoverDismissAriaLabel: "Dismiss",
        legendAriaLabel: "Legend",
        chartAriaRoleDescription: "pie chart",
        segmentAriaRoleDescription: "segment",
      }}
      segmentDescription={(datum, sum) =>
        `${new Intl.NumberFormat(getLanguageCode(currency), {
          style: "currency",
          currency: currency,
        }).format(datum.value)} ${currency} (${(
          (datum.value / sum) *
          100
        ).toFixed(0)}%)`
      }
      ariaDescription="Donut chart showing spend."
      ariaLabel="Spend Donut Chart"
      errorText="Error loading data."
      innerMetricDescription={currency}
      innerMetricValue={`${new Intl.NumberFormat(getLanguageCode(currency), {
        style: "currency",
        currency: currency,
      }).format(total)}`}
      loadingText="Loading chart"
      legendTitle="&nbsp;"
      recoveryText="Retry"
      size="large"
      variant="donut"
      hideFilter
      empty={
        <Box textAlign="center" color="inherit">
          {" "}
          <b>No data available</b>{" "}
          <Box variant="p" color="inherit">
            {" "}
            There is no data available{" "}
          </Box>{" "}
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          {" "}
          <b>No matching data</b>{" "}
          <Box variant="p" color="inherit">
            {" "}
            There is no matching data to display{" "}
          </Box>{" "}
          <Button>Clear filter</Button>{" "}
        </Box>
      }
      data-testid="campaign-pie-chart"
    />
  );
};

export default SpendPieChartCard;
