import { getEnvironmentVariables } from "../constants/environment";

const ENV_VARS = getEnvironmentVariables();

export const ANALYTICS_CONFIG = {
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: ENV_VARS.PINPOINT_APP_ID,
      // Amazon service region
      region: ENV_VARS.REGION,
      mandatorySignIn: false,
    }
  }
};
