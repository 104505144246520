import { AnnotationContextProps, Box, TutorialPanelProps } from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { CAMPAIGN_WIKI_URL, FLIGHT_PLANS_WIKI_URL, HOT_SPOTS, TutorialHotspot, TUTORIALS } from "./constants";
import { PAGE, getPageName } from "../../data/constants/common";

export interface TutorialProps extends TutorialPanelProps.Tutorial {
  id: TUTORIALS;
}

export enum TutorialType {
  MANAGE_CAMPAIGN,
  MANAGE_FLIGHT_PLAN,
}


/**
 * Content for tutorials
 */
export const tutorialData: (
  helpPanelHandler: (activeTopic: string) => undefined
  ) => TutorialProps[] = () => [
    {
      id: TUTORIALS.MANAGE_CAMPAIGN,
      title: "Learn about TaCS Campaigns",
      completed: false,
      description: (
        <>
          <Box variant="p" color="text-body-secondary" padding={{top: "n"}}
          >
            In this tutorial, you will:
          </Box>
          <ul>
            <li>
              <Box variant="span" color="text-body-secondary">
                Familiarize yourself with the UI elements of the Campaigns page
              </Box>
            </li>
            <li>
              <Box variant="span" color="text-body-secondary">
                See how to view and create Campaigns
              </Box>
            </li>
            <li>
              <Box variant="span" color="text-body-secondary">
                Learn how to use Search to find a specific Campaign
              </Box>
            </li>
            <li>
              <Box variant="span" color="text-body-secondary">
                Learn how to customize the page view
              </Box>
            </li>
          </ul>
        </>
      ),
      learnMoreUrl: CAMPAIGN_WIKI_URL,
      completedScreenDescription: "You've completed the Campaign tutorial",
      tasks: [
        {
          title: "Managing Campaigns",
          steps: [
            {
              title: "Manage Campaign page",
              content: (
                <Box variant="p">
                  On this page, you can manage existing Campaigns, create a new Campaign, or download 
                  Campaign metadata in bulk. You can also use the Actions button to archive a Campaign 
                  or all selected Campaigns.
                </Box>
              ),
              hotspotId: TutorialHotspot.manageCampaign
            },
            {
              title: "Create a new Campaign",
              content: (
                <>
                  <Box variant="p">
                    Use the <i>Create Campaign</i> button to create a new Campaign. After creating a new Campaign 
                    with all the required attributes, you can come back later to change or add additional details.
                  </Box>
                </>
              ),
              hotspotId: TutorialHotspot.createCampaignBtn
            },
            {
              title: "Search Campaigns",
              content: (
                <Box variant="p">
                  Use the search bar to help you find a specific Campaign. You can type a search query, 
                  or use the property filters to build more advanced searches.
                </Box>
              ),
              hotspotId: TutorialHotspot.filterCampaignItems,
            },
            {
              title: "Page display options",
              content: (
                <Box variant="p">
                  Click the gear icon to change your view settings. You can choose which attribute columns 
                  are in the table, and how many Campaigns are listed on each page. These settings are 
                  saved when you access TaCS from the same browser.
                </Box>
              ),
              hotspotId: TutorialHotspot.paginationAndDisplay
            },
          ]
        },
      ],
    },
    {
      id: TUTORIALS.MANAGE_FLIGHT_PLAN,
      title: "Learn about TaCS Flight Plans",
      completed: false,
      description: (
        <>
          <Box variant="p" color="text-body-secondary" padding={{top: "n"}}
          >
            In this tutorial, you will:
          </Box>
          <ul>
            <li>
              <Box variant="span" color="text-body-secondary">
                Familiarize yourself with the UI elements of the Flight Plans page
              </Box>
            </li>
            <li>
              <Box variant="span" color="text-body-secondary">
                See how to view and create Flight Plans
              </Box>
            </li>
            <li>
              <Box variant="span" color="text-body-secondary">
                Learn how to use Search to find a specific Flight Plan
              </Box>
            </li>
            <li>
              <Box variant="span" color="text-body-secondary">
                Learn how to custommize the page view
              </Box>
            </li>
          </ul>
        </>
      ),
      learnMoreUrl: FLIGHT_PLANS_WIKI_URL,
      completedScreenDescription: "You've completed the Flight Plan tutorial",
      tasks: [
        {
          title: "Managing Flight Plans",
          steps: [
            {
              title: "Manage Flight Plan page",
              content: (
                <Box variant="p">
                  On this page, you can manage existing Flight Plans, create a new Flight Plan, 
                  or download Flight Plan metadata in bulk. You can also use the Actions button 
                  to archive a Flight Plan or all selected Flight Plans.
                </Box>
              ),
              hotspotId: TutorialHotspot.manageFlightPlan,
            },
            {
              title: "Create a new Flight Plan",
              content: (
                <Box variant="p">
                  Use the <i>Create Flight Plan</i> button to create a new Flight Plan. 
                  After creating a new Flight Plan with all the required attributes, 
                  you can  come back later to change or add additional details.
                </Box>
              ),
              hotspotId: TutorialHotspot.createFlightPlan,
            },
            {
              title: "Search Flight Plans",
              content: (
                <Box variant="p">
                  Use the search bar to help you find a specific Flight Plan. 
                  You can type a search query, or use the property filters to build more advanced searches.
                </Box>
              ),
              hotspotId: TutorialHotspot.searchFlightPlan,
            },
            {
              title: "Page display options",
              content: (
                <Box variant="p">
                  Click the gear icon to change your view settings. You can choose which 
                  attribute columns are in the table, and how many Flight Plans are listed on each page. 
                  These settings are saved when you access TaCS from the same browser.
                </Box>
              ),
              hotspotId: TutorialHotspot.viewDisplayOptions,
            },
          ]
        }
      ],
    }
  ];

/**
 * Retrieves the corresponding hotspot based on the provided page.
 *
 * @param {PAGE} page - The current page being displayed.
 * @returns {string} The hotspot associated with the given page.
 */
export const getHotspotByPage = (page: PAGE) => {
  switch (page) {
  case PAGE.CAMPAIGNS:
    return HOT_SPOTS.CAMPAIGNS;
  case PAGE.FLIGHT_PLANS:
    return HOT_SPOTS.FLIGHT_PLANS;
  default:
    return HOT_SPOTS.CAMPAIGNS;
  }
};

/**
 * Retrieves the tutorials based on the provided page.
 *
 * @param {PAGE} page - The current page being displayed.
 * @param {(topic: string) => undefined} helpPanelHandler - A callback function to handle the help panel topic.
 * @returns {TutorialProps[]} An array of tutorials for the given page.
 */
export const geTutorialsByPage = (page: PAGE, helpPanelHandler: (topic: string) => undefined): TutorialProps[] => {
  let ids: TUTORIALS[] = [];
  switch (page) {
  case PAGE.CAMPAIGNS:
    ids = [TUTORIALS.MANAGE_CAMPAIGN];
    break;
  case PAGE.FLIGHT_PLANS:
    ids = [TUTORIALS.MANAGE_FLIGHT_PLAN];
    break;

  default:
    ids = [];
  }

  return tutorialData(helpPanelHandler).filter(tutorial => ids.includes(tutorial.id));
};

/**
 * Custom React hook that manages the available tutorials based on the current page.
 *
 * @param {(topic: string) => undefined} helpPanelHandler - A callback function to handle the help panel topic.
 * @returns {
*   tutorials: TutorialProps[],
*   setTutorialCompleted: (tutorialId: TUTORIALS, completed: boolean) => void
* } - An object containing the available tutorials and a function to set the completion status of a tutorial.
*/
export const useAvailableTutorials = (helpPanelHandler: (topic: string) => undefined) => {
  const pathname  = window.location.pathname;
  const [tutorials, setTutorials] = useState<TutorialProps[]>([]);
  useEffect(() => {
    const newTutorials = geTutorialsByPage(getPageName(pathname), helpPanelHandler);
    newTutorials.forEach(tutorial => tutorial.completed = false);
    setTutorials(newTutorials);
  }, [pathname]);

  const setTutorialCompleted = (tutorialId: TUTORIALS, completed: boolean) => {
    setTutorials(tutorials => {
      return tutorials.map((tutorial) => {
        if (tutorial.id === tutorialId) {
          return { ...tutorial, completed };
        }
        return tutorial;
      });
    });
  };

  return { tutorials, setTutorialCompleted };
};

export const overlayI18nStrings: AnnotationContextProps.I18nStrings = {
  stepCounterText: (stepIndex: number, totalStepCount: number) => (
    "Step " + (stepIndex + 1) + "/" + totalStepCount
  ),
  taskTitle: (taskIndex: number, taskTitle: string) => (
    "Task " + (taskIndex + 1) + ": " + taskTitle
  ),
  labelHotspot(openState: boolean, stepIndex: number, totalStepCount: number) {
    return openState
      ? "close annotation for step " + (stepIndex + 1) + " of " + totalStepCount
      : "open annotation";
  },
  nextButtonText: "Next",
  previousButtonText: "Previous",
  finishButtonText: "Finish",
  labelDismissAnnotation: "Dismiss annotation",
};

export const tutorialPanelI18nStrings = {
  loadingText: "Loading",
  tutorialListTitle: "Choose a tutorial",
  tutorialListDescription: "Tutorials teach you how to achieve a specific task in TaCS",
  tutorialListDownloadLinkText: "TaCS wiki",
  tutorialCompletedText: "Tutorial completed",
  labelExitTutorial: "dismiss tutorial",
  learnMoreLinkText: "Learn more",
  startTutorialButtonText: "Start tutorial",
  restartTutorialButtonText: "Restart tutorial",
  completionScreenTitle: "Congratulations! You completed the tutorial.",
  dismissTutorialButtonText: "Dismiss tutorial",
  taskTitle: (taskIndex: number, taskTitle: string) => `Task ${taskIndex + 1}: ${taskTitle}`,
  stepTitle: (stepIndex: number, stepTitle: string) => `Step ${stepIndex + 1}: ${stepTitle}`,
  labelTotalSteps: (totalStepCount: number) => `Total steps: ${totalStepCount}`,
  labelLearnMoreExternalIcon: "Learn more about this tutorial",
  labelsTaskStatus: { pending: "Pending", "in-progress": "In-progress", success: "Sucess", }
} as TutorialPanelProps.I18nStrings;
