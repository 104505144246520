import React, { FC, ReactElement } from "react";

import {
  Multiselect,
  FormField,
  Popover,
  Button
} from "@cloudscape-design/components";
import { IMultiselectInputProps } from "../../../data/interfaces/IFormInputs";
import styles from "./Inputs.module.css";

const MultiSelectInput: FC<IMultiselectInputProps> = (props: IMultiselectInputProps): ReactElement | null => {
  const {
    label,
    description,
    error,
    setErrors,
    isRequired,
    name,
    onInputChange,
    inputValue,
    options,
    testid,
    infoDescription,
  } = props;

  const errorText = error[name] != undefined ? error[name]["message"] : "";

  return (
    <FormField
      label={
        <>
          {label} -{" "}
          <small>
            <i>
              {isRequired ? (
                <span className={styles.required}>required</span>
              ) : (
                <span className={styles.optional}>optional</span>
              )}
            </i>
          </small>
        </>
      }
      description={description}
      info={
        <Popover header={label} content={infoDescription} triggerType="custom">
          <Button iconName="status-info" variant="inline-icon" data-testid={label + "InfoButton"}/>
        </Popover>
      }
      i18nStrings={{ errorIconAriaLabel: "Error" }}
      errorText={errorText}
      data-testid={testid}
    >
      <Multiselect
        selectedOptions={inputValue}
        onChange={(event) => {
          onInputChange(event.detail.selectedOptions, name);
          const updatedErrors = error;
          delete updatedErrors[name];
          setErrors(updatedErrors);
        }}
        options={options}
        placeholder="Choose options"
        selectedAriaLabel="Selected"
        data-testid={testid}
      />
    </FormField>
  );
};

export default MultiSelectInput;
