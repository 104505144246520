import React from "react";
import { Container, Alert } from "@cloudscape-design/components";


const isDev = process.env.NODE_ENV === "development";

const FallbackComponent: React.FC<Error> = ({ message, stack }) => (

  <Container>
    <Alert
      type="error"
      header="An error occurred while rendering this view"
    >
      <p>Try refreshing the page, and please contact our team if this issue persist.</p>

      <p>Error: <i>{message}</i></p>
      { isDev && <pre>{stack}</pre> }
    </Alert>
  </Container>
);


export default FallbackComponent;
