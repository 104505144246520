import React from "react";
import { Pagination } from "@cloudscape-design/components";

export interface IPaginationProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  items: any;
} 

const CloudscapeTablePagination = ({ currentPage, setCurrentPage, items }: IPaginationProps) => {
  return (<Pagination
    data-testid="tacs-pagination-component"
    currentPageIndex={currentPage}
    pagesCount={items?.page?.totalPages}
    ariaLabels={{
      nextPageLabel: "Next page",
      previousPageLabel: "Previous page",
      pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
    }}
    onNextPageClick={() => setCurrentPage(currentPage + 1)}
    onPreviousPageClick={() => setCurrentPage(currentPage - 1)}
    onChange={(detail) => {
      setCurrentPage(detail.detail.currentPageIndex);
    }}
  />);
};

export default CloudscapeTablePagination;