import React, { ReactElement, useEffect, useState } from "react";

import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import Checkbox from "@cloudscape-design/components/checkbox";
import Modal from "@cloudscape-design/components/modal";
import SpaceBetween from "@cloudscape-design/components/space-between";
import TextContent from "@cloudscape-design/components/text-content";

import { ACCESS_LEVEL, ENTITY_TYPE } from "../../data/constants/common";
import ArchiveModalTable from "./ArchiveModalTable";
import styles from "./Modal.module.css";

export interface ArchiveModalProps {
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  entities: any[];
  handleOnClickArchive: (archivableEntities: any[]) => void;
  entityType: string;
}

const ArchiveModal = ({
  isVisible,
  setVisible,
  entities,
  handleOnClickArchive,
  entityType,
}: ArchiveModalProps): ReactElement => {
  const archivableCampaigns = entities.filter(
    (entity) => entity.accessLevel === ACCESS_LEVEL.READ_WRITE
  );
  const unArchivableCampaigns = entities.filter(
    (entity) =>
      entity.accessLevel === ACCESS_LEVEL.READ_ONLY ||
    entity.accessLevel === ACCESS_LEVEL.UNKNOWN
  );
  const [doubleConfirmChecked, setDoubleConfirmChecked] = useState(false);
  const entityName =
    entityType === ENTITY_TYPE.CAMPAIGNS ? "Campaign" : "Flight plan";

  useEffect(() => {
    setDoubleConfirmChecked(false);
  }, [entities]);

  return (
    <Modal
      data-testid="archive-modal"
      size="large"
      onDismiss={() => setVisible(false)}
      visible={isVisible}
      footer={
        <Box float="right">
          <SpaceBetween
            direction="horizontal"
            size="xs"
            className={styles.footerContainer}
          >
            <Checkbox
              data-testid="double-confirm-checkbox"
              onChange={({ detail }) => setDoubleConfirmChecked(detail.checked)}
              checked={doubleConfirmChecked}
            >
              {`I understand that archived ${entityName}s cannot be recovered`}
            </Checkbox>
            <Button
              data-testid="cancel-archive-modal-button"
              variant="link"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="archive-modal-button"
              variant="primary"
              onClick={() => handleOnClickArchive(archivableCampaigns)}
              disabled={
                !doubleConfirmChecked || archivableCampaigns.length === 0
              }
            >
              Archive
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={`Archive ${entityName}`}
    >
      <TextContent>
        <div className={styles.warningText}>
          <p>
            {`Warning: Archiving a ${entityName} is a non-reversible process. Please
            review the below information below first before deciding to proceed.`}
          </p>
        </div>
        <p>The following will be archived:</p>
        <ArchiveModalTable
          entities={archivableCampaigns}
          entityType={entityType}
        />
        {unArchivableCampaigns.length > 0 && (
          <>
            <div className={styles.warningText}>
              <p>
                The following will NOT be archived as you do not have the
                permissions to do so:
              </p>
            </div>
            <ArchiveModalTable
              entities={unArchivableCampaigns}
              entityType={entityType}
            />
          </>
        )}
      </TextContent>
    </Modal>
  );
};

export default ArchiveModal;
