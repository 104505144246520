import React, { useContext, useState } from "react";
import {
  AttributeEditor,
  Box,
  Button,
  Container,
  ContentLayout,
  FlashbarProps,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  NonCancelableCustomEvent,
  Select,
  SpaceBetween,
  Table,
  Toggle,
} from "@cloudscape-design/components";
import ScreenLoader from "../../../components/common/ScreenLoader";
import { MessageContext, MessagesContextType } from "../../../context/MessagingContext";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { Attribute, MODEMetadata } from "../Interface";
import { BaseChangeDetail } from "@cloudscape-design/components/input/interfaces";
import { postNewBusinessData } from "../../../client/client";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { BUSINESS_ROUTE_PATHS, ENTITY_TYPE, REQUEST_METHOD } from "../../../data/constants/common";
import { attributeDataTypeOptions, entityOptions, assignments } from "../config";

type InvalidInput = {
  uidisplay: boolean;
  slug: boolean;
  entity: boolean;
  dictionary: boolean;
  governed: boolean;
}

type Props = {
  getIdToken: () => string;
  addMessage: (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string | undefined
  ) => void;
  navigate: NavigateFunction;
  email: string;
  isUpdate: boolean;
  state?: Attribute;
};

export const CreateAttribute: React.FC<Props> = ({
  getIdToken,
  addMessage,
  navigate,
  email,
  isUpdate,
  state
}) => {

  const [modeMetadata] = useState<MODEMetadata>({
    createdBy: email?.split("@")[0],
    creationDate: Date.now()
  });

  const [entityType, setEntityType] = useState<OptionDefinition>(
    state ? { label: state.entity.toUpperCase(), value: state.entity }
      : { label: entityOptions[0].label, value: entityOptions[0].value, }
  );
  const [attributeAssignment, setAttributeAssignment] = useState<OptionDefinition>(
    state ? { label: state.assignment.toUpperCase(), value: state.assignment }
      : { label: assignments[0].label, value: assignments[0].value }
  );
  const [attributeDataType, setAttributeDataType] = useState<OptionDefinition>(
    state ? { label: state.datatype.toUpperCase(), value: state.datatype }
      : { label: attributeDataTypeOptions[0].label, value: attributeDataTypeOptions[0].value }
  );

  const [valueItems, setValueItems] = useState([
    { uidisplay: "", slug: "", dictionary: "", active: false },
  ]);

  const [newAttribute, setNewAttribute] = useState<Attribute>(
    state
      ? { ...state }
      : {
        slug: "",
        uidisplay: "",
        entity: ENTITY_TYPE.CAMPAIGNS,
        governed: true,
        values: valueItems.filter(items => items.slug !== ""),
        assignment: "",
        datatype: "",
        dictionary: "",
        metadata: modeMetadata,
      }
  );
  const [invalidInput, setInvalidInput] = useState<InvalidInput>({
    uidisplay: false,
    entity: false,
    slug: false,
    dictionary: false,
    governed: false,
  });

  const onChangeValueInput = (value: unknown, itemIndex: number, name: string) => {
    const newItems = valueItems.map((currentItem, index) => {
      const result = [];
      if (index === itemIndex) {
        const updatedItem = { ...currentItem, [name]: value };
        result.push(updatedItem);
      } else result.push(currentItem);

      return result[0];
    });

    setValueItems(newItems);
    setNewAttribute({
      ...newAttribute, values: newItems,
    });
  };

  const handleInputChange = (
    name: string,
    e: NonCancelableCustomEvent<BaseChangeDetail>
  ) => {
    const { value } = e.detail;
    setInvalidInput({ ...invalidInput, [name]: false});
    setNewAttribute({
      ...newAttribute, [name]: value,
      entity: entityType.value as string,
      assignment: attributeAssignment.value as string,
      datatype: attributeDataType.value as string,
    });
  };

  const [posting, setPosting] = useState<boolean>(false);
  
  const handleSubmitBusinessData = async () => {
    if (isUpdate) {
      state?.values.concat(valueItems);
    }

    setPosting(true);
    await postNewBusinessData({
      newBusinessData: {attribute: { ...newAttribute }},
      method: isUpdate ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
      getIdToken,
      addMessage,
      navigate,
      urlParam: `attributes/${isUpdate ? newAttribute.slug : ""}`,
      navigateTo: BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC + "/",
      successMessage: `Attribute ${isUpdate ? "updated" : "created"} successfully!`,
      errorMessage: `Attribute ${isUpdate ? "update" : "creation"} failed, please try again!`,
      setPosting,
    });
  };

  return (
    <ContentLayout
      data-testid="create-attribute-page"
      header={
        <Header
          description=""
        >
          {`${isUpdate ? "Update" : "Create new"} attribute`}
        </Header>
      }
    >
      <form onSubmit={event => event.preventDefault()} data-testid="create-new-attribute-form">
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="cancel-btn"
                variant="link"
                onClick={() => navigate("/business-logic/")}
              >
                Cancel
              </Button>
              <Button
                data-testid="submit-btn"
                variant="primary"
                onClick={handleSubmitBusinessData}
              >
                {`${isUpdate ? "Update Attribute": "Create"}`}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="m">
            <Container
              header={
                <Header variant="h3">
                  Attribute details
                </Header>
              }
            >
              <>
                {posting && (
                  <ScreenLoader />
                )}
              </>
              <Grid
                gridDefinition={[{colspan: 6}, {colspan: 6}]}
              >
                <SpaceBetween size="l">
                  <FormField
                    label="Slug"
                    description="A unique identifier for an attribute - THIS CANNOT BE CHANGED LATER"
                    errorText={invalidInput.slug ? "This is a required field with at least 2 characters and ^[a-z0-9]+$." : ""}
                  >
                    <Input
                      data-testid="attribute-slug-input-field"
                      placeholder="Attribute slug name"
                      name={newAttribute.slug}
                      value={newAttribute.slug}
                      invalid={invalidInput.slug}
                      disabled={posting || isUpdate}
                      onChange={e => handleInputChange("slug", e)}
                      onBlur={() => {
                        const isInvalid = newAttribute["slug"].length < 2 
                          || !(/^[a-z0-9]+$/.test(newAttribute.slug));
                        setInvalidInput({...invalidInput, slug: isInvalid});
                      }}
                    />
                  </FormField>
                  <FormField
                    label="UI label"
                    description="Displayed to users in the TaCS UI and downstream reports"
                    errorText={invalidInput.uidisplay ? "This is a required field with at least 3 characters" : ""}
                  >
                    <Input
                      data-testid="attribute-uidisplay-input-field"
                      placeholder="UI Display"
                      name={newAttribute.uidisplay}
                      value={newAttribute.uidisplay}
                      invalid={invalidInput.uidisplay}
                      disabled={posting}
                      onChange={e => handleInputChange("uidisplay", e)}
                      onBlur={() => {
                        const isInvalid = newAttribute["uidisplay"].length < 3;
                        setInvalidInput({...invalidInput, uidisplay: isInvalid});
                      }}
                    />
                  </FormField>
                  <FormField
                    label="Assignment"
                    description="Whether the attribute is for all TaCS Businesses or a custom attribute"
                  >
                    <Select
                      data-testid="attribute-assignment-select-field"
                      placeholder="Attribute assignment"
                      disabled={posting || isUpdate}
                      selectedOption={attributeAssignment}
                      onChange={({ detail }) => {
                        setAttributeAssignment(detail.selectedOption);
                        setNewAttribute({
                          ...newAttribute,
                          assignment: attributeAssignment.value as string,
                        });
                      }}
                      options={assignments}
                    />
                  </FormField>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <FormField
                    label="Entity type"
                    description="Which entity does the attribute belong to"
                  >
                    <Select
                      data-testid="attribute-entity-option-selection"
                      placeholder="Select entity"
                      selectedOption={entityType}
                      disabled={posting}
                      onChange={({ detail }) => {
                        setEntityType(detail.selectedOption);
                        setNewAttribute({
                          ...newAttribute,
                          entity: entityType.value as string,
                        });
                      }}
                      options={entityOptions}
                    />
                  </FormField>
                  <FormField
                    label="Dictionary"
                    description="An unambiguous descriptive definition of the attribute"
                    errorText={invalidInput.dictionary ? "This is a required field with at least 3 characters" : ""}
                  >
                    <Input
                      data-testid="attribute-dictionary-input-field"
                      placeholder="Attribute dictionary"
                      name={newAttribute.dictionary}
                      value={newAttribute.dictionary}
                      invalid={invalidInput.dictionary}
                      disabled={posting}
                      onChange={e => handleInputChange("dictionary", e)}
                      onBlur={() => {
                        const isInvalid = newAttribute["dictionary"].length < 3;
                        setInvalidInput({...invalidInput, dictionary: isInvalid});
                      }}
                    />
                  </FormField>
                  <FormField
                    label="Data type"
                    description="The data type of the attribute's values"
                  >
                    <Select
                      data-testid="attribute-data-type-option-selection"
                      placeholder="Select data type"
                      selectedOption={attributeDataType}
                      disabled={posting}
                      onChange={({ detail }) => {
                        setAttributeDataType(detail.selectedOption);
                        setNewAttribute({
                          ...newAttribute,
                          datatype: attributeDataType.value as string,
                        });
                      }}
                      options={attributeDataTypeOptions}
                    />
                  </FormField>
                </SpaceBetween>
              </Grid>
            </Container>
            <Container
              header={
                <Header variant="h3">
                  Value details
                </Header>
              }
            >
              <SpaceBetween size="xs">
                {isUpdate && (<div>
                  <Table
                    data-testid="existing-custom-values-table"
                    header={
                      <Header
                        variant="h3"
                        counter={"Existing attribute values"}
                      />
                    }
                    empty={
                      <Box textAlign="center" color="inherit">
                        <b>No custom attribute found</b>
                        <Box padding={{ top: "s" }} variant="p">
                          No custom attribute to display.
                        </Box>
                      </Box>
                    }
                    variant="embedded"
                    stripedRows
                    resizableColumns
                    stickyHeader
                    items={state?.values ? state.values : []}
                    columnDefinitions={[
                      {
                        id: "values-ui-display",
                        header: "UI display",
                        minWidth: 176,
                        cell: item => item.uidisplay,
                      },
                      {
                        id: "values-slug",
                        header: "Slug",
                        minWidth: 176,
                        cell: item => item.slug,
                      },
                      {
                        id: "values-dictionary",
                        header: "Dictionary",
                        minWidth: 176,
                        cell: item => item.dictionary,
                      },
                      {
                        id: "values-active",
                        header: "Status",
                        minWidth: 176,
                        cell: item => {

                          return (
                            <Toggle
                              data-testid={`${item.slug}-toggle-status`}
                              ariaLabel={`${item.active ? "Deactivate" : "Activate"} ${item.uidisplay}`}
                              checked={item.active}
                              onChange={() => {
                                const newItems = state?.values ? [...state.values] : [];
                                newItems.map(currentItem => {
                                  if (currentItem.slug === item.slug) {
                                    currentItem.active = !currentItem.active;
                                  }
                                });
                                setNewAttribute({...newAttribute, values: newItems});
                              }}
                            >
                              {item.active ? "Deactivate" : "Activate"}
                            </Toggle>
                          );
                        },
                      }
                    ]}
                  />
                  {state?.values.length && (
                    <Header
                      variant="h3"
                      counter={"Add additional values"}
                    />
                  )}
                </div>)}
                <AttributeEditor
                  addButtonText={"Add new item"}
                  removeButtonText={"Remove item"}
                  onAddButtonClick={() => setValueItems(
                    [...valueItems, {uidisplay: "", slug: "", dictionary: "", active: false}]
                  )}
                  onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tempItems = [...valueItems];
                    tempItems.splice(itemIndex, 1);
                    setValueItems(tempItems);
                    setNewAttribute({...newAttribute, values: tempItems});
                  }}
                  items={valueItems}
                  definition={[
                    {
                      label: "UI label",
                      control: (item, itemIndex) => (
                        <Input
                          data-testid="attr-value-uilabel-input"
                          disabled={posting}
                          value={item.uidisplay}
                          name={item.uidisplay}
                          placeholder="Enter UI display text"
                          onChange={({detail: { value }}) => onChangeValueInput(value, itemIndex, "uidisplay")}
                        />
                      ),
                    },
                    {
                      label: "Slug",
                      control: (item, itemIndex) => (
                        <Input
                          data-testid="attr-value-slug-input"
                          disabled={posting}
                          value={item.slug}
                          name={item.slug}
                          placeholder="Enter slug value"
                          onChange={({detail: { value }}) => onChangeValueInput(value, itemIndex, "slug")}
                        />
                      ),
                    },
                    {
                      label: "Dictionary",
                      control: (item, itemIndex) => (
                        <Input
                          data-testid="attr-value-dictionary-input"
                          disabled={posting}
                          value={item.dictionary}
                          name={item.dictionary}
                          placeholder="Enter attribute description"
                          onChange={({detail: { value }}) => onChangeValueInput(value, itemIndex, "dictionary")}
                        />
                      ),
                    },
                    {
                      label: "Status",
                      control: (item, itemIndex) => (
                        <Toggle
                          ariaLabel={`${item.active ? "Deactivate" : "Activate"} ${item.uidisplay}`}
                          data-testid="attr-value-status-input"
                          controlId="attr-value-status-input"
                          disabled={posting}
                          checked={item.active}
                          onChange={({detail: { checked }}) => onChangeValueInput(checked, itemIndex, "active")}
                        >
                          {item.active ? "Deactivate" : "Activate"}
                        </Toggle>
                      ),
                    },
                  ]}
                  empty={<p>Click on <b><i>Add new items</i></b> to add new attribute value</p>}
                />
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};

export const CreateAttributeContainer: React.FC = (): JSX.Element => {
  const { getIdToken, userAttributes } = useContext(AuthContext);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const navigate = useNavigate();
  const { state }: { state: Attribute } = useLocation();
  const isUpdate = !!state;

  return (
    <CreateAttribute
      data-testid="create-new-attribute-container"
      email={userAttributes?.email}
      getIdToken={getIdToken}
      navigate={navigate}
      addMessage={addMessage}
      state={state}
      isUpdate={isUpdate}
    />
  );
};
