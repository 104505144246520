import {
  INVALID_INPUT,
  NO_ACCESS_TO_BU,
  DOES_NOT_EXIST,
  DEFAULT_MESSAGE,
  ALREADY_EXISTS,
  INTERNAL_SERVER_MESSAGE,
} from "../constants/errorMessages";

export const returnErrorMessage = (
  responseStatus: number,
  businessunitSlug?: string,
  resourceName?: string
) => {
  switch (responseStatus) {
  case 400:
    return INVALID_INPUT;
  case 403:
    return NO_ACCESS_TO_BU(businessunitSlug);
  case 404:
    return DOES_NOT_EXIST(resourceName);
  case 409:
    return ALREADY_EXISTS(resourceName);
  case 500:
    return INTERNAL_SERVER_MESSAGE;
  default:
    return DEFAULT_MESSAGE;
  }
};
