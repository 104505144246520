import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Container,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import {
  API_CALL_NAME,
  ENTITY_TYPE,
  REQUEST_ID_SELECTOR,
} from "../../data/constants/common";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import TaCSIDSelector from "../../components/form/inputs/TaCSIDSelector";
import { getEnvironmentVariables } from "../../data/constants/environment";
import { AuthContext } from "../../context/AuthContext";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import DynamicIDInput from "../../components/form/inputs/DynamicIDInput";
import { useNavigate } from "react-router-dom";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";
import {
  csvDownload,
  generateTemplateDownloadCSVName,
} from "../../data/helpers/utils";

interface IRequestIDProps {
  businessUnitSlug: string;
  tacsId: string;
}

const RequestIDsView: FC<object> = (): ReactElement | null => {
  const navigate = useNavigate();
  const { selectedBG, getIdToken } = useContext(AuthContext);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const [selector, setSelector] = useState(REQUEST_ID_SELECTOR.WITH_TACS_ID);
  const [campaignName, setCampaignName] = useState("");
  const [flightPlan, setFlightPlan] = useState<OptionDefinition | null>(null);
  const [countOfStandaloneIDs, setCountOfStandaloneIDs] = useState("0");
  const [countOfPackages, setCountOfPackages] = useState("0");
  const [flightPlanList, setFlightPlanList] = useState<any[]>([]);
  const [status, setStatus] =
    useState<DropdownStatusProps.StatusType>("loading");
  const [invalidCount, setInvalidCount] = useState<boolean[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [requestID, setRequestID] = useState<IRequestIDProps>({
    businessUnitSlug: "",
    tacsId: "",
  });

  const [invalidField, setInvalidField] = useState({
    businessUnitSlug: false,
    tacsId: false,
    flightPlan: false,
    countOfStandaloneIDs: false,
  });

  const [idCount, setIdCount] = useState<Array<string>>([]);

  const resetForm = () => {
    setSelector(REQUEST_ID_SELECTOR.WITH_TACS_ID);
    setRequestID({ businessUnitSlug: "", tacsId: "" });
    setCampaignName("");
    setFlightPlan(null);
    setCountOfStandaloneIDs("0");
    setCountOfPackages("0");
    setFlightPlanList([]);
    setStatus("loading");
    setCurrentPage(1);
    setIdCount([]);
  };

  const businessUnitList = selectedBG
    ? Object.entries(selectedBG.businessUnits)
      .map(([, value]) => {
        return {
          value: value.businessUnitSlug,
          label: value.businessUnitName,
          isAccessReadOnly: value.isAccessReadOnly,
        };
      })
      .filter((bu) => !bu.isAccessReadOnly)
    : [];

  const fetchFlightPlansUsingTaCSID = async (
    tacsID: string,
    isNewList = true
  ) => {
    const page = isNewList ? 1 : currentPage;
    const url = `${getEnvironmentVariables().API_ENDPOINT}/${
      API_CALL_NAME.FLIGHT_PLANS
    }/?tacsId=${tacsID}&${encodeURI(
      businessUnitList.map((bu) => `bu=${bu.value}`).join("&")
    )}&currPage=${page}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
      });
      const data = await response.json();
      if (response.ok) {
        const newList = data.flightplans
          .filter((flightPlan: any) => flightPlan.accessLevel === "rw")
          .map((flightPlan: any) => {
            return { value: flightPlan.id, label: flightPlan.name };
          });
        setCurrentPage(page + 1);
        isNewList
          ? setFlightPlanList(newList)
          : setFlightPlanList([...flightPlanList, ...newList]);
        page > data.page.totalPages
          ? setStatus("finished")
          : setStatus("pending");
        if (data.page.totalElements === 0) {
          handleOnErrorMessage("No Flight Plans found for this Campaign");
        }
      } else {
        handleOnErrorMessage("Unable to fetch flight plans!");
        setStatus("error");
      }
    } catch (error: any) {
      handleOnErrorMessage("Unable to fetch flight plans!");
      setStatus("error");
    }
  };

  const handleOnLoadFlightPlans = (detail: any) => {
    if (!detail.firstPage) {
      setStatus("loading");
      const isNewList = false;
      fetchFlightPlansUsingTaCSID(requestID.tacsId, isNewList);
    }
  };

  useEffect(() => {
    if (requestID.tacsId) {
      fetchFlightPlansUsingTaCSID(requestID.tacsId);
    }
  }, [campaignName]);

  useEffect(() => {
    setFlightPlan(null);
  }, [requestID]);

  const buildRequestIdPayload = () => {
    const miidRequests = [];
    if (parseInt(countOfStandaloneIDs) > 0) {
      miidRequests.push({
        count: parseInt(countOfStandaloneIDs),
        flightPlanId: flightPlan?.value,
        mediaItemType: "standalone",
      });
    }

    return JSON.stringify({
      miidRequests: [
        ...miidRequests,
        ...idCount.map((count) => {
          return {
            count: parseInt(count),
            flightPlanId: flightPlan?.value,
            mediaItemType: "package",
          };
        }),
      ],
    });
  };

  const handleOnSuccessMessage = (input: any) => {
    setIsSubmitLoading(false);
    addMessage({
      type: "success",
      content: "Media IDs requested successfully!",
      dismissible: true,
      action: (
        <Button
          data-testid="redownload-button"
          onClick={() =>
            csvDownload(
              input,
              null,
              generateTemplateDownloadCSVName(
                ENTITY_TYPE.MEDIA_ID_REQUEST,
                selectedBG?.businessGroupName || "",
                true
              ),
              "REQUEST_MEDIA_ID_RESPONSE"
            )
          }
        >
          Re-download
        </Button>
      ),
    });
  };

  const handleOnErrorMessage = (message: string) => {
    setIsSubmitLoading(false);
    addMessage({
      type: "error",
      content: message,
      dismissible: true,
    });
  };

  const postRequestMediaIDs = async () => {
    const url = `${getEnvironmentVariables().API_ENDPOINT}/${
      API_CALL_NAME.MEDIA_ITEMS
    }/miid/${requestID.businessUnitSlug}/request`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        body: buildRequestIdPayload(),
      });
      const data = await response.json();

      if (response.ok) {
        csvDownload(
          data["miidResponses"],
          null,
          generateTemplateDownloadCSVName(
            ENTITY_TYPE.MEDIA_ID_REQUEST,
            selectedBG?.businessGroupName || "",
            true
          ),
          "REQUEST_MEDIA_ID_RESPONSE"
        );
        resetForm();
        handleOnSuccessMessage(data["miidResponses"]);
      } else {
        handleOnErrorMessage("Requesting media IDs failed!");
      }
    } catch (error) {
      handleOnErrorMessage("Requesting media IDs failed!");
    }
  };

  const handleOnClickCancel = () => {
    navigate(-1);
  };

  const validateForm = () => {
    let isValidated = true;
    //individual input error status
    Object.entries(requestID).forEach((obj) => {
      if (!obj[1]) {
        setInvalidField((prevState) => ({
          ...prevState,
          [obj[0]]: true,
        }));
        isValidated = false;
      }
    });

    if (flightPlan === null) {
      setInvalidField((prevState) => ({
        ...prevState,
        flightPlan: true,
      }));
      isValidated = false;
    }

    invalidCount.forEach((invalid) => {
      if (invalid) {
        isValidated = false;
      }
    });

    if (!isValidated) {
      addMessage({
        type: "error",
        content: "Please fill out all required fields!",
        dismissible: true,
      });
      return false;
    }

    if (countOfStandaloneIDs === "0" && countOfPackages === "0") {
      addMessage({
        type: "error",
        content: "Please request at least one ID!",
        dismissible: true,
      });
      return false;
    }

    return true;
  };

  const handleOnClickSubmit = () => {
    if (!validateForm()) {
      return;
    }
    setIsSubmitLoading(true);
    postRequestMediaIDs();
  };

  const handleOnChangeNumericInput = (
    value: string,
    setState: (value: React.SetStateAction<string>) => void,
    upperLimit: number
  ) => {
    const intValue = parseInt(value);
    if (
      (isNaN(intValue) && value !== "") ||
      intValue < 0 ||
      intValue > upperLimit
    ) {
      return;
    }
    setState(value !== "" ? intValue.toString() : "");
  };

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="Generate Media Item IDs without submitting metadata"
        >
          Request IDs
        </Header>
      }
    >
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid="cancel-button"
              variant="link"
              onClick={() => handleOnClickCancel()}
            >
              Cancel
            </Button>
            <Button
              data-testid="submit-button"
              loading={isSubmitLoading}
              variant="primary"
              onClick={() => handleOnClickSubmit()}
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="xl">
          <Container
            header={
              <Header 
                variant="h2" 
                description="Your Media Items will be created within the Flight Plan you choose and cannot be moved once created"
              >
                Select Campaign and Flight Plan
              </Header>
            }
          >
            <SpaceBetween size="l">
              <TaCSIDSelector
                isEdit={false}
                selector={selector}
                tacsID={requestID.tacsId}
                campaignName={campaignName}
                businessUnit={requestID.businessUnitSlug}
                setSelector={setSelector}
                setCampaignName={setCampaignName}
                setTacsID={(tacsId) => {
                  setRequestID((prevState) => {
                    return {
                      ...prevState,
                      tacsId: tacsId,
                    };
                  });
                  setInvalidField({ ...invalidField, tacsId: false });
                }}
                setBusinessUnit={(businessUnitSlug) => {
                  setRequestID((prevState) => {
                    return {
                      ...prevState,
                      businessUnitSlug: businessUnitSlug,
                    };
                  });
                  setInvalidField({
                    ...invalidField,
                    businessUnitSlug: false,
                  });
                }}
                invalidField={invalidField}
                setInvalidField={setInvalidField}
              />
              <FormField
                label="Flight Plan"
                description="Select the Flight Plan where the Media Item IDs will be created"
                errorText={
                  invalidField.flightPlan && "Please select a flight plan"
                }
              >
                <Select
                  data-testid="flight-plan-select"
                  invalid={invalidField.flightPlan}
                  disabled={!requestID.tacsId || flightPlanList.length === 0}
                  selectedOption={flightPlan}
                  options={flightPlanList}
                  loadingText="Loading flight plans"
                  placeholder="Choose flight plan"
                  statusType={status}
                  onChange={({ detail }) => {
                    setFlightPlan(detail.selectedOption);
                    setInvalidField((prevState: any) => {
                      return { ...prevState, flightPlan: false };
                    });
                  }}
                  onLoadItems={({ detail }) => handleOnLoadFlightPlans(detail)}
                />
              </FormField>
            </SpaceBetween>
          </Container>
          <Container
            header={
              <Header 
                variant="h2"
                description="View the TaCS Documentation link in the left sidebar to learn more about Media Item types"
              >
                Set the number of Media Item IDs to be created
              </Header>
            }
          >
            <SpaceBetween size="l">
              <FormField
                label="Number of standalone IDs"
                description=""
                errorText={
                  invalidField.countOfStandaloneIDs &&
                  "ID count needs to be between 0 and 500"
                }
              >
                <Input
                  invalid={invalidField.countOfStandaloneIDs}
                  data-testid="request-id-standalone-input"
                  onChange={({ detail }) => {
                    handleOnChangeNumericInput(
                      detail.value,
                      setCountOfStandaloneIDs,
                      500
                    );
                  }}
                  value={countOfStandaloneIDs}
                  inputMode="numeric"
                />
              </FormField>
              <FormField label="Number of packages" description="">
                <Input
                  data-testid="request-id-packages-input"
                  onChange={({ detail }) =>
                    handleOnChangeNumericInput(
                      detail.value,
                      setCountOfPackages,
                      25
                    )
                  }
                  value={countOfPackages}
                  inputMode="numeric"
                />
              </FormField>
              {parseInt(countOfPackages) ? (
                <DynamicIDInput
                  idCount={idCount}
                  setIdCount={setIdCount}
                  packageCount={parseInt(countOfPackages)}
                  invalidCount={invalidCount}
                  setInvalidCount={setInvalidCount}
                />
              ) : (
                <></>
              )}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Form>
    </ContentLayout>
  );
};

export default RequestIDsView;
