/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from "react";
import { useAvailableTutorials } from "./Tutorials";
import { AppLayoutProps, TutorialPanelProps } from "@cloudscape-design/components";
import { TUTORIALS, TutorialHotspot } from "./constants";
import { AuthContext } from "../../context/AuthContext";
import { PATHS } from "../../data/constants/navigation";

declare global {
  interface Window {
    Cypress?: any;
  }
}

export type TutorialInfoTopic = TutorialHotspot;
type State = {
  tutorials: TutorialPanelProps.Tutorial[];
  toolsOpen: boolean;
  toolsTab: HelpPanelTabs;
  toolsHide: boolean;
  helpPanelTopic: string;
}
type Actions = {
  setToolsTab: Dispatch<SetStateAction<HelpPanelTabs>>;
  setHelpPanelTopic: Dispatch<SetStateAction<string>>;
  setToolsOpen: (openTool: boolean) => void;
  setTutorialCompleted: (tutorialId: TUTORIALS, completed: boolean) => void;
}
export enum HelpPanelTabs {
  INFO = "Info",
  TUTORIAL = "Tutorial",
}

export type TutorialContext = {
  state: State;
  actions: Actions;
}
const context: TutorialContext = {
  state: {
    tutorials: [],
    toolsOpen: true,
    toolsTab: HelpPanelTabs.TUTORIAL,
    toolsHide: true,
    helpPanelTopic: TutorialHotspot.manageCampaign,
  },
  actions: {
    setToolsTab: () => {},
    setHelpPanelTopic: () => {},
    setTutorialCompleted: () => {},
    setToolsOpen: () => {}
  },

};
export const TutorialContent = createContext<TutorialContext>(context);

interface Props {
  children: React.ReactNode;
}

const TutorialProvider: React.FC<Props> = ({ children }) => {
  const { activeHref } = useContext(AuthContext);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [toolsTab, setToolsTab] = useState(HelpPanelTabs.TUTORIAL);
  const [helpPanelTopic, setHelpPanelTopic] = useState("default");

  const appLayoutRef = useRef<AppLayoutProps.Ref>(null);

  /**
   * If true, the tools drawer is not displayed at all.
   * `toolsOpen` attributes manages the state of the tools panel
   * when the tools drawer is displayed, i.e when this is false.
   */
  const hideTools = () => {

    // Hidetools/Disable for prod until product okays tutorial content / feature release
    if (window.Cypress) return true;

    // Only enable for Campaigns
    if (activeHref === `/${PATHS.CAMPAIGNS}` || activeHref === `/${PATHS.FLIGHT_PLANS}`) {
      return false;
    }
    // Hide for all others until tutorials are available on those pages
    return true;
  };
  
  const makeHelpPanelHandler = useCallback((topic: string) => {
    setHelpPanelTopic(topic);
    setToolsOpen(true);
    appLayoutRef.current?.focusToolsClose();

    return undefined;
  }, []);

  const { tutorials, setTutorialCompleted } = useAvailableTutorials(makeHelpPanelHandler);
  const data: TutorialContext = {
    state: {
      tutorials,
      toolsOpen,
      toolsTab,
      toolsHide: hideTools(),
      helpPanelTopic,
    },
    actions: {
      setHelpPanelTopic,
      setToolsOpen,
      setToolsTab,
      setTutorialCompleted,
    },
  };

  return (
    <div data-testid="tutorial-context-provider">
      <TutorialContent.Provider
        value={data}
      >
        {children}
      </TutorialContent.Provider>
    </div>
  );
};

const useTutorialContext = () => useContext(TutorialContent);

export { TutorialProvider, useTutorialContext, };
