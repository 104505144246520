import { PropertyFilterProps } from "@cloudscape-design/components";
import { SelectedBGLocalStorage } from "../../data/constants/localStorage";
import { ISettingsMap, ITableSettings } from "../../data/interfaces/ITable";

export const getSavedPreferencesFromLocalStorage = (
  resourceName: string,
  visibleContentDefault: any
): ITableSettings => {
  const tableSettings: string | null = window.localStorage.getItem(
    `${resourceName}Table`
  );
  const parsedSettings: ISettingsMap = tableSettings
    ? JSON.parse(tableSettings)
    : {};
  const currBusinessGroupData: any = localStorage.getItem(
    SelectedBGLocalStorage.SELECTED_BG_NAME
  );
  const parsedBGData = currBusinessGroupData
    ? JSON.parse(currBusinessGroupData)
    : "";
  const currBusinessGroup = parsedBGData
    ? parsedBGData?.businessGroupSlug
    : null;

  let preferencesData = {
    pageSize: 10,
    wraplines: true,
    visibleContent: visibleContentDefault,
  };
  if (parsedSettings[currBusinessGroup])
    preferencesData = parsedSettings[currBusinessGroup];

  return preferencesData;
};

export const getSavedSearchFiltersLocalStorage = (
  entityType: string,
  isAdmin = false 
): PropertyFilterProps.Query => {

  const defaultQuery: PropertyFilterProps.Query = {
    tokens: [],
    operation: "and",
  };

  let storageKey: string;

  if (!isAdmin) {
    let currBusinessGroup: string | null = null;
    const businessGroupJSON = localStorage.getItem(SelectedBGLocalStorage.SELECTED_BG_NAME);
    
    if (businessGroupJSON) {
      try {
        const parsedBGData = JSON.parse(businessGroupJSON);
        currBusinessGroup = parsedBGData.businessGroupSlug ?? null;
      } catch (error) {
        console.error("Failed to parse business group data from localStorage:", error);
      }
    }

    if (!currBusinessGroup) {
      return defaultQuery;
    }

    storageKey = `${entityType}SearchFilter`;

    try {
      const storedFilters = localStorage.getItem(storageKey);
      if (storedFilters) {
        const parsedFilters = JSON.parse(storedFilters);

        if (parsedFilters && typeof parsedFilters === "object") {
          const groupQuery = parsedFilters[currBusinessGroup];
          if (groupQuery && typeof groupQuery === "object") {
            return groupQuery;
          }
        }
      }
    } catch (error) {
      console.error("Failed to load search filters from localStorage:", error);
    }

    return defaultQuery;
  } else {

    storageKey = `${entityType}SearchFilter`;

    try {
      const storedFilters = localStorage.getItem(storageKey);
      if (storedFilters) {
        const parsedFilters = JSON.parse(storedFilters);
        if (parsedFilters && typeof parsedFilters === "object") {
          return parsedFilters as PropertyFilterProps.Query;
        }
      }
    } catch (error) {
      console.error("Failed to load search filters from localStorage:", error);
    }

    return defaultQuery;
  }
};