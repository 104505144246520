import React from "react";
import { Attribute, BusinessGroup } from "../Interface";
import { Checkbox, Spinner, Tabs } from "@cloudscape-design/components";
import { ENTITY_TYPE } from "../../../data/constants/common";

type Props = {
  newBusinessGroup: BusinessGroup;
  setNewBusinessGroup: React.Dispatch<React.SetStateAction<BusinessGroup>>;
  isLoading: boolean;
  serverError: Error | null;
  data: any;
}

export const AttributesItems: React.FC<Props> = ({
  isLoading,
  serverError,
  data,
  newBusinessGroup,
  setNewBusinessGroup,
}): JSX.Element => {


  return (
    <Tabs
      key={"attributes-tabs"}
      data-testid="create-bg-attributes-tabs"
      tabs={[
        {
          id: "campaigns-tab",
          label: "Campaign",
          content: <AttributesOfEntityType
            attributes={data.attributes} 
            isLoading={isLoading}
            serverError={serverError}
            entityType={ENTITY_TYPE.CAMPAIGNS}
            newBusinessGroup={newBusinessGroup}
            setNewBusinessGroup={setNewBusinessGroup}
          />
        },
        {
          id: "flight-plans-tab",
          label: "Flight Plan",
          content: <AttributesOfEntityType
            attributes={data.attributes}
            isLoading={isLoading}
            serverError={serverError}
            entityType={ENTITY_TYPE.FLIGHT_PLANS}
            newBusinessGroup={newBusinessGroup}
            setNewBusinessGroup={setNewBusinessGroup}
          />
        },
        {
          id: "media-items-tab",
          label: "Media Item",
          content: <AttributesOfEntityType
            attributes={data.attributes}
            isLoading={isLoading}
            serverError={serverError}
            entityType={ENTITY_TYPE.MEDIA_ITEMS}
            newBusinessGroup={newBusinessGroup}
            setNewBusinessGroup={setNewBusinessGroup}
          />
        },
      ]}
    />
  );
};

export type AttributesOfEntity = {
  entityType: string;
  attributes: Attribute[];
  isLoading: boolean;
  serverError: Error | null;
  newBusinessGroup: BusinessGroup;
  setNewBusinessGroup: React.Dispatch<React.SetStateAction<BusinessGroup>>;
}

export const  AttributesOfEntityType: React.FC<AttributesOfEntity> = ({
  entityType,
  attributes,
  isLoading,
  serverError,
  newBusinessGroup,
  setNewBusinessGroup,
}): JSX.Element => {

  if (serverError) {
    return (
      <span data-testid="fetching-attributes-list-error-text">
        Oops encouter an error loading available attributes for {entityType}.
      </span>
    );
  }

  if (isLoading) {
    return (
      <span data-testid="attributes-loading-spinner">
        <Spinner />
        Fetching available attributes for {entityType}.
      </span>
    );
  }

  const attributesOfEntityType = attributes.filter(attribute => attribute.entity === entityType && attribute.assignment === "custom");
  const newCustomAttributeSlugList = newBusinessGroup.customAttributesSlugList ? newBusinessGroup.customAttributesSlugList : [];

  return (
    <>
      <h4 data-testid="select-attributes-subheading">Assign Attributes</h4>
      <div style={{columnCount: "2"}}>
        {
          attributesOfEntityType.length ?
            (
              attributesOfEntityType
                .map((attribute: Attribute, idx: number) => (
                  <Checkbox
                    key={`${idx}-${attribute.slug}`}
                    data-testid={`attribute-items-${idx}`}
                    checked={newCustomAttributeSlugList.some(item => item === attribute.slug)}
                    onChange={() => {
                      // If user uncheck an attribute, remove from list otherwise add item to list.
                      if (newCustomAttributeSlugList.some(item => item === attribute.slug)) {
                        setNewBusinessGroup({
                          ...newBusinessGroup,
                          customAttributesSlugList: newCustomAttributeSlugList.filter(item => item !== attribute.slug),
                        });
                      } else {
                        setNewBusinessGroup({
                          ...newBusinessGroup,
                          customAttributesSlugList: newCustomAttributeSlugList.concat(attribute.slug),
                        });
                      }
                    }}
                    name={attribute.slug}
                  >
                    {attribute.uidisplay}
                  </Checkbox>
                ))
            )
            :
            <p data-testid="attribute-items-non-available-text">
              No available attributes for {entityType}.
            </p>
        }
      </div>
    </>
  );
};
