import {
  Button,
  Container,
  ContentLayout,
  ExpandableSection,
  FlashbarProps,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  NonCancelableCustomEvent,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useState, useContext } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { BUSINESS_ROUTE_PATHS, REQUEST_METHOD } from "../../../data/constants/common";
import { AuthContext } from "../../../context/AuthContext";
import { AttributesItems } from "./AttributesItems";
import { postNewBusinessData, useFetchBusinessData } from "../../../client/client";
import { MessageContext, MessagesContextType } from "../../../context/MessagingContext";
import { BaseChangeDetail } from "@cloudscape-design/components/input/interfaces";
import { BUSINESS_PROPERTY, BusinessGroup, MODEMetadata } from "../Interface";
import ScreenLoader from "../../../components/common/ScreenLoader";

type Props = {
  getIdToken: () => string;
  email: string;
  addMessage: (
    message: FlashbarProps.MessageDefinition,
    messageData?: any,
    errorMessage?: string | undefined
  ) => void;
  navigate: NavigateFunction;
};

export const CreateBusinessGroup: React.FC<Props> = ({
  getIdToken,
  email,
  addMessage,
  navigate,
}): JSX.Element => {

  const { state } = useLocation();
  const isUpdate = state ? true : false;
  const [modeMetadata] = useState<MODEMetadata>({
    createdBy: email,
    creationDate: Date.now(),
  });

  const [pageSize, setPageSize] = useState<number>(20);
  const { isLoading, data, serverError }
  = useFetchBusinessData({
    urlPath: `${BUSINESS_ROUTE_PATHS.ATTRIBUTES}/?pageSize=${pageSize}`,
    method: REQUEST_METHOD.GET,
    getTokenFn: getIdToken,
  });

  const [newBusinessGroup, setNewBusinessGroup] = useState<BusinessGroup>(
    state
      ? { ...state }
      : {
        businessGroupSlug: "",
        businessGroupName: "",
        businessGroupOrg: "",
        customAttributesSlugList: [],
        metadata: modeMetadata,
      }
  );
  const [invalidInput, setInvalidInput] = useState({
    businessGroupSlug: false,
    businessGroupName: false,
    businessGroupOrg: false,
  });

  const handleInputChange = (
    name: string,
    e: NonCancelableCustomEvent<BaseChangeDetail>
  ) => {

    setInvalidInput({ ...invalidInput, [name]: false});
    setNewBusinessGroup({
      ...newBusinessGroup, [name]: e.detail.value,
    });
  };
  const [posting, setPosting] = useState<boolean>(false);
  const handleSubmitBusinessData = async () => {

    setPosting(true);
    await postNewBusinessData({
      newBusinessData: { businessGroup: { ...newBusinessGroup } },
      method: isUpdate ? REQUEST_METHOD.PUT : REQUEST_METHOD.POST,
      getIdToken,
      addMessage,
      navigate,
      urlParam: `businessgroup/${isUpdate ? newBusinessGroup.businessGroupSlug : ""}`,
      navigateTo: BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT + BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS + "/",
      successMessage: `${BUSINESS_PROPERTY.BUSINESS_GROUP} ${isUpdate ? "updated" : "created"} successfully!`,
      errorMessage: `${BUSINESS_PROPERTY.BUSINESS_GROUP} ${isUpdate ? "update" : "creation"} failed, please try again!`,
      setPosting,
    });
  };

  return (
    <ContentLayout
      header={
        <Header
          variant="h2"
        >
          {`${isUpdate ? "Update" : "Create new"} ${BUSINESS_PROPERTY.BUSINESS_GROUP}`}
        </Header>
      }
    >
      <form
        data-testid="business-group-form"
        onSubmit={event => event.preventDefault()}
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="new-bg-cancel-btn"
                variant="link"
                onClick={() => navigate(BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT + BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS + "/")}
              >
                Cancel
              </Button>
              <Button
                data-testid="create-new-bg-submit-btn"
                variant="primary"
                onClick={handleSubmitBusinessData}
              >
                {`${isUpdate ? "Update": "Create"}`}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="m">
            <Container >
              <ExpandableSection
                defaultExpanded
                headerText="Basic details"
                data-testid="create-bg-basic-details-section"
              >
                <>
                  {posting && (
                    <ScreenLoader />
                  )}
                </>
                <SpaceBetween size="m">
                  <Grid
                    gridDefinition={[{colspan: 6}, {colspan: 6}]}
                  >
                    <FormField
                      label={`${BUSINESS_PROPERTY.BUSINESS_GROUP} slug`}
                      description={`Enter the slug for the new ${BUSINESS_PROPERTY.BUSINESS_GROUP} - THIS CANNOT BE CHANGED LATER`}
                      errorText={invalidInput.businessGroupSlug ? "This is a required field with of at least 2 characters and ^[a-z0-9]+$" : ""}
                    >
                      <Input
                        data-testid="business-group-slug-input"
                        placeholder={`${BUSINESS_PROPERTY.BUSINESS_GROUP} slug`}
                        name={newBusinessGroup.businessGroupSlug}
                        value={newBusinessGroup.businessGroupSlug}
                        invalid={invalidInput.businessGroupSlug}
                        disabled={posting || isUpdate}
                        onChange={e => handleInputChange("businessGroupSlug", e)}
                        onBlur={() => {
                          const isInvalid = newBusinessGroup["businessGroupSlug"].length < 2 
                            || !(/^[a-z0-9]+$/.test(newBusinessGroup.businessGroupSlug));
                          setInvalidInput({...invalidInput, businessGroupSlug: isInvalid});
                        }}
                      />
                    </FormField>
                   
                    <FormField 
                      label={`${BUSINESS_PROPERTY.BUSINESS_GROUP} name`}
                      description={`Enter a short name for the new ${BUSINESS_PROPERTY.BUSINESS_GROUP} which will appear in the UI and downstream in reporting data`}
                      errorText={invalidInput.businessGroupName ? "This is a required field with of at least 3 character." : ""}
                    >
                      <Input
                        data-testid="business-group-name-input"
                        placeholder={`${BUSINESS_PROPERTY.BUSINESS_GROUP} name`}
                        name={newBusinessGroup.businessGroupName}
                        value={newBusinessGroup.businessGroupName}
                        invalid={invalidInput.businessGroupName}
                        disabled={posting}
                        onChange={e => handleInputChange("businessGroupName", e)}
                        onBlur={() => {
                          const isInvalid = newBusinessGroup["businessGroupName"].length < 4;
                          setInvalidInput({...invalidInput, businessGroupName: isInvalid});
                        }}
                      />
                    </FormField>
                  </Grid>
                  <Grid gridDefinition={[{colspan: 6}]}>
                    <FormField 
                      label={"Business Org"}
                      description={`Enter the name for the Business Org - multiple ${BUSINESS_PROPERTY.BUSINESS_GROUP}s can use the same Business Org to make it easier to group together their data`}
                    >
                      <Input
                        data-testid="business-organization-input"
                        placeholder={"Business Org"}
                        name={newBusinessGroup.businessGroupOrg}
                        value={newBusinessGroup.businessGroupOrg}
                        invalid={invalidInput.businessGroupOrg}
                        disabled={posting}
                        onChange={e => handleInputChange("businessGroupOrg", e)}
                      />
                    </FormField>
                  </Grid>
                </SpaceBetween>
              </ExpandableSection>
            </Container>
            <Container>
              <ExpandableSection
                headerText="Attributes"
                data-testid="create-bg-attributes-section"
                onChange={() => setPageSize(data.page?.totalElements)}
              >
                <AttributesItems
                  isLoading={isLoading}
                  serverError={serverError}
                  data={data}
                  newBusinessGroup={newBusinessGroup}
                  setNewBusinessGroup={setNewBusinessGroup}
                />
              </ExpandableSection>
            </Container>
          </SpaceBetween>
        </Form>
      </form>
    </ContentLayout>
  );
};

export const CreateBusinessGroupContainer: React.FC = ():JSX.Element => {
  const { getIdToken, userAttributes } = useContext(AuthContext);
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const navigate = useNavigate();
  return (
    <div data-testid="create-new-business-group-container">
      <CreateBusinessGroup
        data-testid="create-new-business-group-container"
        getIdToken={getIdToken}
        email={userAttributes?.email}
        navigate={navigate}
        addMessage={addMessage}
      />
    </div>
  );
};
